import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { useSearchParams } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import { useDialog } from 'src/dialogs/Dialogs';

import { useSnackbar } from 'src/components/v3/Snackbar';
import CalendarAside from './CalendarAside';
import { CalendarAgendaTable } from './CalendarAgendaTable';
import CalendarAgenda from './CalendarAgenda';
import { useCalendarTableEvents } from './utils';
import Header from './Header';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';

const today = dayjs().locale('es');

const useGoogleCalendarReconnectToastMsg = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(() => ({
    success: searchParams.get('success') === 'true',
    googleCalendarToastOpen: searchParams.get('reconnect') === 'true',
    googleCalendarToastShown: false,
  }));
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (params.googleCalendarToastOpen && !params.googleCalendarToastShown) {
      if (params.success) {
        openSnackbar('Google Calendar reconectado exitosamente!', {
          id: 'google-calendar-success-reconnect-calendar-page-toast',
          severity: 'success',
        });
      } else {
        openSnackbar(
          'Error al reconectar Google Calendar, asegurate de que la cuenta que seleccionaste es la correcta y tiene permisos para ver todos tus calendarios asociados en LegalSurf. Contactenos a soporte@legal.surf en caso de necesitar ayuda',
          {
            id: 'google-calendar-error-reconnect-calendar-page-toast',
            severity: 'error',
          },
        );
      }

      setSearchParams({});
      setParams({
        googleCalendarToastOpen: false,
        googleCalendarToastShown: true,
      });
    }
  }, [
    openSnackbar,
    params.googleCalendarToastOpen,
    params.googleCalendarToastShown,
    params.success,
    searchParams,
    setSearchParams,
  ]);
};

export const CalendarPage = () => {
  useGoogleCalendarReconnectToastMsg();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = searchParams.get('date');

    return dayjs(date).isValid() ? dayjs(date).locale('es') : today;
  });

  const dispatchEventDialog = useDialog('event');
  const [weekly, setWeekly] = useState(false);
  const { data: events, loading: isLoadingEvents } = useCalendarTableEvents(
    selectedDate,
    selectedCalendars,
  );

  useEffect(() => {
    const eventId = searchParams.get('eventId');
    const calendarId = searchParams.get('success');

    if (eventId && calendarId) {
      dispatchEventDialog('CalendarSearchParamsEventDialog', {
        id: eventId,
        calendarId,
      });

      setSearchParams({});
    }
  }, [dispatchEventDialog, searchParams, setSearchParams]);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  return (
    <Page title="Calendario | LegalSurf">
      <Stack direction={['column', 'column', 'row']} gap={[1, 2]}>
        <Box maxWidth={['100%', '100%', 320]} order={[2, 2, 0]}>
          <CalendarAside
            selectedCalendars={selectedCalendars}
            selectedDate={selectedDate}
            setSelectedCalendars={setSelectedCalendars}
            weekly={weekly}
            onSelectDate={setSelectedDate}
          />
        </Box>

        <Stack flex={1} gap={[1, 2]}>
          <Header
            title="Calendario"
            subtitle={`${events?.length ?? 0} eventos`}
            loading={isLoadingEvents}
          />

          <DisabledBackdrop withIndicator in={isLoadingEvents}>
            <Card>
              <CardContent>
                <CalendarAgenda
                  selectedCalendars={selectedCalendars}
                  selectedDate={selectedDate}
                  weekly={weekly}
                  onSelectDate={handleSelectDate}
                  onWeeklyClick={() => setWeekly(!weekly)}
                />

                <CalendarAgendaTable
                  events={events}
                  selectedCalendars={selectedCalendars}
                  selectedDate={selectedDate}
                  weekly={weekly}
                  onSelectDate={handleSelectDate}
                  onWeeklyClick={() => setWeekly(!weekly)}
                />
              </CardContent>
            </Card>
          </DisabledBackdrop>
        </Stack>
      </Stack>
    </Page>
  );
};

export default CalendarPage;
