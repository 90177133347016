import React from 'react';
import { Box, Avatar, Tooltip, Typography } from '@mui/material';
import { stringAvatar } from 'src/utils/formatters';

export const EntityCell = ({ row }) => (
  <Box>
    <Tooltip key={row.entity.id} title={row.entity.displayName}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar
          alt="Cliente"
          variant="squared"
          {...stringAvatar(row.entity.displayName, {
            width: 30,
            height: 30,
            fontSize: 12,
          })}
        />

        <Typography>{row?.entity?.displayName}</Typography>
      </Box>
    </Tooltip>
  </Box>
);
