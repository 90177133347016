import React, { useMemo } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { DragDropContext } from '@hello-pangea/dnd';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import DraggableColumn, {
  getColumnEventId,
} from 'src/components/v3/DraggableColumn';
import { TaskCard } from 'src/components/v3/TaskCard';
import { usePermissions } from 'src/utils/hooks/usePermissions';

import { DEFAULT_TASK_STATES, LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useUpdateCalendarEventStatus } from '@legalsurf/hooks';

import StateColumnHeader from './StateColumnHeader';

const boardTaskStates = DEFAULT_TASK_STATES.filter(
  (state) => state.value !== 'finished',
);

const sortEventsByDate = (tasks) =>
  [...(tasks || [])].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

const splitEventsByState = (events) => ({
  pending: sortEventsByDate(
    events.filter((event) => event.state === 'pending'),
  ),
  wip: sortEventsByDate(events.filter((event) => event.state === 'wip')),
  done: sortEventsByDate(events.filter((event) => event.state === 'done')),
  reviewing: sortEventsByDate(
    events.filter((event) => event.state === 'reviewing'),
  ),
});

export const Board = ({ events, loading }) => {
  const studioId = useStudioId();
  const dispatchEventDialog = useDialog('event');
  const { hasPermissions: hasCalendarAccessPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
  ]);

  const [updateCalendarEventStatus] = useUpdateCalendarEventStatus();

  // TODO: MOVE TO THE SERVER?
  const splittedEvents = useMemo(() => splitEventsByState(events), [events]);

  const handleClickTask = (task) => {
    dispatchEventDialog(task.id, {
      id: task.id,
      calendarId: task.calendarId,
    });
  };

  const handleDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Re ordering inside the same origin column
    if (result.type === 'COLUMN') {
      console.log('Update the order from the event ');

      return;
    }

    const [eventId, calendarId] = result.draggableId.split('_');

    // Re ordering from other origin column
    const currentEvent = events.find(
      (event) => event.id === eventId && event.calendarId === calendarId,
    );

    updateCalendarEventStatus({
      variables: {
        calendarId: currentEvent.calendarId,
        id: currentEvent.id,
        state: result.destination.droppableId,
      },
      optimisticResponse: {
        updateEventStatus: {
          __typename: 'Event',
          id: currentEvent.id,
          studioId,
          calendarId: currentEvent.calendarId,
          state: result.destination.droppableId,
          type: 'task',
          updatedAt: new Date().toISOString(),
        },
      },
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="row" gap={2}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {boardTaskStates.map((state, index) => (
              <DraggableColumn
                header={
                  <StateColumnHeader state={state}>
                    {state.label}
                  </StateColumnHeader>
                }
                id={state.value}
                index={index}
                items={splittedEvents[state.value]}
                key={state.value}
                renderItem={({ item }) => (
                  <TaskCard
                    isLoading={loading}
                    key={getColumnEventId(item)}
                    onClick={
                      hasCalendarAccessPermissions
                        ? () => handleClickTask(item)
                        : undefined
                    }
                    {...item}
                  />
                )}
              />
            ))}
          </DragDropContext>
        </Stack>
      </CardContent>
    </Card>
  );
};
