import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Table from 'src/components/v3/Table';
import { SalesQuery } from 'src/pages/Dashboard/Sales/SalesQueries';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import { useDialog } from 'src/dialogs/Dialogs';
import Header from './Header';
import { useSalesToExport } from './excel';
import { useSalesColumns } from './columns';

const initialSalesQueryVars = {
  filecases: null,
  clients: null,
  responsibles: null,
};

const SalesTable = ({ disabled, ...props }) => {
  const studioId = useStudioId();
  const columns = useSalesColumns();
  const [selectedItems, setSelectedItems] = useState([]);

  const dispatchSaleDialog = useDialog('sale');

  const {
    data: { sales } = { sales: {} },
    previousData,
    loading,
    refetch,
    variables,
  } = useQuery(SalesQuery, {
    onCompleted: () => setSelectedItems([]),
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: { studio: studioId, ...initialSalesQueryVars },
    skip: !studioId,
  });

  const { getSalesToExport, loading: loadingSalesToExport } =
    useSalesToExport();

  const handleDownloadAsExcel = async () => {
    await getSalesToExport({ ...variables, ids: selectedItems });
  };

  return (
    <Table
      columns={columns}
      components={{
        toolbar: Header,
      }}
      componentsProps={{
        toolbar: {
          totalItems: sales?.length ?? previousData?.sales?.length ?? 0,
          loading,
          refetch,
          selectedItems,
          setSelectedItems,
          onDownloasdAsExcel: handleDownloadAsExcel,
          loadingSalesToExport,
        },
        noResultsOverlay: {
          variant: 'sales',
        },
        noRowsOverlay: {
          variant: 'sales',
        },
      }}
      loading={loading}
      paginationMode="client"
      rowCount={sales?.length ?? previousData?.sales?.length ?? 0}
      rows={sales ?? previousData?.sales ?? []}
      rowsPerPageOptions={[]}
      rowSelectionModel={selectedItems}
      onRowClick={(params) =>
        dispatchSaleDialog(params.row.id, { id: params.row.id })
      }
      onRowSelectionModelChange={(newSelectionModel) =>
        setSelectedItems(newSelectionModel)
      }
      {...props}
    />
  );
};

export default SalesTable;
