import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { LSMail as LSMailIcon } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import EntitiesPopover from 'src/popovers/EntitiesPopover';
import { useMutation } from '@apollo/client';
import { UPDATE_EVENT } from 'src/graphql/mutations/calendars';
import FilecasesPopover from 'src/popovers/FilecasesPopover';
import { CalendarEventDetailsEntity } from './CalendarEventDetailsEntity';

const FilecaseSection = ({ event }) => {
  const [updateCalendarEvent] = useMutation(UPDATE_EVENT);
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.READ,
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filecaseAnchorEl, setFilecaseAnchorEl] = useState(null);
  const dispatchFilecaseDialog = useDialog('filecase');

  const handleOpenEntitiesPopover = (clickEvent) => {
    setAnchorEl(clickEvent.currentTarget);
  };

  const handleCloseEntitiesPopover = () => {
    setAnchorEl(null);
  };

  const handleSubmitEntitiesPopover = async (values, { setSubmitting }) => {
    await updateCalendarEvent({
      variables: {
        studioId: event.studioId,
        id: event.id,
        calendar: event.calendarId,
        entities:
          values.entities?.map((entity) => entity.value || entity) || [],
      },
    });

    setSubmitting(false);
  };

  const handleFilecasePopoverOpen = ({ currentTarget }) => {
    setFilecaseAnchorEl(currentTarget);
  };

  const handleFilecasePopoverClose = () => {
    setFilecaseAnchorEl(null);
  };

  const handleSubmitFilecasePopover = async (values, { setSubmitting }) => {
    await updateCalendarEvent({
      variables: {
        studioId: event.studioId,
        id: event.id,
        calendar: event.calendarId,
        filecase: values.filecases?.value || null,
      },
    });

    setSubmitting(false);
  };

  return (
    <Stack direction="column" gap={0.5}>
      {event.filecase && (
        <Box
          sx={{
            p: 1,
            borderRadius: 0.4,
            display: 'flex',
            alignItems: 'center',

            ...(hasPermissions && {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'primary.100',
              },
            }),
          }}
          onClick={() => {
            if (!hasPermissions) return;

            dispatchFilecaseDialog(
              `CalendarEventDetailsDialog-${event.filecase.id}`,
              {
                id: event.filecase.id,
              },
            );
          }}
        >
          <LSMailIcon color="primary" sx={{ width: 30, height: 30 }} />

          <Typography sx={{ ml: 2 }}>
            Nro. {event.filecase.externalId} {event.filecase.title}
          </Typography>
        </Box>
      )}

      {!!event?.entities?.length && (
        <Box>
          {event?.entities?.map((entity) => (
            <CalendarEventDetailsEntity
              entity={entity}
              event={event}
              filecase={event.filecase}
              key={entity.id}
            />
          ))}
        </Box>
      )}

      <EntitiesPopover
        anchorEl={anchorEl}
        entities={event?.entities?.map(({ id }) => id) || []}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
        }}
        onClose={handleCloseEntitiesPopover}
        onSubmit={handleSubmitEntitiesPopover}
      />

      <FilecasesPopover
        anchorEl={filecaseAnchorEl}
        anchorOrigin={{
          horizontal: 'left',
        }}
        filecases={
          event.filecase
            ? { label: event.filecase.title, value: event.filecase.id }
            : null
        }
        open={Boolean(filecaseAnchorEl)}
        transformOrigin={{
          horizontal: 'right',
        }}
        onClose={handleFilecasePopoverClose}
        onSubmit={handleSubmitFilecasePopover}
      />

      <CreationInfoBanner
        buttonStr="Directorio"
        description="No dejes pasar ningun compromiso, organiza tu información asociando directorios y expedientes"
        secondaryButton={{
          buttonStr: 'Expediente',
          onClick: handleFilecasePopoverOpen,
        }}
        title="Vinculaciones"
        onClick={handleOpenEntitiesPopover}
      />
    </Stack>
  );
};

export default FilecaseSection;
