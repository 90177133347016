import React from 'react';
import { Avatar, Button } from '@mui/material';
import OneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';

const addOneDriveTypeToDocs = (arr) =>
  arr.map((val) => ({
    ...val,
    kind: 'onedrive',
    isFolder: !!val.folder,
    url: val.webUrl,
  }));

export const OneDriveButton = ({ disabled, onSelect }) => {
  const openOneDrivePicker = async () => {
    const odOptions = {
      clientId: import.meta.env.VITE_AZURE_APP_CLIENT_ID,
      action: "share", // Or "query" or "download" based on your needs
      multiSelect: true,
      accountSwitchEnabled: true,
      advanced: {
        redirectUri: import.meta.env.VITE_AZURE_APP_REDIRECT_URI
      },
      success(response) {
        onSelect(addOneDriveTypeToDocs(response?.value));
      },
      cancel() {
        console.log('Picker cancelled');
      },
      error(e) {
        console.error('Picker error:', e);
      }
    };

    window.OneDrive.open(odOptions);
  };

  return (
    <Button
      disabled={disabled}
      startIcon={
        <Avatar src={OneDriveIcon} sx={{ img: { objectFit: 'contain' } }} />
      }
      sx={{
        textTransform: 'none',
        mt: 2,
        p: 2,
      }}
      variant="text"
      onClick={openOneDrivePicker}
    >
      OneDrive
    </Button>
  );
};
