import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';

// TODO: ASK FOR PRETTY STARS ICONS
import {
  Star,
  StarBorderOutlined as StarBorderOutlinedIcon,
} from '@mui/icons-material';
import { useMutation } from '@apollo/client';

import { MARK_AS_FAVORITE } from 'src/graphql/mutations/filecases';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { useUser } from 'src/utils/hooks/useUser';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const FavoriteCell = ({ row: filecase }) => {
  const { user } = useUser();
  const studioId = useStudioId();

  const [markAsFavorite] = useMutation(MARK_AS_FAVORITE, {
    refetchQueries: [GET_FILECASE_SINGLE_DATA],
  });

  const isMarkAsFavorite = filecase?.favorites?.some(
    (favorite) => favorite.id === user?.id,
  );

  const [isFavorite, setIsFavorite] = useState(isMarkAsFavorite);

  const handleClickFavorite = () => {
    markAsFavorite({
      variables: {
        studioId,
        id: filecase.id,
      },

      update: (cache) => {
        cache.modify({
          id: cache.identify(filecase),
          fields: {
            favorites() {
              if (!isFavorite) {
                return [...filecase.favorites, user];
              }

              return filecase.favorites.filter(
                (oldFavorite) => oldFavorite.id !== user.id,
              );
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    setIsFavorite(isMarkAsFavorite);
  }, [isMarkAsFavorite]);

  return (
    <Tooltip
      arrow
      placement="right"
      title={!isFavorite ? 'Marcar como favorito' : 'Desmarcar favorito'}
    >
      <IconButton
        color={isFavorite ? 'warning' : 'default'}
        onClick={() => {
          setIsFavorite(!isFavorite);
          handleClickFavorite();
        }}
      >
        {isFavorite ? <Star /> : <StarBorderOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default FavoriteCell;
