import React from 'react';
import { Dialog, Divider, DialogTitle } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';

import {
  GetExpenseDetails,
  UpdatePaymentMutation,
  CreatePaymentMutation,
} from 'src/pages/Dashboard/Expenses/ExpensesQueries';
import PaymentForm from 'src/forms/PaymentForm';
import dayjs from 'dayjs';

// TODO: No more multi staged dialogs/forms/etc
// TODO: Try to abstract what's necessary and compound the rest
const PaymentDialog = ({
  expense = {},
  payment,
  open,
  onClose,
  title,
  type,
  ...rest
}) => {
  const { openSnackbar } = useSnackbar();
  const [createPayment, { loading }] = useMutation(CreatePaymentMutation, {
    refetchQueries: [GetExpenseDetails],
  });
  const [updatePayment] = useMutation(UpdatePaymentMutation, {
    refetchQueries: [GetExpenseDetails],
  });

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = payment ? updatePayment : createPayment;
    try {
      await changeMutation({
        variables: {
          amount: Number(values.amount),
          paymentId: payment ? payment.id : null,
          date: new Date(values.date).toISOString(),
          concept: values.concept,
          method: values.paymentMethod.value,
          expense: expense.id,
          currency: values.currency.value,
        },
      });

      openSnackbar('Pago creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>

      <Divider />

      <PaymentForm
        enableReinitialize
        buttonLabel={payment ? 'Editar' : 'Guardar'}
        initialValues={{
          ...expense,
          date: dayjs(payment?.date),
          expenseTotal: expense.amount,
          expensePaid: expense.paid,
          currency: expense.currency
            ? {
                value: expense.currency.name,
                label: expense.currency.name,
              }
            : null,
          amount: payment?.amount ? payment.amount : 0,
          concept: payment?.concept,
          paymentMethod: DEFAULT_PAYMENT_METHODS.find(
            (method) => method.value === payment?.method,
          ),
        }}
        isEdit={!!payment}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default PaymentDialog;
