import React from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useUser } from 'src/utils/hooks/useUser';
import dayjs from 'dayjs';
import { getTableTasksQuery } from './gql';
import Header, { useTaskBoardSearchFilterValues } from './Header';
import { Board } from './Board';
import { Table } from './Table';

export const TaskBoard = () => {
  const studioId = useStudioId();
  const { user } = useUser();
  const [filterValues, , initialized] = useTaskBoardSearchFilterValues();
  const { viewType = 'board' } = filterValues;

  const initialTasksQueryVars = {
    filecases: null,
    calendars: null,
    text: null,
    start: null,
    end: null,
    type: 'task',
    state: ['pending', 'wip', 'done', 'reviewing'],
    assigned: user?.role === 'member' ? [user.id] : null,
  };

  const queryVariables = {
    studioId,
    calendars: initialTasksQueryVars.calendars || filterValues.calendars,
    filecases: initialTasksQueryVars.filecases || filterValues.filecases,
    text: initialTasksQueryVars.text || filterValues.text,
    start:
      initialTasksQueryVars.start || filterValues.start
        ? dayjs(initialTasksQueryVars.start || filterValues.start)
            .startOf('day')
            .toDate()
        : null,
    end:
      initialTasksQueryVars.end || filterValues.end
        ? dayjs(initialTasksQueryVars.end || filterValues.end)
            .endOf('day')
            .toDate()
        : null,
    type: initialTasksQueryVars.type || filterValues.type,
    assigned: initialTasksQueryVars.assigned || filterValues.assigned,
    state:
      viewType === 'board'
        ? initialTasksQueryVars.state
        : filterValues.state
          ? [filterValues.state]
          : ['pending'],
  };

  const {
    data: { events } = { events: [] },
    loading,
    refetch,
  } = useQuery(getTableTasksQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !studioId || !initialized,
  });

  return (
    <Stack direction="column" gap={1}>
      <Header
        loading={loading}
        refetch={refetch}
        subtitle={`Totales ${events.length}`}
        title="Tareas"
      />

      {viewType === 'board' ? (
        <Board events={events} loading={loading} />
      ) : (
        <Table events={events} loading={loading} refetch={refetch} />
      )}
    </Stack>
  );
};
