import React from 'react';
import ContrastColor from 'contrast-color';
import { palette } from 'src/config/mui-theme/foundations';
import { Box, Tooltip, Typography } from '@mui/material';

export const Badge = React.forwardRef(
  (
    {
      backgroundColor = palette.background.input,
      color,
      label,
      tooltipLabel,
      textProps = {},
      tooltipProps = {},
      ...props
    },
    ref,
  ) => {
    const constrastedColor = React.useMemo(
      () => color || ContrastColor.contrastColor({ bgColor: backgroundColor }),
      [color, backgroundColor],
    );

    return (
      <Tooltip title={tooltipLabel ?? label} placement="top" {...tooltipProps}>
        <Box
          ref={ref}
          maxWidth={120}
          minWidth={120}
          backgroundColor={backgroundColor}
          borderRadius={1}
          px={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={40}
          {...props}
        >
          <Typography
            color={constrastedColor}
            fontWeight="600"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{ textWrap: 'nowrap' }}
            {...textProps}
          >
            {label}
          </Typography>
        </Box>
      </Tooltip>
    );
  },
);
