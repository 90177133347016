import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  Stack,
  Typography,
  AvatarGroup,
  Tooltip,
  Box,
  Avatar,
  CircularProgress,
} from '@mui/material';

import { LSMail as LSMailIcon } from 'src/components/icons';

import EndingCell from 'src/pages/Dashboard/Calendar/CalendarAgendaTable/EndingCell';
import { stringAvatar } from 'src/utils/formatters';
import { AssigneesPopover } from '../AssigneesPopover';

export const TaskCard = (props) => {
  const {
    title,
    assigned,
    filecase,
    todos,
    entities,
    onClick,
    calendar,
    sx = {},
  } = props;

  const [completionPercentage, completionPercentageTitle] = useMemo(() => {
    const porcetange = Math.floor(
      (todos.filter((todo) => todo.done).length / todos.length) * 100,
    );

    const str = `${porcetange}%`;

    return [porcetange, str];
  }, [todos]);

  // const [updateCalendarEventAssignees] = useUpdateCalendarEventAssignees();

  // const { hasPermissions } = usePermissions([
  //   LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  // ]);

  // const handleAssignedSubmit = async (newAssignees) => {
  //   await updateCalendarEventAssignees({
  //     variables: {
  //       id: props.id,
  //       calendarId: props.calendarId,
  //       assigned: newAssignees,
  //     },
  //   });
  // };

  return (
    <Card
      sx={{
        ...sx,
        borderLeftColor: calendar.color,
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
      }}
      onClick={onClick}
    >
      <CardContent sx={{ p: 2, pb: 1, pl: 1 }}>
        <Stack direction="column" gap={1}>
          <Box sx={{ position: 'relative' }}>
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{
                px: 0.5,
              }}
              variant="h6"
            >
              {title}
            </Typography>
          </Box>

          <Stack alignItems="center" direction="row">
            {entities.length > 0 && (
              <AvatarGroup
                max={3}
                renderSurplus={(surplus) => (
                  <Avatar alt={`${surplus} personas más`}>
                    <Typography fontSize={10} variant="body1">
                      +{surplus}
                    </Typography>
                  </Avatar>
                )}
                spacing={6}
                sx={{
                  '& > div': {
                    width: '20px !important',
                    height: '20px !important',
                  },
                }}
                total={entities.length}
              >
                {entities.map((entity) => (
                  <Tooltip key={entity.id} title={entity.displayName}>
                    <Avatar
                      alt={entity.displayName}
                      {...stringAvatar(entity.displayName, {
                        fontSize: 12,
                      })}
                    >
                      <Typography fontSize={12} variant="body1">
                        {entity.displayName?.[0]}
                      </Typography>
                    </Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            )}

            {filecase && (
              <Tooltip title={filecase.title}>
                <Box component="span" sx={{ display: 'flex' }}>
                  <LSMailIcon color="primary" />
                </Box>
              </Tooltip>
            )}

            {todos.length > 0 && (
              <Tooltip
                title={completionPercentageTitle}
                visibility={completionPercentage ? 'initial' : 'hidden'}
              >
                <Stack sx={{ position: 'relative' }}>
                  <CircularProgress
                    size={18}
                    sx={{
                      mr: 2,
                      color: 'grey.100',
                      position: 'absolute',
                    }}
                    thickness={8}
                    value={100}
                    variant="determinate"
                  />

                  <CircularProgress
                    color={completionPercentage === 100 ? 'success' : 'primary'}
                    size={18}
                    sx={{ width: 100, borderRadius: 1, height: 10 }}
                    thickness={8}
                    value={completionPercentage}
                    variant="determinate"
                  />
                </Stack>
              </Tooltip>
            )}
          </Stack>

          <Stack alignItems="center" direction="row">
            <EndingCell row={props} tooltipProps={{ placement: 'top' }} />

            {assigned.length > 0 && (
              <Stack
                direction="row"
                alignItems="center"
                gap={assigned?.length ? 0 : 0.5}
                justifyContent="flex-start"
              >
                <Typography
                  sx={{ opacity: 0.8 }}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  fontSize={12}
                >
                  Asignados
                </Typography>

                <AssigneesPopover
                  selectedAssignees={assigned}
                  touchable={false}
                  // TODO: Enable assignees popover when stop propagation works
                  // onSubmit={handleAssignedSubmit}
                  sx={{
                    justifyContent: 'flex-start',
                    '.MuiAvatar-root': {
                      width: 25,
                      height: 25,
                    },
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
