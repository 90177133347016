import React, { useMemo } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  Observable,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { SentryLink } from 'apollo-link-sentry';
import { useAuth } from '@clerk/clerk-react';

const promiseToObservable = (promise) =>
  new Observable((subscriber) => {
    promise().then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err),
    );
    return subscriber; // this line can removed, as per next comment
  });

const graphqlAPI = `${import.meta.env.VITE_API_URI}/graphql`;

export const appCache = new InMemoryCache({
  typePolicies: {
    Event: {
      keyFields: ['id', 'calendarId'],
    },
    EventTodo: {
      keyFields: ['id', 'eventId', 'calendarId'],
    },
    CustomField: {
      keyFields: ['id', 'name', 'studioId'],
    },
  },
});

const uploadLink = createUploadLink({
  uri: graphqlAPI,
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

const sentryLink = new SentryLink({
  uri: graphqlAPI,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeFetchResult: true,
    includeError: true,
  },
});

export const ApolloCustomProvider = ({ children }) => {
  const { signOut, getToken, isSignedIn, userId } = useAuth();

  // const setLimitReached = useSubscriptionStore(
  //   (state) => state.setLimitReached,
  // );

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, operation, networkError, forward }) => {
        if (networkError) {
          if (
            networkError?.result?.code === 'invalid_token' ||
            networkError?.result?.code === 'invalid_code'
          ) {
            return promiseToObservable(signOut).flatMap(() =>
              forward(operation),
            );
          }
        }

        if (graphQLErrors) {
          // Handle authentication error
          if (
            graphQLErrors.some(
              (error) => error.message === 'User not authenticated',
            )
          ) {
            return promiseToObservable(signOut).flatMap(() =>
              forward(operation),
            );
          }

          Sentry.withScope((scope) => {
            if (networkError) {
              const serverError = networkError;

              scope.setTag('errorType', 'NetworkError');

              if (serverError?.statusCode) {
                scope.setExtra('statusCode', serverError.statusCode);
              }

              if (serverError?.result) {
                scope.setExtra('errorDetails', serverError.result);
              }
            }

            if (graphQLErrors?.length) {
              scope.setTag('errorType', 'GraphQLError');

              graphQLErrors.forEach((error, index) => {
                scope.setExtra(`graphQLError_${index}`, {
                  message: error.message,
                  locations: error.locations,
                  path: error.path,
                  extensions: error.extensions,
                });
              });
            }

            scope.setExtra('operation', {
              operationName: operation.operationName,
              variables: operation.variables,
              query: operation?.query?.loc?.source?.body,
            });

            Sentry.captureException(networkError || graphQLErrors, {
              user: {
                id: userId,
              },
            });
          });
        }

        return forward(operation);
      }),
    [signOut, userId],
  );

  const authLink = useMemo(
    () =>
      setContext(async (_, context) => {
        const headers = { ...context.headers };

        if (!isSignedIn) {
          return { headers };
        }

        const token = await getToken().catch((error) => {
          Sentry.captureException(error);
        });

        if (token) {
          headers.Authorization = `Bearer ${token}`;
        } else {
          delete headers.Authorization;
        }

        return {
          headers,
        };
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSignedIn],
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([sentryLink, authLink, errorLink, uploadLink]),
        cache: appCache,
        name: '@legalsurf/web',
        defaultOptions: {
          query: {
            fetchPolicy: 'network-only',
            partialRefetch: true,
          },
        },
      }),
    [authLink, errorLink],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
