import React from 'react';
import { Box, Tooltip, LinearProgress } from '@mui/material';

const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

export const ChargedCell = ({ row }) => (
  <Tooltip key={row.id} title={`Cobrado: ${row.currency?.name} ${row.charged}`}>
    <Box sx={{ width: 120 }}>
      <LinearProgress
        color="success"
        sx={{ height: 8, borderRadius: 5 }}
        value={normalise(row.charged, 0, row.amount)}
        variant="determinate"
      />
    </Box>
  </Tooltip>
);
