import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

import { useDialog } from 'src/dialogs/Dialogs';
import { LSMail as LSMailIcon } from 'src/components/icons';

// ToggleCell is not my favorite name, but I don't have a better one
const ToggleCell = ({ row: filecase }) => {
  const dispatchFilecaseDialog = useDialog('filecase');

  return (
    <Tooltip arrow placement="top" title="Ver expediente">
      <IconButton
        color="primary"
        onClick={() => {
          dispatchFilecaseDialog(filecase.id, { id: filecase.id });
        }}
      >
        <LSMailIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ToggleCell;
