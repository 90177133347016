import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { usePermissions } from 'src/utils/hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import { stringAvatar } from 'src/utils/formatters';

const TitleCell = ({ row }) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.READ,
  ]);

  const handleEntityClick = () => {
    navigate(row.id);
  };

  return (
    <Box
      sx={{
        maxWidth: '40rem',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'no-wrap',
        gap: 2,
        alignItems: 'center',
        cursor: hasPermissions ? 'pointer' : 'initial',
        '& > * ': { mr: 0.5 },
      }}
      onClick={() => {
        if (hasPermissions) {
          handleEntityClick(row.id);
        }
      }}
    >
      <Avatar
        role="button"
        {...stringAvatar(row.displayName, {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          cursor: 'pointer',
          fontSize: '1rem',
          display: ['none', 'none', 'flex'],
        })}
      />

      <Typography
        role="button"
        sx={{
          fontWeight: '500',
        }}
      >
        {row.displayName}
      </Typography>
    </Box>
  );
};

export default TitleCell;
