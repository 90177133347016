import React, { memo } from 'react';
import {
  Grid,
  Divider,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
} from '@mui/material';
import { Formik } from 'formik';

import Form, {
  SelectField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import { EntityStatusAutocomplete, TagsAutocomplete } from 'src/autocompletes';

import {
  LSDirectory as LSDirectoryIcon,
  LSInstitution as LSInstitutionIcon,
} from 'src/components/icons';

import { entityTypes } from '@legalsurf/common';

import DirectoryFormValidationSchema from './validation-schema';

const defaultFormValues = {
  firstname: '',
  lastname: '',
  businessname: '',
  phone: '',
  type: null,
  isLegalEntity: false,
  tags: [],
};

const DirectoryForm = ({
  onSubmit,
  onCancel,
  initialValues = defaultFormValues,
  enableReinitialize,
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    validationSchema={DirectoryFormValidationSchema}
    onSubmit={onSubmit}
  >
    {({ values, setFieldValue, resetForm }) => (
      <>
        <DialogContent>
          <Form id="directoryForm">
            <FormControl item component={Grid} xs={12}>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    color="primary"
                    startIcon={<LSDirectoryIcon />}
                    sx={{
                      padding: 3,

                      // TODO: Find a prettier approach... get it?
                      // 5 months after seem this, I got it hahahahdf
                      ...(!values.isLegalEntity && {
                        backgroundColor: 'secondary.100',
                      }),
                    }}
                    variant="dashed"
                    onClick={() => {
                      resetForm();
                      setFieldValue('isLegalEntity', false);
                    }}
                  >
                    Persona fisica
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    fullWidth
                    color="primary"
                    startIcon={<LSInstitutionIcon />}
                    sx={{
                      padding: 3,

                      // Duplicated styles
                      ...(values.isLegalEntity && {
                        backgroundColor: 'secondary.100',
                      }),
                    }}
                    variant="dashed"
                    onClick={() => {
                      resetForm();
                      setFieldValue('isLegalEntity', true);
                    }}
                  >
                    Persona juridica
                  </Button>
                </Grid>
              </Grid>
            </FormControl>

            <SelectField
              gridProps={{ xs: 12 }}
              label="Tipo"
              name="type"
              options={entityTypes}
            />

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item spacing={2} xs={12}>
              {!values.isLegalEntity ? (
                <>
                  <TextField
                    gridProps={{ xs: 12, md: 6 }}
                    label="Nombre"
                    name="firstname"
                    placeholder="Nombre"
                  />
                  <TextField
                    gridProps={{ xs: 12, md: 6 }}
                    label="Apellido"
                    name="lastname"
                    placeholder="Apellido"
                  />
                </>
              ) : (
                <TextField
                  gridProps={{ xs: 12 }}
                  label="Razon social"
                  name="businessname"
                  placeholder="Razon social"
                />
              )}

              <EntityStatusAutocomplete
                creatable
                name="state"
                placeholder="Ej. Contactado/Negociando"
              />

              <TextField
                label="Telefono"
                name="phone"
                placeholder="Ej. 123 1456 134"
              />

              <TagsAutocomplete
                creatable
                multiple
                name="tags"
                placeholder="Ej. Zona sur"
              />
            </Grid>
          </Form>
        </DialogContent>

        <DialogActions sx={{ gap: 2, justifyContent: 'center' }}>
          <Button onClick={onCancel}>Cancelar</Button>

          <SubmitButton
            fullWidth
            form="directoryForm"
            sx={{ minWidth: 240 }}
            type="submit"
          >
            Crear
          </SubmitButton>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default memo(DirectoryForm);
