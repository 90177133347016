import React, { useState } from 'react';
import {
  Box,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';

import { getEntityType } from 'src/utils/formatters';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { LS_PERMISSIONS_MAP, entityTypesObj } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LSTrash } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { DELETE_ENTITY_FROM_FILECASE } from 'src/graphql/mutations/filecases';
import { useMutation } from '@apollo/client';
import { WhatsApp } from '@mui/icons-material';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import {
  isValidWhatsappNumber,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';

const Entity = ({ isWithActions = true, filecase, entity }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.UPDATE,
  ]);

  const [deleteEntityFromFilecase] = useMutation(DELETE_ENTITY_FROM_FILECASE, {
    variables: {
      entity: entity.id,
      filecase: filecase.id,
      studioId,
    },
  });

  const handleEntityDelete = () =>
    dispatchWarningPromptDialog(`SingleFilecaseEntities-${entity.id}`, {
      onAccept: deleteEntityFromFilecase,
      title: 'Desvincular entidad',
      content: `Estas seguro que deseas desvincular a ${entity.displayName} de tu expediente?`,
    });

  const isWhatsappValid = isValidWhatsappNumber(entity.phone);
  const isPhoneEmpty = entity.phone === null || entity.phone?.length === 0;
  const isPhoneValid = isWhatsappValid || isPhoneEmpty;

  const tooltipTitle = `Envia plantillas personalizados por Whatsapp agregando primero un número de teléfono valido a ${
    entity.displayName
  } (${entityTypesObj[entity.type]})`;

  return (
    <Box sx={{ mb: 2 }}>
      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ filecase }}
        entity={entity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg(entity.phone, msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />

      <ListItem
        key={entity.id}
        secondaryAction={
          <Box>
            <Tooltip
              disableFocusListener={isWhatsappValid}
              disableHoverListener={isWhatsappValid}
              disableTouchListener={isWhatsappValid}
              title={tooltipTitle}
            >
              <span>
                <IconButton
                  disabled={!isPhoneValid}
                  variant="text"
                  onClick={handleOpenFilecasesPopover}
                >
                  <WhatsApp
                    sx={{
                      color: isWhatsappValid ? '#24d366' : undefined,
                      opacity: !isPhoneEmpty ? 0.85 : 1,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>

            {isWithActions && hasPermissions && (
              <Tooltip title="Desvincular entidad">
                <IconButton variant="text" onClick={handleEntityDelete}>
                  <LSTrash color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: 1,
          p: 1,
          '&:hover': {
            backgroundColor: 'primary.100',
          },
        }}
      >
        <ListItemText
          primary={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography
                color="textSecondary"
                fontWeight="bold"
                sx={{ maxWidth: 380 }}
              >
                {entity.displayName}
              </Typography>

              <Chip
                color="primary"
                label={getEntityType(entity.type)}
                variant="light"
              />
            </Box>
          }
          onClick={() =>
            window.open(
              `/dashboard/${studioId}/directories/${entity.id}`,
              '_blank',
            )
          }
        />
      </ListItem>
    </Box>
  );
};

export default Entity;
