import React, { useMemo } from 'react';
import { DialogTitle, Dialog, Divider } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useParams } from 'react-router-dom';

import EntityDetailsForm from 'src/forms/EntityDetailsForm';
import { parseCustomFieldsToInitialValues } from 'src/components/ui-components/Form';

import { UPDATE_ENTITY } from 'src/graphql/mutations/entities';
import { GET_ENTITY } from 'src/graphql/queries/entities';

const EntityDialog = ({ onClose, open, title, entity }) => {
  const params = useParams();

  const { openSnackbar } = useSnackbar();
  const [updateEntity] = useMutation(UPDATE_ENTITY, {
    refetchQueries: [
      {
        query: GET_ENTITY,
        variables: {
          entity: entity.id,
        },
      },
    ],
  });

  const initialValues = useMemo(
    () => ({
      ...entity,
      ...parseCustomFieldsToInitialValues(entity?.customFields),
      description: entity?.description ?? '',
      state: entity?.state?.name
        ? {
            label: entity.state.name,
            value: entity.state.name,
            color: entity.state.color,
          }
        : '',
      tags:
        entity?.tags?.map((tag) => ({ label: tag.name, value: tag.name })) ||
        [],
    }),
    [entity],
  );

  const handleSubmit = async (values, formikBag) => {
    try {
      await updateEntity({
        variables: {
          ...values,
          state: values?.state?.name,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label ?? tag.name),
          id: params.entity,
          type: values.type.value ? values.type.value : null,
        },
      });

      openSnackbar('Se ha guardado correctamente.', {
        severity: 'success',
      });

      formikBag.resetForm();
      onClose();
    } catch (error) {
      openSnackbar('Ha ocurrido un error.', {
        severity: 'error',
      });
    }
  };

  return (
    <Dialog
      open={open}
      sx={{ '& .MuiPaper-root': { width: 500 } }}
      onClose={onClose}
    >
      <DialogTitle>{title ?? 'Actualizar directorio'}</DialogTitle>

      <Divider />

      <EntityDetailsForm
        enableReinitialize
        entityId={entity?.id}
        initialValues={initialValues}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default EntityDialog;
