import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent, Box } from '@mui/material';
import * as Yup from 'yup';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import {
  AssigneesAutocomplete,
  EntitiesAutocomplete,
  TagsAutocomplete,
  FilecasesAutocomplete,
} from 'src/autocompletes';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';

const defaultValues = {
  client: null,
  amount: 0,
  concept: '',
  filecase: null,
  responsible: null,
  canBeSeenByCreatorOnly: false,
  tags: [],
  currency: 'usd',
};

const ExpenseForm = ({
  initialValues = defaultValues,
  onSubmit,
  isEdit,
  onCancel,
  buttonLabel,
  type,
  ...rest
}) => {
  const validationSchema = Yup.lazy(() => {
    const shape = initialValues.currency
      ? {
          currency: Yup.object()
            .required('Debes seleccionar o crear una moneda')
            .nullable(),
        }
      : {};

    return Yup.object({
      ...shape,
      date: YupDateValidations,
      amount: Yup.number()
        .positive('Debe ser un monto positivo')
        .required('Debes agregar un monto a tu gasto'),
      concept: Yup.string()
        .min(1, 'Debes agregar un concepto a tu gasto')
        .required('Debes agregar un concepto a tu gasto'),
    });
  });

  return (
    <Formik
      {...rest}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <DialogContent>
            <Form id="expenseForm">
              <DateField label="Fecha" name="date" />

              <MoneyField
                amountName="amount"
                currencyName="currency"
                disabled={isEdit}
                label="Monto"
                type="number"
              />

              <TextField multiline label="Concepto" name="concept" rows={3} />

              <EntitiesAutocomplete
                disabled={initialValues.entity}
                label="Proveedor"
                name="entity"
              />

              <FilecasesAutocomplete
                disabled={initialValues.filecase}
                label="Expediente"
                name="filecase"
              />

              {type === 'debit' && (
                <SelectField
                  label="Medio de pago"
                  name="paymentMethod"
                  options={DEFAULT_PAYMENT_METHODS}
                />
              )}

              <AssigneesAutocomplete label="Responsable" name="responsible" />

              <TagsAutocomplete
                creatable
                multiple
                name="tags"
                placeholder="Ej. Zona sur"
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
                Cancelar
              </Button>

              <SubmitButton
                fullWidth
                form="expenseForm"
                gridProps={{ sx: { width: '100%' } }}
              >
                {buttonLabel}
              </SubmitButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default ExpenseForm;
