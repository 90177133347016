/* eslint-disable arrow-body-style */
import React from 'react';
import { Box, Skeleton, Divider, Card, CardContent } from '@mui/material';

const UserColumnSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Skeleton height={180} width={120} />
          <Skeleton height={20} width={100} />
          <Skeleton height={40} width={120} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 4,
            '& > * + *': { marginLeft: 2 },
          }}
        >
          <Skeleton height={30} variant="circular" width={30} />
          <Skeleton height={30} variant="circular" width={30} />
          <Skeleton height={30} variant="circular" width={30} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
          <Skeleton height={15} width={100} />
          <Skeleton height={40} width={80} />
        </Box>

        <Divider />

        <Box
          sx={{
            '& > *': {
              marginTop: 4,
            },
          }}
        >
          <Box>
            <Skeleton height={18} width={50} />
            <Skeleton height={15} width={145} />
          </Box>
          <Box>
            <Skeleton height={18} width={50} />
            <Skeleton height={15} width={145} />
          </Box>

          <Box>
            <Skeleton height={18} width={50} />
            <Skeleton height={15} width={145} />
          </Box>
          <Box>
            <Skeleton height={18} width={50} />
            <Skeleton height={15} width={145} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserColumnSkeleton;
