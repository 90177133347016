import React, { useState } from 'react';

import { Box, Tooltip, IconButton } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';

import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import {
  isValidWhatsappNumber,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';
import { entityTypesObj } from '@legalsurf/common';

export const TemplateCell = ({ row: entity }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const isWhatsappValid = isValidWhatsappNumber(entity.phone);
  const isPhoneEmpty = entity.phone === null || entity.phone?.length === 0;
  const isPhoneValid = isWhatsappValid || isPhoneEmpty;

  const tooltipTitle = `Envia plantillas personalizados por Whatsapp agregando primero un número de teléfono valido a ${
    entity.displayName
  } (${entityTypesObj[entity.type]})`;

  return (
    <>
      <Box
        ref={anchorEl}
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
      >
        <Tooltip
          disableFocusListener={isWhatsappValid}
          disableHoverListener={isWhatsappValid}
          disableTouchListener={isWhatsappValid}
          title={tooltipTitle}
        >
          <span>
            <IconButton
              color="inherit"
              disabled={!isPhoneValid}
              size="small"
              variant="text"
              onClick={handleOpenFilecasesPopover}
            >
              <WhatsApp
                sx={{
                  color: isWhatsappValid ? '#24d366' : undefined,
                  opacity: !isPhoneEmpty ? 1 : 0.85,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        entity={entity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg(entity.phone, msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />
    </>
  );
};
