import { gql, useLazyQuery } from '@apollo/client';
import ExcelJS from 'exceljs';
import { useUser } from 'src/utils/hooks/useUser';
import dayjs from 'dayjs';
import { getEntityType } from 'src/utils/formatters';
import { formatCustomFieldValue } from 'src/components/ui-components/Form/CustomField';

const EntitiesToExportQuery = gql`
  query EntitiesToExportQuery(
    $search: String
    $tags: [String!]
    $studio: ID!
    $orderBy: EntitiesOrderBy
    $sortOrder: SortOrder
    $ids: [ID!]
  ) {
    entities(
      search: $search
      tags: $tags
      studio: $studio
      orderBy: $orderBy
      sortOrder: $sortOrder
      ids: $ids
    ) {
      id
      displayName
      description
      type
      email
      identification
      country
      location
      city
      address
      phone
      postalCode
      createdAt

      tags {
        id
        name
        studioId
      }

      createdBy {
        id
        name
        email
      }

      customFields {
        id
        name
        studioId
        type
        value(modelType: Entity)
      }
    }
  }
`;

const excelHeaders = [
  'Nombre o Razon Social',
  'Descripción',
  'Tipo',
  'Etiquetas',
  'Email',
  'Telefono',
  'Identificación',
  'País',
  'Localidad',
  'Ciudad',
  'Dirección',
  'Código postal',
  'Creador por',
  'Fecha de creación',
];

const entityKeyPerCell = [
  'displayName',
  'description',
  'type',
  'tags',
  'email',
  'phone',
  'identification',
  'country',
  'location',
  'city',
  'address',
  'postalCode',
  'createdBy',
  'createdAt',
];

export const useEntitiesToExport = () => {
  const { user } = useUser();
  const [getEntitiesToExport, { loading }] = useLazyQuery(
    EntitiesToExportQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  return {
    loading,
    getEntitiesToExport: (variables) =>
      getEntitiesToExport({ variables }).then((res) => {
        const { entities = [] } = res.data ?? {};

        const workbook = new ExcelJS.Workbook();

        workbook.creator = user.name;
        workbook.created = new Date();

        const worksheet = workbook.addWorksheet('Directorio');

        // Set column headers strings
        excelHeaders.forEach((header, index) => {
          worksheet.getRow(1).getCell(index + 1).value = header;
        });

        if (entities?.[0]?.customFields?.length) {
          entities[0].customFields.forEach((customField, index) => {
            worksheet.getRow(1).getCell(excelHeaders.length + index + 1).value = customField.name;
          });
        }

        // Set entity rows data
        entities.forEach((entity, index) => {
          // Plus 2 because = exceljs starts at 1 and columns headers row
          const entityRow = worksheet.getRow(2 + index);

          entityKeyPerCell.forEach((key, cellKey) => {
            const entityCellKey = 1 + cellKey;
            if (!entity[key] || entity[key]?.length === 0) {
              return;
            }

            switch (key) {
              // case 'state':
              //   entityRow.getCell(entityCellKey).value = entity[key].name;
              //   entityRow.getCell(entityCellKey).fill = {
              //     type: 'pattern',
              //     pattern: 'solid',
              //     fgColor: {
              //       argb: entity[key].color.split('#')[1],
              //     },
              //   };
              //   entityRow.getCell(entityCellKey).font = {
              //     color: {
              //       argb: ContrastColor.contrastColor({
              //         bgColor: entity[key].color,
              //       }).split('#')[1],
              //     },
              //   };

              //   break;

              case 'createdBy':
                entityRow.getCell(entityCellKey).value = entity[key].name;
                break;

              case 'type':
                entityRow.getCell(entityCellKey).value = getEntityType(
                  entity[key],
                );
                break;

              case 'createdAt':
                entityRow.getCell(entityCellKey).value = dayjs(
                  entity[key],
                ).format('LLL');
                break;

              case 'tags':
                entityRow.getCell(entityCellKey).value = entity[key]
                  .map((val) => val.name)
                  .join(', ');
                break;

              default:
                entityRow.getCell(entityCellKey).value = entity[key];
            }
          });

          if (entity.customFields?.length) {
            entity.customFields.forEach((customField, customFieldIndex) => {
              entityRow.getCell(1 + entityKeyPerCell.length + customFieldIndex).value = formatCustomFieldValue(customField);
            });
          }
        });

        // Downloads the xlsx into the browser
        worksheet.workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'directorios.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      }),
  };
};
