import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GET_PAGINATED_ENTITIES } from 'src/graphql/queries/entities';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header from './Header';
import { useColumns } from './columns';
import { useEntitiesToExport } from './excel';

const DirectoriesTable = ({ disabled, ...props }) => {
  const studioId = useStudioId();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(defaultPageSize);
  const [selectedItems, setSelectedItems] = useState([]);

  const columns = useColumns();

  const {
    data: { paginatedEntities } = { paginatedEntities: {} },
    previousData,
    loading,
    refetch,
    variables,
  } = useQuery(GET_PAGINATED_ENTITIES, {
    onCompleted: () => setSelectedItems([]),
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
      limit: defaultPageSize,
      offset: 0,
    },
    skip: !studioId,
  });

  const handlePageChange = (pagination) => {
    setPage(pagination.page);
    setLimit(pagination.pageSize);

    refetch({
      limit: pagination.pageSize,
      offset: pagination.page * pagination.pageSize,
    });
  };

  const handleReset = (vars) =>
    refetch(vars).then(() => {
      setLimit(defaultPageSize);
    });

  const { getEntitiesToExport, loading: loadingEntitiesToExport } =
    useEntitiesToExport();

  const handleDownloadAsExcel = async () => {
    const params = { ...variables, ids: selectedItems };

    if (!selectedItems?.length) {
      params.limit = paginatedEntities?.data?.count;
    }

    await getEntitiesToExport(params);
  };

  return (
    <Table
      columns={columns}
      components={{
        toolbar: Header,
      }}
      componentsProps={{
        toolbar: {
          totalItems:
            paginatedEntities?.count ??
            previousData?.paginatedEntities?.count ??
            0,
          loading,
          refetch: handleReset,
          selectedItems,
          setSelectedItems,
          onDownloasdAsExcel: handleDownloadAsExcel,
          loadingEntitiesToExport,
        },
        noResultsOverlay: {
          variant: 'entities',
        },
        noRowsOverlay: {
          variant: 'entities',
        },
      }}
      loading={loading || loadingEntitiesToExport}
      paginationModel={{ page, pageSize: limit }}
      rowCount={
        paginatedEntities?.count ?? previousData?.paginatedEntities?.count ?? 0
      }
      rowSelectionModel={selectedItems}
      rows={
        paginatedEntities?.data ?? previousData?.paginatedEntities?.data ?? []
      }
      onPaginationModelChange={handlePageChange}
      onRowSelectionModelChange={(newSelectionModel) =>
        setSelectedItems(newSelectionModel)
      }
      onSortModelChange={([column]) => {
        refetch({
          ...variables,
          ...(column
            ? {
              orderBy: column.field,
              sortOrder: column.sort,
            }
            : {
              orderBy: null,
              sortOrder: null,
            }),
        });
      }}
      {...props}
    />
  );
};

export default DirectoriesTable;
