import React from 'react';
import { Chip, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

export const SectionHeaderActiveFilters = ({ filters, filterLabels }) => {
  const { setFieldValue } = useFormikContext()
  const activeFilters = Object.entries(filters).filter(([key, val]) => filterLabels[key] && (Array.isArray(val) ? val.length : Boolean(val)));

  const getHumanizedSlug = (key, value) => {
    const label = filterLabels[key];
    const separator = ': ';
    const val = Array.isArray(value)
      ? value.length
      : typeof value === 'boolean'
        ? (value ? 'Sí' : 'No')
        : value.toString()

    return [label, separator, val].join('');
  }

  if (!activeFilters.length) {
    return null;
  }

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      {activeFilters.map(([key, value]) => (
        <Chip
          color="primary"
          label={getHumanizedSlug(key, value)}
          size="small"
          sx={{
            '& .MuiChip-deleteIcon': {
              color: 'primary.main',
              '&:hover': {
                color: 'error.main',
              },
            },
          }}
          variant="light"
          onDelete={() => setFieldValue(key, Array.isArray(value) ? [] : null)}
        />
      ))}

      {/* <ButtonGroup sx={{ backgroundColor: 'primary.100' }}>
        <Button size="small" onClick={handleReset}>
          Aplicar filtros por defecto
        </Button>

        <Button size="small" onClick={handleReset}>
          <CheckIcon fontSize="small" />
        </Button>
      </ButtonGroup> */}
    </Stack>
  );
};
