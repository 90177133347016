import { gql } from '@apollo/client';

export const DirectoriesTableHeaderFiltersQuery = gql`
  query tags($studioId: ID!) {
    tags(studio: $studioId) {
      name
    }

    entityStates(studioId: $studioId) {
      name
      color
    }
  }
`;
