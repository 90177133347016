import React from 'react';
import { Grid, IconButton, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';

const SubmitButton = ({
  children = 'Submit',
  gridProps = { xs: 12 },
  form,
  disabled,
  as = 'button',
  loading,
  fullWidth = false,
  ...rest
}) => {
  const { dirty, isValid, isSubmitting } = useFormikContext();

  const endIcon = isSubmitting ? (
    <CircularProgress color="primary" size={10} />
  ) : undefined;

  return (
    <Grid item {...gridProps}>
      {as === 'button' && (
        <LoadingButton
          color="primary"
          disabled={!isValid || disabled}
          form={form}
          fullWidth={fullWidth}
          loading={loading || isSubmitting}
          type="submit"
          variant="contained"
          {...rest}
        >
          {children}
        </LoadingButton>
      )}

      {as === 'icon' && (
        <IconButton
          color="primary"
          disabled={!dirty || !isValid || isSubmitting || disabled}
          form={form}
          type="submit"
          {...rest}
        >
          {isSubmitting ? endIcon : children}
        </IconButton>
      )}
    </Grid>
  );
};

export default SubmitButton;
