import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

import { getEntityType } from 'src/utils/formatters';

const TypeCell = ({ row }) => (
  <Box
    sx={{
      maxWidth: '40rem',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      gap: 1,
    }}
  >
    <Typography
      role="button"
      sx={{
        fontWeight: '500',
      }}
    >
      {getEntityType(row.type)}
    </Typography>

    {!!row.tags?.length && (
      <Box sx={{ gap: 0.4, display: 'flex' }}>
        {row.tags?.map((tag) => (
          <Chip
            key={tag.name}
            label={tag.name}
            size="small"
            sx={{
              color: 'primary.dark',
              backgroundColor: 'primary.100',
            }}
          />
        ))}
      </Box>
    )}
  </Box>
);

export default TypeCell;
