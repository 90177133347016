import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { useExpenses } from '@legalsurf/hooks';
import { useSnackbar } from 'src/components/v3/Snackbar';

import Table from 'src/components/v3/Table';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import WarningDialog from 'src/dialogs/WarningDialog';

import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import { DeleteExpenseMutation } from 'src/pages/Dashboard/Expenses/ExpensesQueries';
import { ExpensesQuery } from '@legalsurf/queries';

import { useDialog } from 'src/dialogs/Dialogs';
import Header from './Header';
import { getColumns } from './columns';
import { useExpensesToExport } from './excel';

const columns = getColumns();

const ExpensesTable = ({ ...props }) => {
  const studioId = useStudioId();
  const [selectedItems, setSelectedItems] = useState([]);
  const { openSnackbar } = useSnackbar();
  const [openExpenseDeletionWarning, setOpenExpenseDeletionWarning] =
    useState(false);
  const dispatchExpenseForm = useDialog('expenseForm');
  const dispatchExpenseDialog = useDialog('expense');

  const [deleteExpense, { loading: isDeletingExpense }] = useMutation(
    DeleteExpenseMutation,
    {
      refetchQueries: [ExpensesQuery, GET_ENTITY_SINGLE_PAGE_METADATA],
    },
  );

  const { data, refetch, loading, variables } = useExpenses(
    {
      studio: studioId,
    },
    {
      skip: !studioId,
      notifyOnNetworkStatusChange: true,
      partialRefetch: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setSelectedItems([]),
    },
  );

  const handleDelete = useCallback(async () => {
    try {
      const plural = selectedItems.length ? 'Gastos' : 'Gasto';

      const promises = selectedItems.map((id) =>
        deleteExpense({
          variables: {
            id,
          },
        }),
      );

      await Promise.allSettled(promises);

      openSnackbar(`${plural} eliminado exitosamente.`, {
        severity: 'success',
      });
    } catch (error) {
      openSnackbar(
        'Hubo un problema al eliminar el/los gastos. Intente mas tarde.',
        {
          severity: 'error',
        },
      );
    } finally {
      setOpenExpenseDeletionWarning(false);
    }
  }, [openSnackbar, deleteExpense, selectedItems]);

  const { getExpensesToExport, loading: loadingExpensesToExport } =
    useExpensesToExport();

  const handleDownloadAsExcel = async () => {
    await getExpensesToExport({ ...variables, ids: selectedItems });
  };

  return (
    <>
      <Table
        columns={columns}
        components={{
          toolbar: Header,
        }}
        componentsProps={{
          toolbar: {
            totalItems: data.length,
            loading,
            refetch,
            selectedItems,
            setSelectedItems,
            setShowModalExpense: () => dispatchExpenseForm('ExpensesTable'),
            setOpenExpenseDeletionWarning,
            onDownloasdAsExcel: handleDownloadAsExcel,
            loadingExpensesToExport,
          },
          noResultsOverlay: {
            variant: 'expenses',
          },
          noRowsOverlay: {
            variant: 'expenses',
          },
        }}
        loading={loading}
        paginationMode="client"
        rowCount={data.length}
        rows={data}
        rowsPerPageOptions={[]}
        rowSelectionModel={selectedItems}
        onRowClick={({ row }) => {
          dispatchExpenseDialog(row.id, {
            expenseId: row.id,
          });
        }}
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        {...props}
      />

      <WarningDialog
        loading={isDeletingExpense}
        open={openExpenseDeletionWarning}
        onAccept={handleDelete}
        onCancel={() => setOpenExpenseDeletionWarning(false)}
      />
    </>
  );
};

export default ExpensesTable;
