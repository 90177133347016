import React from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';

export const ResponsibleCell = ({ row }) =>
  row.responsible?.id ? (
    <Box>
      <Tooltip key={row.responsible.id} title={row.responsible.name}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Avatar
            alt="Responsable"
            size="small"
            src={row.responsible.picture}
            sx={{ margin: 1, width: 30, height: 30 }}
          />
        </Box>
      </Tooltip>
    </Box>
  ) : (
    'N/A'
  );
