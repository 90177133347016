import React from 'react';

import { Formik } from 'formik';
import { Button, Grid, Divider } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useNavigate } from 'react-router-dom';

import Form, {
  TextField,
  SubmitButton,
  ImageField,
} from 'src/components/ui-components/Form';
import { CountriesAutocomplete } from 'src/autocompletes';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { UPDATE_STUDIO } from 'src/graphql/mutations/studios';
import { GET_MY_STUDIOS } from 'src/graphql/queries/studios';
import { useStudio } from 'src/utils/hooks/useStudio';
import StudioFormSchemaValidation from './validation-schema';
import StudioFormSkeleton from './Skeleton';

export const StudioConfigurationForm = () => {
  const navigate = useNavigate();
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const { studio, loading } = useStudio();
  const [updateStudio] = useMutation(UPDATE_STUDIO, {
    refetchQueries: [GET_MY_STUDIOS],
  });

  const initialValues = {
    file: null,
    picture: studio?.picture,
    studioName: studio?.name,
    phone: studio.address?.phone,
    country: studio.address?.country ? {
      label: studio.address?.country,
      value: studio.address?.country,
    } : null,
    city: studio.address?.city,
    floor: studio.address?.floor,
    street: studio.address?.street,
  };

  const handleSubmit = async (values, formikBag) => {
    try {
      await updateStudio({
        variables: {
          studio: studioId,
          name: values.studioName,
          picture: values.file,
          phone: values.phone,
          country: values.country?.label ?? null,
          city: values.city,
          floor: values.floor,
          street: values.street,
        },
      });

      openSnackbar('Se ha editado exitosamente.', {
        severity: 'success',
      });
    } catch ({ message }) {
      openSnackbar(message ?? 'Hubo un error.', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  if (loading) {
    return <StudioFormSkeleton />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={StudioFormSchemaValidation}
      onSubmit={handleSubmit}
    >
      <Form id="studioConfigurationForm">
        <Grid item sx={{ textAlign: 'center' }} xs={12}>
          <ImageField imagenUser={initialValues.picture} name="file" />
        </Grid>

        <TextField
          label="Nombre del estudio"
          name="studioName"
          placeholder="Ej. Instituto Law's"
        />

        <TextField
          label="Numero de contacto"
          name="phone"
          placeholder="Ej. 0 11 1234-1234"
        />

        <CountriesAutocomplete label="Pais" name="country" />

        <TextField
          label="Ciudad"
          name="city"
          placeholder="Ej. Capital Federal"
        />
        <TextField
          label="Direccion"
          name="street"
          placeholder="Ej. Av. Libertador 1234"
        />
        <TextField
          label="Piso / Departamento"
          name="floor"
          placeholder="PB 1"
        />

        <Divider />

        <Grid container item xs={12}>
          <Grid item sm xs={12}>
            <Button fullWidth onClick={() => navigate(-1)}>
              Cancelar
            </Button>
          </Grid>

          <SubmitButton
            fullWidth
            form="studioConfigurationForm"
            gridProps={{ xs: 12, sm: true }}
            type="submit"
          >
            Guardar cambios
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  );
};
