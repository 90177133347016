import React, { useState } from 'react';

import { Box, Tooltip, IconButton } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';

import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import {
  isValidWhatsappNumber,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';
import { entityTypesObj } from '@legalsurf/common';
import { useUser } from 'src/utils/hooks/useUser';

export const TemplateCell = ({ row: sale }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useUser();

  const handleOpenFilecasesPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const isWhatsappValid = isValidWhatsappNumber(sale.entity.phone);
  const isPhoneEmpty =
    sale.entity.phone === null || sale.entity.phone?.length === 0;
  const isPhoneValid = isWhatsappValid || isPhoneEmpty;

  const tooltipTitle = `Envia plantillas personalizados por Whatsapp agregando primero un número de teléfono valido a (${
    entityTypesObj[sale.entity.type]
  }) ${sale.entity.displayName}`;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip
        disableFocusListener={isPhoneValid}
        disableHoverListener={isPhoneValid}
        disableTouchListener={isPhoneValid}
        title={tooltipTitle}
      >
        <span>
          <IconButton
            color="inherit"
            disabled={!isPhoneValid}
            size="small"
            variant="text"
            onClick={handleOpenFilecasesPopover}
          >
            <WhatsApp
              sx={{
                color: isWhatsappValid ? '#24d366' : undefined,
                opacity: !isPhoneEmpty ? 1 : 0.85,
              }}
            />
          </IconButton>
        </span>
      </Tooltip>

      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ sale, filecase: sale?.filecase, user }}
        entity={sale.entity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg(sale.entity.phone, msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />
    </Box>
  );
};
