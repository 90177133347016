import React, { useRef } from 'react';
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  Avatar,
  Card,
  CardContent,
  CardActions,
  Button,
  AvatarGroup,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  LSDirectory as LSDirectoryIcon,
  LSMail as LSMailIcon,
} from 'src/components/icons';
import { useQuery } from '@apollo/client';

import Page from 'src/components/v2/Page';

import { useReactToPrint } from 'react-to-print';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { useParams } from 'react-router-dom';
import FilecaseDetailsSkeleton from '../FilecaseDetailsSkeleton';
import { SingleFilecaseEntities } from '../SingleFilecaseEntities';

export const FilecasePDF = () => {
  const { filecaseId } = useParams();

  const { data: { filecase } = {}, loading } = useQuery(
    GET_FILECASE_SINGLE_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filecase: filecaseId,
      },
      skip: !filecaseId,
    },
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (loading) {
    return <FilecaseDetailsSkeleton />;
  }

  return (
    <Page sx={{ backgroundColor: '#f4faff', minHeight: '100vh' }}>
      <Card
        sx={{
          margin: 'auto',
          maxWidth: 1000,
        }}
      >
        <CardContent
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <Box
            ref={componentRef}
            sx={{
              p: 4,
              // Overrides any inputs background, if this patter repeats, find a better way.
              fieldset: { backgroundColor: '#FFF !important' },

              '& :before': {
                display: 'none',
              },
            }}
          >
            <LSMailIcon color="primary" sx={{ mr: 2 }} />
            <Box>
              {filecase?.externalId && (
                <Typography fontWeight={600} variant="h5">
                  Nro. {filecase.externalId}
                </Typography>
              )}
              <Typography sx={{ fontWeight: '600', fontSize: 23 }} variant="h3">
                {filecase?.title}
              </Typography>

              <Box sx={{ mt: 2 }}>
                {(filecase?.state || filecase?.category || filecase?.side) && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {filecase?.state && (
                      <Chip
                        label={filecase.state.name}
                        size="small"
                        variant="custom"
                      />
                    )}

                    {filecase?.category && (
                      <Chip
                        color="primary"
                        label={filecase.category.name}
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {filecase?.side && (
                      <Chip
                        color="primary"
                        label={filecase.side.name}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Box>
                )}

                {filecase?.description && (
                  <Typography
                    sx={{
                      fontWeight: '600',
                      whiteSpace: 'pre-line',
                      marginBottom: 2,
                      marginTop: 6,
                    }}
                    variant="h4"
                  >
                    {filecase?.description}
                  </Typography>
                )}

                {!!(Array.isArray(filecase?.tags) && filecase.tags.length) && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {filecase.tags.map((tag) => (
                      <Chip key={tag.name} label={tag.name} size="small" />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {filecase?.assigned?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  Responsables
                  <AvatarGroup
                    max={3}
                    spacing="small"
                    total={filecase.assigned.length}
                  >
                    {filecase.assigned?.map((assignee) => (
                      <Tooltip key={assignee.id} title={assignee.name}>
                        <Avatar
                          alt="Avatar"
                          size="small"
                          src={assignee.picture}
                          sx={{ margin: 1, width: 30, height: 30 }}
                        />
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                </Box>
              )}
            </Box>

            <Timeline position="right" sx={{ padding: 0 }}>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: 'grey.300', boxShadow: 'none' }}
                  >
                    <LSDirectoryIcon color="primary" />
                  </TimelineDot>
                  <TimelineConnector
                    sx={{
                      width: '1px',
                      border: '1px dashed',
                      borderColor: 'grey.300',
                    }}
                  />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <SingleFilecaseEntities filecase={filecase} />
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            sx={{
              marginLeft: 'auto',
            }}
            variant="filled"
            onClick={handlePrint}
          >
            ⬇ Descargar Expediente
          </Button>
        </CardActions>
      </Card>
    </Page>
  );
};
