import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { SettingsOutlined } from '@mui/icons-material';
import {
  LSCalendar,
  LSDirectory,
  LSDocument,
  LSExpense,
  LSMail,
  LSSale,
  LSStats,
} from 'src/components/icons';
import LSClock from 'src/components/icons/LSClock';

// TODO: Move to constants or other place
export const permissionModules = [
  {
    name: 'Expedientes',
    Icon: LSMail,
    actions: [
      {
        name: 'Acceder a expedientes',
        slug: LS_PERMISSIONS_MAP.FILECASES.ACCESS,
      },
      {
        name: 'Ver detalle de expedientes',
        slug: LS_PERMISSIONS_MAP.FILECASES.READ,
      },
      {
        name: 'Crear expedientes',
        slug: LS_PERMISSIONS_MAP.FILECASES.CREATE,
      },
      {
        name: 'Editar expedientes',
        slug: LS_PERMISSIONS_MAP.FILECASES.UPDATE,
      },
      {
        name: 'Eliminar expedientes',
        slug: LS_PERMISSIONS_MAP.FILECASES.DELETE,
      },
    ],
  },
  {
    name: 'Directorio',
    Icon: LSDirectory,
    actions: [
      {
        name: 'Acceder a directorio',
        slug: LS_PERMISSIONS_MAP.DIRECTORY.ACCESS,
      },
      {
        name: 'Visualizar persona',
        slug: LS_PERMISSIONS_MAP.DIRECTORY.READ,
      },
      {
        name: 'Crear persona',
        slug: LS_PERMISSIONS_MAP.DIRECTORY.CREATE,
      },
      {
        name: 'Editar persona',
        slug: LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
      },
      {
        name: 'Eliminar persona',
        slug: LS_PERMISSIONS_MAP.DIRECTORY.DELETE,
      },
    ],
  },
  {
    name: 'Gestion',
    Icon: LSCalendar,
    actions: [
      {
        name: 'Acceder a calendario',
        slug: LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
      },
      {
        name: 'Crear eventos/tareas',
        slug: LS_PERMISSIONS_MAP.CALENDAR.CREATE,
      },
      {
        name: 'Editar eventos/tareas',
        slug: LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
      },
      {
        name: 'Eliminar eventos/tareas',
        slug: LS_PERMISSIONS_MAP.CALENDAR.DELETE,
      },
    ],
  },
  {
    name: 'Time Tracking',
    Icon: LSClock,
    actions: [
      {
        name: 'Acceder a time tracking',
        slug: LS_PERMISSIONS_MAP.TIME_TRACKING.ACCESS,
      },
    ],
  },
  {
    name: 'Honorarios',
    Icon: LSSale,
    actions: [
      {
        name: 'Acceder a honorarios/cobros',
        slug: LS_PERMISSIONS_MAP.SALES.ACCESS,
      },
      {
        name: 'Crear honorario/cobro',
        slug: LS_PERMISSIONS_MAP.SALES.CREATE,
      },
      {
        name: 'Editar honorario/cobro',
        slug: LS_PERMISSIONS_MAP.SALES.UPDATE,
      },
      {
        name: 'Eliminar honorario/cobro',
        slug: LS_PERMISSIONS_MAP.SALES.DELETE,
      },
    ],
  },
  {
    name: 'Gastos',
    Icon: LSExpense,
    actions: [
      {
        name: 'Acceder a gastos/pagos',
        slug: LS_PERMISSIONS_MAP.EXPENSES.ACCESS,
      },
      {
        name: 'Crear gasto/pago',
        slug: LS_PERMISSIONS_MAP.EXPENSES.CREATE,
      },
      {
        name: 'Editar gasto/pago',
        slug: LS_PERMISSIONS_MAP.EXPENSES.UPDATE,
      },
      {
        name: 'Eliminar gasto/pago',
        slug: LS_PERMISSIONS_MAP.EXPENSES.DELETE,
      },
    ],
  },
  {
    name: 'Documentos',
    Icon: LSDocument,
    actions: [
      {
        name: 'Acceder y visualizar documentos',
        slug: LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
      },
      {
        name: 'Adjuntar documento',
        slug: LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
      },
      {
        name: 'Editar documento',
        slug: LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,
      },
      {
        name: 'Eliminar documento',
        slug: LS_PERMISSIONS_MAP.DOCUMENTS.DELETE,
      },
    ],
  },
  {
    name: 'Informes',
    Icon: LSStats,
    actions: [
      {
        name: 'Acceder a informes',
        slug: LS_PERMISSIONS_MAP.REPORTS.ACCESS,
      },
    ],
  },
  {
    name: 'Configuracion',
    Icon: SettingsOutlined,
    actions: [
      {
        name: 'Acceder a configuracion',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS,
      },
      {
        name: 'Acceder a cuentas y permisos',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS_ACCOUNTS_AND_PERMISSIONS,
      },
      {
        name: 'Acceder a Cuenta LegalSurf',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS_ACCOUNT,
      },
      {
        name: 'Acceder a Datos de Estudio',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS_STUDIO_DATA,
      },
      {
        name: 'Crear Categorías / estados / etiquetas ',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
      },
      {
        name: 'Acceso a administrar plantillas de WhatsApp',
        slug: LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
      },
    ],
  },
];
