import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent } from '@mui/material';
import * as Yup from 'yup';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';

const shape = {
  concept: Yup.string().required('Debes agregar un concepto a tu cobro'),
};

const validationSchema = Yup.lazy((values) => {
  const maxAmount = values.expenseTotal - values.expensePaid;

  return Yup.object({
    ...shape,
    date: YupDateValidations,
    amount: Yup.number()
      .positive('Debe ser un monto positivo')
      .max(maxAmount, `La cantidad no puede exceder: ${maxAmount}`)
      .required('Debes agregar un monto a tu honorario'),
    paymentMethod: Yup.object()
      .nullable()
      .required('Debes agregar un metodo de pago'),
  });
});

const PaymentForm = ({
  initialValues,
  onSubmit,
  onCancel,
  buttonLabel,
  isEdit,
  type,
  ...rest
}) => (
  <Formik
    {...rest}
    enableReinitialize
    initialValues={initialValues}
    validationSchema={
      !isEdit
        ? validationSchema
        : Yup.object({
            ...shape,
            paymentMethod: Yup.object()
              .nullable()
              .required('Debes agregar un metodo de pago'),

            concept: Yup.string().required(
              'Debes agregar un concepto a tu cobro',
            ),
          })
    }
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <>
        <DialogContent>
          <Form id="paymentForm">
            <DateField label="Fecha" name="date" />

            <MoneyField
              disabledCurrency
              amountName="amount"
              currencyName="currency"
              disabled={isEdit}
              label="Monto"
              type="number"
            />

            <TextField label="Concepto" name="concept" />

            <SelectField
              label="Medio de pago"
              name="paymentMethod"
              options={DEFAULT_PAYMENT_METHODS}
            />
          </Form>
        </DialogContent>

        <DialogActions>
          <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
            Cancelar
          </Button>

          <SubmitButton fullWidth form="paymentForm">
            {buttonLabel}
          </SubmitButton>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default PaymentForm;
