import { gql } from '@apollo/client';

export const CREATE_ENTITY = gql`
  mutation createEntity(
    $studio: ID!
    $name: String
    $firstname: String
    $lastname: String
    $businessname: String
    $email: String
    $identification: String
    $country: String
    $location: String
    $address: String
    $website: String
    $number: String
    $postalCode: String
    $phone: String
    $college: String
    $collegeIdentification: String
    $tags: [String!]
    $type: EntityType
    $state: ID
    $filecases: [ID!]
  ) {
    createEntity(
      studio: $studio
      name: $name
      firstname: $firstname
      lastname: $lastname
      businessname: $businessname
      email: $email
      tags: $tags
      identification: $identification
      country: $country
      location: $location
      address: $address
      website: $website
      number: $number
      postalCode: $postalCode
      phone: $phone
      college: $college
      type: $type
      collegeIdentification: $collegeIdentification
      state: $state
      filecases: $filecases
    ) {
      id
      type
      displayName
      type
      email
      phone
      state {
        id
        color
        name
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const UPDATE_ENTITY = gql`
  mutation updateEntity(
    $id: ID!
    $name: String
    $firstname: String
    $lastname: String
    $businessname: String
    $identification: String
    $email: String
    $country: String
    $location: String
    $city: String
    $number: String
    $postalCode: String
    $phone: String
    $address: String
    $type: EntityType
    $departament: String
    $description: String
    $tags: [String!]
    $state: ID
  ) {
    updateEntity(
      id: $id
      name: $name
      firstname: $firstname
      lastname: $lastname
      businessname: $businessname
      identification: $identification
      email: $email
      country: $country
      location: $location
      number: $number
      postalCode: $postalCode
      phone: $phone
      city: $city
      address: $address
      type: $type
      departament: $departament
      description: $description
      tags: $tags
      state: $state
    ) {
      id
      firstname
      lastname
      businessname
      type
      state {
        id
        name
        color
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const DELETE_ENTITY = gql`
  mutation deleteEntity($id: ID!) {
    deleteEntity(id: $id) {
      id
    }
  }
`;

export const DELETE_ENTITIES = gql`
  mutation deleteEntities($ids: [ID!], $studioId: ID!) {
    deleteEntities(ids: $ids, studioId: $studioId)
  }
`;

export const CreateEntityComment = gql`
  mutation CreateEntityComment(
    $entityId: ID!
    $studioId: ID!
    $content: String!
  ) {
    createEntityComment(
      entityId: $entityId
      content: $content
      studioId: $studioId
    ) {
      id
      content
      entityId
      updatedAt
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

export default null;
