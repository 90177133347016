import React, { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';

import { LS_PERMISSIONS_MAP, ROLES_MAP, entityTypes } from '@legalsurf/common';

import WarningDialog from 'src/dialogs/WarningDialog';
import SectionHeader from 'src/components/v2/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GET_PAGINATED_ENTITIES } from 'src/graphql/queries/entities';
import { DELETE_ENTITIES } from 'src/graphql/mutations/entities';
import { defaultPageSize } from 'src/components/v3/Table';
import { DirectoriesTableHeaderFiltersQuery } from './queries';

const initialDirectoriesFiltersQueryVars = {
  search: null,
  tags: null,
  type: null,
  limit: defaultPageSize,
  offset: 0,
  entityState: null,
};

const initialValues = {
  search: '',
  type: null,
  tags: [],
  limit: defaultPageSize,
  offset: 0,
  entityState: null,
};

const Header = ({
  totalItems,
  refetch,
  loading,
  selectedItems,
  setSelectedItems,
  loadingEntitiesToExport,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const studioId = useStudioId();

  const dispatchEntityFormDialog = useDialog('entityForm');
  const { role, permissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.READ,
  ]);

  const hasPermissionsToDelete = permissions.includes(
    LS_PERMISSIONS_MAP.DIRECTORY.DELETE,
  );
  const hasPermissionsToCreate = permissions.includes(
    LS_PERMISSIONS_MAP.DIRECTORY.CREATE,
  );

  const [deleteEntities, { loading: isDeleting }] = useMutation(
    DELETE_ENTITIES,
    {
      refetchQueries: [GET_PAGINATED_ENTITIES],
      update: (cache, data, options) => {
        setSelectedItems([]);

        options.variables.ids?.forEach((id) => {
          const normalizedId = cache.identify({
            id,
            __typename: 'Entity',
          });

          cache.evict({ id: normalizedId });
        });

        cache.gc();
      },
    },
  );

  const filters = useQuery(DirectoriesTableHeaderFiltersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studioId,
    },
  });

  const handleFiltersSubmit = useCallback(
    (values) =>
      refetch({
        studio: studioId,
        search: values.search,
        type: values.type?.value,
        entityState: values.entityState?.value,
        tags: values.tags?.map((tag) => tag.value) || [],
      }),
    [refetch, studioId],
  );

  return (
    <Box sx={{ p: 2 }}>
      <SectionHeader
        actions={
          role === ROLES_MAP.ADMIN ||
          role === ROLES_MAP.OWNER ||
          hasPermissionsToDelete
            ? [
                {
                  title: 'Eliminar',
                  handleChange: async () => {
                    setOpenWarnDialog(true);
                  },
                  disabled: !selectedItems.length || isDeleting,
                  icon: DeleteIcon,
                },
                {
                  title: `Descargar Excel (${
                    selectedItems?.length || 'Todos'
                  })`,
                  handleChange: handleDownloadAsExcel,
                  icon: DownloadIcon,
                  disabled: isDeleting || loadingEntitiesToExport,
                },
              ]
            : undefined
        }
        createButton={
          hasPermissionsToCreate ||
          role === ROLES_MAP.ADMIN ||
          role === ROLES_MAP.OWNER
            ? {
                title: 'Crear directorio',
                handleChange: () => {
                  dispatchEntityFormDialog('DirectoriesTableForm');
                },
              }
            : undefined
        }
        filters={{
          initialValues,
          loading: loading || filters.loading,
          onSubmit: handleFiltersSubmit,
          onReset: () => refetch(initialDirectoriesFiltersQueryVars),
          data: [
            {
              title: 'Tipo',
              name: 'type',
              options: entityTypes,
            },
            {
              title: 'Estado',
              name: 'entityState',
              options:
                filters.data &&
                filters.data.entityStates.map((state) => ({
                  label: state.name,
                  value: state.name,
                })),
            },
            {
              title: 'Etiquetas',
              name: 'tags',
              isMulti: true,
              options:
                filters.data &&
                filters.data.tags.map((tag) => ({
                  label: tag.name,
                  value: tag.name,
                })),
            },
          ],

          search: {
            placeholder: 'Buscar directorio',
            name: 'search',
          },
        }}
        subtitle={`${totalItems} totales`}
        title="Directorio"
      />

      <WarningDialog
        loading={isDeleting}
        open={openWarnDialog}
        onAccept={async () => {
          await deleteEntities({
            variables: {
              ids: selectedItems,
              studioId,
            },
          });

          setSelectedItems([]);
          setOpenWarnDialog(false);
        }}
        onCancel={() => setOpenWarnDialog(false)}
      />
    </Box>
  );
};

export default Header;
