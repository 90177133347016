import React, { useState } from 'react';

import {
  Box,
  MenuItem,
  IconButton,
  Menu,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import WarningDialog from 'src/dialogs/WarningDialog';

import { getFilesTableDocumentsQuery } from '../gql';

const ActionsCell = ({ row: document }) => {
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const { isDeleting, handleDeleteDocument } = useDigitalOceanSpace({
    refetchQueries: [getFilesTableDocumentsQuery],
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={isDeleting}
          onClick={() => setDocumentToDelete(document)}
        >
          <ListItemIcon>
            <DeleteIcon color="primary" fontSize="small" />
          </ListItemIcon>

          <ListItemText>Eliminar</ListItemText>
        </MenuItem>
      </Menu>

      <WarningDialog
        loading={isDeleting}
        open={Boolean(documentToDelete)}
        onAccept={async () => {
          await handleDeleteDocument([document.id]);
          setDocumentToDelete(null);
        }}
        onCancel={() => setDocumentToDelete(false)}
      />
    </Box>
  );
};

export default ActionsCell;
