import React from 'react';
import { create } from 'zustand';

import SaleDetailsDialog from 'src/pages/Dashboard/Sales/SaleDetailsDialog';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import ExpenseDetailsDialog from 'src/pages/Dashboard/Expenses/ExpenseDetailsDialog';
import FilePreviewer from 'src/components/v2/FilePreviewer';
import { usePostHog } from 'posthog-js/react';
import CalendarEventDetailsDialog from '../calendar/CalendarEventDetailsDialog';
import ChargeDialog from '../ChargeDialog';
import DirectoryDialog from '../DirectoryDialog';
import FilecaseDetailsDialog from '../FilecaseDetailsDialog';
import FilecaseFormDialog from '../FilecaseFormDialog';
import FilesFormDialog from '../FilesFormDialog';
import TransactionDialog from '../TransactionDialog';
import WarningDialog from '../WarningDialog';
import { EventFormDialog } from '../calendar/EventFormDialog';
import NotificationTemplateFormDialog from '../NotificationTemplateFormDialog';
import PaymentDialog from '../PaymentDialog';
import ExpenseDialog from '../ExpenseDialog';
import { ContractPreviewDialog } from '../ContractPreviewDialog';
import { CustomFieldFormDialog } from '../CustomFieldFormDialog';
import { WorkflowFormDialog } from '../WorkflowFormDialog';
import { WorkflowTimelineItemEventDialogForm } from '../WorkflowFormDialog/WorkflowTimelineContent/components/WorkflowTimelineItemEvent/WorkflowTimelineItemEventDialogForm';

const useDialogStore = create((set) => ({
  dialogs: {
    filecase: [],
    filecaseForm: [],
    eventForm: [],
    event: [],
    entityForm: [],
    documentsForm: [],
    salesForm: [],
    sale: [],
    chargeForm: [],
    warningPrompt: [],
    notificationTemplateFormDialog: [],
    expenseForm: [],
    expensesPaymentForm: [],
    expense: [],
    contractPreview: [],
    documentsPreview: [],
    customFieldForm: [],
    workflowsForm: [],
    workflowsEventForm: [],
  },
  setDialog: (dialog, key, data, open) =>
    set((state) => ({
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[key],
          [key]: {
            open,
            key,
            data: data ?? null,
          },
        },
      },
    })),
}));

const replaceVars = (str) => {
  // check if single uuid
  if (str.match(/^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i)) {
    return ':id';
  }

  // check if compund str with uuid + string
  const pattern = /(.+-)[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/i;
  const placeholder = ':id';

  return str.replace(pattern, `$1${placeholder}`);
};

export const useDialog = (dialog) => {
  const studioId = useStudioId();
  const posthog = usePostHog();

  return (key, data) => {
    useDialogStore.setState((state) => ({
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[key],
          [key]: {
            open: true,
            key,
            data: data ?? null,
          },
        },
      },
    }));

    posthog.capture('DialogView', {
      key,
      dialog,
      studioId,
    });
  };
};

export const DialogsProvider = () => {
  const [dialogs, setDialog] = useDialogStore((state) => [
    state.dialogs,
    state.setDialog,
  ]);

  const handleDialogClose = (dialog, key) => {
    setDialog(dialog, key, dialogs[dialog][key]?.data, false);
  };

  return (
    <>
      {Object.values(dialogs.filecase)?.map((filecase) => (
        <FilecaseDetailsDialog
          filecaseId={filecase?.open ? filecase?.data?.id : null}
          key={filecase?.key}
          onHide={() => handleDialogClose('filecase', filecase?.key)}
        />
      ))}
      {Object.values(dialogs.filecaseForm)?.map((filecaseForm) => (
        <FilecaseFormDialog
          {...filecaseForm?.data}
          filecase={filecaseForm?.data?.filecase}
          key={filecaseForm?.key}
          open={filecaseForm?.open}
          onClose={() => handleDialogClose('filecaseForm', filecaseForm?.key)}
        />
      ))}
      {Object.values(dialogs.eventForm)?.map((eventForm) => (
        <EventFormDialog
          {...eventForm?.data}
          calendarId={eventForm.data?.calendarId}
          eventId={eventForm.data?.id}
          filecase={eventForm.data?.filecase}
          key={eventForm.key}
          show={eventForm.open}
          type={eventForm.data?.type || 'alert'}
          onHide={() => handleDialogClose('eventForm', eventForm.key)}
        />
      ))}

      {Object.values(dialogs.event)?.map((event) => (
        <CalendarEventDetailsDialog
          {...event?.data}
          calendarId={event.data.calendarId}
          eventId={event.data.id}
          key={event.key}
          open={event.open}
          onClose={() => handleDialogClose('event', event.key)}
        />
      ))}

      {Object.values(dialogs.entityForm)?.map((entityForm) => (
        <DirectoryDialog
          key={entityForm.key}
          open={entityForm.open}
          onClose={() => handleDialogClose('entityForm', entityForm.key)}
          {...entityForm?.data}
        />
      ))}
      {Object.values(dialogs.documentsForm)?.map((documentsForm) => (
        <FilesFormDialog
          {...documentsForm?.data}
          key={documentsForm.key}
          open={documentsForm.open}
          onClose={() => handleDialogClose('documentsForm', documentsForm.key)}
        />
      ))}
      {Object.values(dialogs.documentsPreview)?.map((documentPreview) => (
        <FilePreviewer
          {...documentPreview?.data}
          document={documentPreview.data?.document}
          downloable={documentPreview.data?.downloable}
          key={documentPreview.key}
          open={documentPreview.open}
          onClose={() =>
            handleDialogClose('documentsPreview', documentPreview.key)
          }
        />
      ))}
      {Object.values(dialogs.salesForm)?.map((salesForm) => (
        <TransactionDialog
          {...salesForm?.data}
          key={salesForm.key}
          open={salesForm.open}
          title={
            salesForm.data?.id ? 'Editar honorario' : 'Crear nuevo honorario'
          }
          onClose={() => handleDialogClose('salesForm', salesForm.key)}
        />
      ))}
      {Object.values(dialogs.sale)?.map((sale) => (
        <SaleDetailsDialog
          key={sale.key}
          open={sale.open}
          saleId={sale.data?.id}
          onClose={() => handleDialogClose('sale', sale.key)}
        />
      ))}
      {Object.values(dialogs.chargeForm)?.map((chargeForm) => (
        <ChargeDialog
          charge={chargeForm.data?.charge}
          key={chargeForm.key}
          open={chargeForm.open}
          sale={chargeForm.data?.sale}
          title="Crear nuevo cobro"
          onClose={() => handleDialogClose('chargeForm', chargeForm.key)}
        />
      ))}
      {Object.values(dialogs.warningPrompt)?.map((warningPrompt) => (
        <WarningDialog
          key={warningPrompt.key}
          open={warningPrompt.open}
          onCancel={() => handleDialogClose('warningPrompt', warningPrompt.key)}
          onClose={() => handleDialogClose('warningPrompt', warningPrompt.key)}
          {...warningPrompt?.data}
        />
      ))}

      {Object.values(dialogs.notificationTemplateFormDialog)?.map(
        (notificationTemplateFormDialog) => (
          <NotificationTemplateFormDialog
            {...notificationTemplateFormDialog?.data}
            filecase={notificationTemplateFormDialog?.data?.filecase}
            key={notificationTemplateFormDialog?.key}
            open={notificationTemplateFormDialog?.open}
            onClose={() =>
              handleDialogClose(
                'notificationTemplateFormDialog',
                notificationTemplateFormDialog?.key,
              )
            }
          />
        ),
      )}

      {Object.values(dialogs.expense)?.map((expense) => (
        <ExpenseDetailsDialog
          {...expense?.data}
          key={expense.key}
          open={expense.open}
          saleId={expense.data?.id}
          onClose={() => handleDialogClose('expense', expense.key)}
        />
      ))}

      {Object.values(dialogs.expenseForm)?.map((expenseForm) => (
        <ExpenseDialog
          {...expenseForm?.data}
          key={expenseForm.key}
          open={expenseForm.open}
          saleId={expenseForm.data?.id}
          onClose={() => handleDialogClose('expenseForm', expenseForm.key)}
        />
      ))}

      {Object.values(dialogs.expensesPaymentForm)?.map(
        (expensesPaymentForm) => (
          <PaymentDialog
            {...expensesPaymentForm?.data}
            key={expensesPaymentForm?.key}
            open={expensesPaymentForm?.open}
            onClose={() =>
              handleDialogClose('expensesPaymentForm', expensesPaymentForm?.key)
            }
          />
        ),
      )}

      {Object.values(dialogs.contractPreview)?.map((contractPreview) => (
        <ContractPreviewDialog
          {...contractPreview?.data}
          key={contractPreview?.key}
          open={contractPreview?.open}
          onClose={() => {
            contractPreview?.data?.onClose?.();
            handleDialogClose('contractPreview', contractPreview?.key);
          }}
        />
      ))}

      {Object.values(dialogs.customFieldForm)?.map((customFieldForm) => (
        <CustomFieldFormDialog
          {...customFieldForm?.data}
          key={customFieldForm?.key}
          open={customFieldForm?.open}
          onClose={() =>
            handleDialogClose('customFieldForm', customFieldForm?.key)
          }
        />
      ))}

      {Object.values(dialogs.workflowsForm)?.map((workflowForm) => (
        <WorkflowFormDialog
          {...workflowForm?.data}
          key={workflowForm?.key}
          open={workflowForm?.open}
          onClose={() => handleDialogClose('workflowsForm', workflowForm?.key)}
        />
      ))}

      {Object.values(dialogs.workflowsEventForm)?.map((workflowEventForm) => (
        <WorkflowTimelineItemEventDialogForm
          {...workflowEventForm?.data}
          key={workflowEventForm?.key}
          open={workflowEventForm?.open}
          onClose={() =>
            handleDialogClose('workflowsEventForm', workflowEventForm?.key)
          }
        />
      ))}
    </>
  );
};
