import { gql } from '@apollo/client';

export const CREATE_STUDIO = gql`
  mutation createStudio(
    $name: String!
    $country: String
    $phone: String
    $metadata: StudioCreationMetadata
  ) {
    createStudio(
      name: $name
      country: $country
      phone: $phone
      metadata: $metadata
    ) {
      id
      name
      slug
      createdAt
    }
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio(
    $studio: ID!
    $name: String!
    $phone: String
    $country: String
    $city: String
    $floor: String
    $street: String
    $picture: Upload
  ) {
    updateStudio(
      studio: $studio
      name: $name
      picture: $picture
      address: {
        phone: $phone
        country: $country
        city: $city
        floor: $floor
        street: $street
      }
    ) {
      id
      name
      slug
      address {
        id
        phone
        country
        city
        floor
        street
        city
      }
    }
  }
`;
export const CREATE_SALE = gql`
  mutation createSale(
    $studio: ID!
    $amount: Float!
    $hours: Int!
    $concept: String!
    $responsible: ID
    $tags: [String!]
    $date: DateTime!
    $entity: ID!
    $currency: ID
    $filecase: ID
    $canBeSeenByCreatorOnly: Boolean!
  ) {
    createSale(
      studio: $studio
      tags: $tags
      amount: $amount
      hours: $hours
      responsible: $responsible
      date: $date
      concept: $concept
      entity: $entity
      currency: $currency
      filecase: $filecase
      canBeSeenByCreatorOnly: $canBeSeenByCreatorOnly
    ) {
      id
      date
      concept
      amount
      hours
      charged

      currency {
        id
        name
        studioId
      }

      entity {
        id
        type
        displayName
        phone
      }
      responsible {
        id
        picture
        name
      }
      filecase {
        id
        title
        externalId
        description
        state {
          id
          studioId
          color
          name
        }
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const UPDATE_SALE = gql`
  mutation updateSale(
    $studio: ID!
    $saleId: ID
    $tags: [String!]
    $concept: String!
    $responsible: ID
    $date: DateTime!
    $currency: ID
    $entity: ID!
    $filecase: ID
    $hours: Int!
  ) {
    updateSale(
      saleId: $saleId
      hours: $hours
      currency: $currency
      tags: $tags
      studio: $studio
      responsible: $responsible
      date: $date
      concept: $concept
      entity: $entity
      filecase: $filecase
    ) {
      id
      date
      concept
      amount
      hours
      charged

      currency {
        id
        name
        studioId
      }

      entity {
        id
        type
        displayName
        phone
      }
      responsible {
        id
        picture
        name
      }
      filecase {
        id
        title
        externalId
        description
        state {
          id
          studioId
          color
          name
        }
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const ASSIGN_STUDIO_PERMISSION_TO_MEMBER = gql`
  mutation assignStudioPermissionToMember(
    $studioId: ID!
    $permission: String!
    $memberId: ID!
  ) {
    assignStudioPermissionToMember(
      permission: $permission
      memberId: $memberId
      studioId: $studioId
    ) {
      id
      name
      permissions
    }
  }
`;

export const REMOVE_STUDIO_PERMISSION_TO_MEMBER = gql`
  mutation removeStudioPermissionToMember(
    $studioId: ID!
    $permission: String!
    $memberId: ID!
  ) {
    removeStudioPermissionToMember(
      permission: $permission
      memberId: $memberId
      studioId: $studioId
    ) {
      id
      name
      permissions
    }
  }
`;

export const ASSIGN_STUDIO_ROLE_TO_MEMBER = gql`
  mutation assignStudioRoleToMember(
    $studioId: ID!
    $role: String!
    $memberId: ID!
  ) {
    assignStudioRoleToMember(
      role: $role
      memberId: $memberId
      studioId: $studioId
    ) {
      id
      name
      role
      permissions
    }
  }
`;

export const REMOVE_STUDIO_ROLE_TO_MEMBER = gql`
  mutation removeStudioRoleToMember(
    $studioId: ID!
    $role: String!
    $memberId: ID!
  ) {
    removeStudioRoleToMember(
      role: $role
      memberId: $memberId
      studioId: $studioId
    ) {
      id
      name
      role
      permissions
    }
  }
`;
