import React from 'react';

import { ChargedCell } from './ChargedCell';
import { DateCell } from './DateCell';
import { EntityCell } from './EntityCell';
import { ResponsibleCell } from './ResponsibleCell';
import { AmountCell } from './AmountCell';
import { ConceptCell } from './ConceptCell';
import { TemplateCell } from './TemplateCell';

export const useSalesColumns = ({ entities = true } = {}) =>
  [
    {
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerName: 'Concepto',
      field: 'concept',
      renderCell: (cell) => <ConceptCell {...cell} />,
    },
    entities
      ? {
          disableColumnMenu: true,
          sortable: false,
          headerName: 'Cliente',
          field: 'entity',
          width: 240,
          renderCell: (cell) => <EntityCell {...cell} />,
        }
      : false,
    {
      disableColumnMenu: true,
      sortable: false,
      width: 120,
      headerName: 'Cantidad',
      field: 'amount',
      renderCell: (cell) => <AmountCell {...cell} />,
    },
    {
      disableColumnMenu: true,
      sortable: false,
      headerName: 'Cobrado',
      field: 'charged',
      width: 160,
      height: 100,
      renderCell: (cell) => <ChargedCell {...cell} />,
    },
    {
      disableColumnMenu: true,
      sortable: false,
      headerName: 'Responsable',
      field: 'responsible',
      width: 120,
      renderCell: (cell) => <ResponsibleCell {...cell} />,
    },
    {
      disableColumnMenu: true,
      sortable: false,
      headerName: 'Creación',
      field: 'date',
      width: 140,
      renderCell: (cell) => <DateCell {...cell} />,
    },
    {
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: 'WhatsApp',
      field: 'whatsapp',
      // width: 200,
      renderCell: (cell) => <TemplateCell {...cell} />,
    },
  ].filter((x) => !!x);
