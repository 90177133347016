import React from 'react';
import { Dialog } from '@mui/material';
import { ExpenseDetailsDialogContent } from './ExpenseDetailsDialogContent';

const ExpenseDetailsDialog = ({ expenseId, onClose, ...props }) => (
  <Dialog fullWidth maxWidth="md" onClose={onClose} {...props}>
    <ExpenseDetailsDialogContent expenseId={expenseId} onClose={onClose} />
  </Dialog>
);

export default ExpenseDetailsDialog;
