const MuiInputLabel = {
  styledOverrides: {
    root: {
      fontWeight: 500,
      marginBottom: 16,
      fontSize: 16,
    },
  },
};

export default MuiInputLabel;
