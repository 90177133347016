import React, { useRef } from 'react';
import {
  Avatar,
  Box,
  Typography,
  useTheme,
  Button,
  CardContent,
  CircularProgress,
  Card,
  CardActions,
  Chip,
} from '@mui/material';

import Page from 'src/components/v2/Page';
import { useReactToPrint } from 'react-to-print';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toDateFormat } from 'src/utils/dates';
import { formatMoney } from 'src/utils/formatters';
import { GET_CHARGE } from '../SalesQueries';
import cardVouche from '../VoucherSale/cardVouche.png';

export const VoucherCharge = () => {
  const { studioId, chargeId } = useParams();
  const theme = useTheme();

  const { data, loading } = useQuery(GET_CHARGE, {
    variables: {
      id: chargeId,
      studioId,
    },
  });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  if (loading) return <CircularProgress />;

  return (
    <Page sx={{ backgroundColor: '#f4faff', minHeight: '100vh' }}>
      <Card
        sx={{
          margin: 'auto',
          maxWidth: 700,
          border: '1px solid #e2e7ec',
          boxShadow: 'none',
          borderRadius: '0',
        }}
      >
        <CardContent ref={componentRef} sx={{ display: 'flex' }}>
          <Box
            sx={{
              margin: 4,
              marginLeft: 7,
              width: '100%',
              display: 'flex',
              gap: 1.1,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'contents' }}>
              <Avatar
                alt={null}
                src={data.studio.picture}
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 80,
                }}
              />
              <Typography sx={{ fontWeight: 500, fontSize: 24 }} variant="span">
                {data.studio.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.grey[500],
                  fontSize: 14,
                }}
                variant="span"
              >
                Fecha: {toDateFormat(data.charge.date)}
              </Typography>
              <Typography
                sx={{
                  backgroundColor: 'rgb(127, 131, 255)',
                  padding: 0.9,
                  borderRadius: '0.3rem',
                  width: 'fit-content',
                  color: '#ffffff',
                  fontWeight: 500,
                  fontSize: 12,
                }}
                variant="span"
              >
                Comprobante de honorarios
              </Typography>

              <Chip
                color="primary"
                label={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === data.charge.method,
                  ).label
                }
                sx={{ mr: 2, maxWidth: 120 }}
                value={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === data.charge.method,
                  ).label
                }
                variant="light"
              />

              <Typography
                sx={{ fontWeight: 500, color: theme.palette.grey[400] }}
                variant="h5"
              >
                {data.charge.concept}
              </Typography>
              <Typography
                sx={{
                  fontSize: 36,
                  fontWeight: 600,
                  color: '#1A1A1A',
                }}
                variant="span"
              >
                {data.charge.currency?.name} {formatMoney(data.charge.amount)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ margin: 'auto' }}>
            <Avatar
              alt={null}
              src={cardVouche}
              style={{
                width: 94,
                height: 71,
                borderRadius: 80,
              }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            sx={{
              marginLeft: 'auto',
            }}
            variant="filled"
            onClick={handlePrint}
          >
            ⬇ Descargar Comprobante
          </Button>
        </CardActions>
      </Card>
    </Page>
  );
};
