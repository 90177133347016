import React from 'react';

import { SelectField } from 'src/components/ui-components/Form';

const options = Intl.supportedValuesOf('timeZone')
  .map((timezone) => ({
    label: timezone,
    value: timezone,
    // Sort by america first
  }))
  .sort((a) => (a.label.includes('America') ? -1 : 1));

const TimezonesAutocomplete = ({
  disabled,
  label = 'Zona Horaria',
  placeholder = `Ej. ${
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Mexico_City'
  }`,
  ...rest
}) => (
  <SelectField
    disabled={disabled}
    label={label}
    loading={false}
    options={options}
    placeholder={placeholder}
    {...rest}
  />
);

export default TimezonesAutocomplete;
