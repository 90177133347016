import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent } from '@mui/material';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';

const shape = {
  concept: Yup.string(),
};

const validationSchema = Yup.lazy((values) => {
  const maxAmount = values.saleTotal - values.saleCharged;

  return Yup.object({
    ...shape,
    amount: Yup.number()
      .positive('Debe ser un monto positivo')
      .max(maxAmount, `La cantidad no puede exceder: ${maxAmount}`)
      .required('Debes agregar un monto a tu honorario'),
    paymentMethod: Yup.object()
      .nullable()
      .required('Debes agregar un metodo de pago'),
  });
});

const ChargeForm = ({
  initialValues,
  onSubmit,
  onCancel,
  buttonLabel,
  isEdit,
  type,
  currency,
  ...rest
}) => {
  const defaultValues = {
    date: dayjs(),
    amount: 0,
    concept: '',
    responsible: null,
    paymentMethod: DEFAULT_PAYMENT_METHODS[0],
  };

  const validationSch = useMemo(
    () =>
      !isEdit
        ? validationSchema
        : Yup.object({
            ...shape,
            date: YupDateValidations,
            paymentMethod: Yup.object()
              .nullable()
              .required('Debes agregar un metodo de pago'),

            concept: Yup.string().required(
              'Debes agregar un concepto a tu cobro',
            ),
          }),
    [isEdit],
  );

  return (
    <Formik
      {...rest}
      initialValues={({ ...defaultValues }, { ...initialValues })}
      validationSchema={validationSch}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <DialogContent>
            <Form id="salesForm">
              <DateField label="Fecha" name="date" />

              <MoneyField
                disabledCurrency
                amountName="amount"
                currencyName="currency"
                disabled={isEdit}
                label="Monto"
                type="number"
              />

              <TextField
                multiline
                label="Concepto"
                minRows={3}
                name="concept"
              />

              <SelectField
                label="Medio de pago"
                name="paymentMethod"
                options={DEFAULT_PAYMENT_METHODS}
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
              Cancelar
            </Button>

            <SubmitButton fullWidth form="salesForm">
              {buttonLabel}
            </SubmitButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default ChargeForm;
