import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { Box, Card } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDocuments, useDraggableSections } from '@legalsurf/hooks';

import DetailSection from 'src/components/v2/FilecaseDetail/DetailSection';
import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import {
  LSBooks,
  LSCalendar,
  LSDocument,
  LSExpense,
  LSMessages,
  LSPin,
  LSSale,
} from 'src/components/icons';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import DetailsColumnSkeleton from './DetailsColumnSkeleton';
import { FilecasesDetail } from './FilecasesDetail';
import { DocumentsSection } from './DocumentsSection';
import { EventsDetail } from './EventsDetail';
import { SingleEntityTreasury } from './SingleEntityTreasury';
import { Comments } from './Comments';

const rawSections = [
  'filecases',
  'documents',
  'alerts',
  'tasks',
  'sales',
  'expenses',
  'comments',
];

const getSections = (sectionId) => {
  switch (sectionId) {
    case 'filecases':
      return FilecasesDetail;
    case 'documents':
      return DocumentsSection;
    case 'alerts':
    case 'tasks':
      return EventsDetail;
    case 'sales':
    case 'expenses':
      return SingleEntityTreasury;
    case 'comments':
      return Comments;
    default:
      return null;
  }
};

const useSections = (entity) => {
  const studioId = useStudioId();

  const { documents } = useDocuments({
    fetchPolicy: 'cache-only',
    variables: {
      studioId,
      entityId: entity?.id,
    },
  });

  const getTitle = useCallback(
    ({ type, title }) => {
      if (!entity) {
        return title;
      }

      const casts = {
        filecases: () => entity.filecases.length,
        documents: () => documents.length,
        alerts: () =>
          entity.events.filter((event) => event.type === 'alert').length,
        tasks: () =>
          entity.events.filter((event) => event.type === 'task').length,
        sales: () => entity.sales?.length,
        expenses: () => entity.expenses?.length,
        comments: () => entity.comments?.length,
      };

      const count = casts[type]();

      return count ? `${title} (${count})` : title;
    },
    [documents.length, entity],
  );

  const sectionData = useMemo(
    () => ({
      filecases: {
        icon: <LSBooks color="primary" />,
        title: getTitle({ type: 'filecases', title: 'Expedientes' }),
        permissions: [LS_PERMISSIONS_MAP.FILECASES.ACCESS],
      },
      documents: {
        icon: <LSDocument color="primary" />,
        title: getTitle({ type: 'documents', title: 'Documentos vinculados' }),
        permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
      },
      alerts: {
        icon: <LSCalendar color="primary" />,
        title: getTitle({ type: 'alerts', title: 'Alertas' }),
        permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
      },
      tasks: {
        icon: <LSPin color="primary" />,
        title: getTitle({ type: 'tasks', title: 'Tareas' }),
        permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
      },
      sales: {
        icon: <LSSale color="primary" />,
        title: getTitle({ type: 'sales', title: 'Honorarios' }),
        permissions: [LS_PERMISSIONS_MAP.SALES.ACCESS],
      },
      expenses: {
        icon: <LSExpense color="primary" />,
        title: getTitle({ type: 'expenses', title: 'Gastos' }),
        permissions: [LS_PERMISSIONS_MAP.EXPENSES.ACCESS],
      },
      comments: {
        icon: <LSMessages color="primary" />,
        title: getTitle({ type: 'comments', title: 'Comentarios' }),
      },
    }),
    [getTitle],
  );

  return sectionData;
};

export const DetailsColumn = () => {
  const params = useParams();
  const { loading, data: { getEntity: entity } = {} } = useQuery(
    GET_ENTITY_SINGLE_PAGE_METADATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        studio: params.studioId,
        entity: params.entity,
      },
    },
  );

  const sectionData = useSections(entity);
  const [sections, handleDragEnd] = useDraggableSections(
    rawSections,
    'v1-entity-detail',
  );

  if (loading) {
    return <DetailsColumnSkeleton />;
  }

  if (!entity) {
    return <Navigate replace to="/error404" />;
  }

  return (
    <Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Card sx={{ p: 2 }}>
          {sections.map((sectionId, index) => {
            const Section = getSections(sectionId);
            const { title, icon, permissions } = sectionData[sectionId];

            const content = (
              <Droppable
                direction="vertical"
                droppableId={sectionId}
                key={sectionId}
              >
                {(dropProvided, snapshot) => (
                  <Box
                    {...dropProvided.droppableProps}
                    direction="column"
                    ref={dropProvided.innerRef}
                    sx={{
                      ...(snapshot.isDraggingOver && {
                        backgroundColor: 'primary.100',
                      }),
                    }}
                  >
                    <DetailSection
                      draggableId={sectionId}
                      icon={icon}
                      index={index}
                      title={title}
                    >
                      <Section entity={entity} type={sectionId} />
                    </DetailSection>

                    {dropProvided.placeholder}
                  </Box>
                )}
              </Droppable>
            );

            if (!permissions) {
              return content;
            }

            return (
              <PermissionGuard key={sectionId} permissions={permissions}>
                {content}
              </PermissionGuard>
            );
          })}
        </Card>
      </DragDropContext>
    </Box>
  );
};
