import React, { memo } from 'react';
import { Button, DialogContent, DialogActions } from '@mui/material';
import { Formik } from 'formik';

import Form, {
  SubmitButton,
  TextField,
  SelectField,
  CustomFields,
} from 'src/components/ui-components/Form';
import { EntityStatusAutocomplete, TagsAutocomplete } from 'src/autocompletes';
import { entityTypes } from '@legalsurf/common';

const defaultFormValues = {
  firstname: '',
  businessname: '',
  lastname: '',
  identification: '',
  phone: '',
  email: '',
  country: '',
  type: '',
  location: '',
  city: '',
  address: '',
  departament: '',
  postalCode: '',
  tags: [],
};

const EntityDetailsForm = ({
  onSubmit,
  onCancel,
  entityId,
  initialValues = defaultFormValues,
  enableReinitialize,
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <>
        <DialogContent>
          <Form id="entityDetailsForm">
            <EntityStatusAutocomplete name="state" />

            <TextField
              label={!values.firstname ? 'Razon social' : 'Nombre'}
              name={!values.firstname ? 'businessname' : 'firstname'}
              placeholder="Ej. Jhon"
            />

            {values.firstname && <TextField label="Apellido" name="lastname" />}

            <TextField
              multiline
              label="Descripción"
              maxRows={4}
              name="description"
              placeholder="Descripción"
            />
            <SelectField
              gridProps={{ xs: 12 }}
              label="Tipo"
              name="type"
              options={entityTypes}
            />
            <TextField
              label="Identificador"
              name="identification"
              placeholder="Ej. DNI/Pasaporte/EIN/CUIT"
            />

            <TextField
              label="Email"
              name="email"
              placeholder="Ej. jon@doe.com"
            />

            <TextField
              label="Telefono"
              name="phone"
              placeholder="Ej. 123 1456 134"
            />

            <TextField
              label="Pais"
              name="country"
              placeholder="Ej. Argentina"
            />

            <TextField
              label="Estado / Provincia"
              name="location"
              placeholder="Ej. Buenos Aires"
            />

            <TextField
              label="Ciudad"
              name="city"
              placeholder="Ej. Capital Federal"
            />

            <TextField
              label="Direccion"
              name="address"
              placeholder="Ej. Capital Federal"
            />

            <TextField
              label="Piso / Departamento"
              name="departament"
              placeholder="Ej. 10C"
            />

            <TextField
              label="Codigo postal"
              name="postalCode"
              placeholder="Ej. 2407B"
            />

            <TagsAutocomplete
              creatable
              multiple
              name="tags"
              placeholder="Ej. Zona sur"
            />

            <CustomFields modelId={entityId} modelType="Entity" />
          </Form>
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={onCancel}>
            Cancelar
          </Button>

          <SubmitButton fullWidth form="entityDetailsForm" type="submit">
            Actualizar
          </SubmitButton>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default memo(EntityDetailsForm);
