import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import dayjs from 'dayjs';

import { useSales } from '@legalsurf/hooks';
import { LSDollarSign, LSStats } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { formatMoney } from 'src/utils/formatters';
import {
  useCurrencies,
  useDefaultCurrency,
} from 'src/autocompletes/CurrencyAutocomplete';
import { useConditionalEffect } from 'src/utils/hooks/useConditionalEffect';

const startOfCurrentWeek = dayjs().startOf('week').toISOString();
const currentDateOfTheWeek = dayjs().toISOString();

const SalesSection = () => {
  const studioId = useStudioId();
  const { currency: defaultCurrency } = useDefaultCurrency();
  const { currencies } = useCurrencies();
  const [initialized, setInitialized] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const { data: sales, loading } = useSales({
    variables: {
      studio: studioId,
      start: startOfCurrentWeek,
      end: currentDateOfTheWeek,
    },
  });

  useConditionalEffect(() => {
    if (!initialized && defaultCurrency?.id) {
      setSelectedCurrency(defaultCurrency.id);
      setInitialized(true);
    }
  }, [defaultCurrency?.id, initialized]);

  const groupedSales = useMemo(() => {
    if (!sales || !sales.length) return {};

    return sales.reduce((acc, sale) => {
      const currencyId = sale.currency.id;

      if (!acc[currencyId]) {
        acc[currencyId] = {
          total: 0,
          charged: 0,
          name: sale.currency.name,
          id: currencyId,
        };
      }

      acc[currencyId].total += sale.amount;
      acc[currencyId].charged += sale.charged;

      return acc;
    }, {});
  }, [sales]);

  const selectedCurrencyData = groupedSales[selectedCurrency] || {};
  const total = selectedCurrencyData.total || 0;
  const charged = selectedCurrencyData.charged || 0;
  const completionProgress = total > 0 ? (charged / total) * 100 : 0;

  if (!loading && !sales.length) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <LSStats color="primary" />

            <Typography variant="h6" fontWeight="600">
              Honorarios de esta semana
            </Typography>
          </Stack>

          {loading ? (
            <Stack alignItems="center" direction="row" justifyContent="center">
              <CircularProgress color="primary" size={20} />
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    borderRadius: 1,
                    backgroundColor: 'success.200',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LSDollarSign fontSize="medium" color="success" />
                </Box>

                <Stack>
                  <Typography variant="body2" fontWeight="500" fontSize={16}>
                    Honorarios
                  </Typography>

                  <Stack
                    key={selectedCurrency}
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Select
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                      size="small"
                    >
                      {currencies.map((currency) => (
                        <MenuItem key={currency.id} value={currency.id}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </Select>

                    <Typography fontSize={20} fontWeight="bold">
                      {formatMoney(total)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{ position: 'relative', width: 60, height: 60 }}>
                  <CircularProgress
                    size={60}
                    sx={{
                      position: 'absolute',
                      color: 'primary.200',
                    }}
                    thickness={6}
                    value={100}
                    variant="determinate"
                  />

                  <CircularProgress
                    size={60}
                    sx={{
                      position: 'absolute',
                      color: 'primary.main',
                    }}
                    thickness={6}
                    value={completionProgress}
                    variant="determinate"
                  />
                </Box>

                <Stack>
                  <Typography variant="body2" fontWeight="500" fontSize={14}>
                    % cobrado
                  </Typography>

                  <Typography fontSize={20} fontWeight="bold">
                    {completionProgress.toFixed(0)}%
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SalesSection;
