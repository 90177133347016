import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import legalsurfTeam from './legalsurf-team.png';
import { usePostHog } from 'posthog-js/react';

export const useOnboardingCheck = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    if (searchParams.get('onboarding') === 'completed') {
      setShowModal(true);
    }
  }, [searchParams]);

  const handleClose = () => {
    if (searchParams.get('onboarding') === 'completed') {
      posthog.capture('webappOnboarding', {
        step: 'completed',
      });
    }

    searchParams.delete('onboarding');
    setSearchParams(searchParams);
    setShowModal(false);
  };

  return [showModal, handleClose];
};

export const CongratsModal = () => {
  const [showModal, handleClose] = useOnboardingCheck();

  return (
    <Dialog open={showModal} onClose={handleClose} maxWidth="xs">
      <Box component="img" src={legalsurfTeam} />

      <Stack>
        <Stack sx={{ p: 4 }} gap={2}>
          <Typography variant="h3">
            !Te damos la bienvenida a LegalSurf! 🏄
          </Typography>

          <Typography variant="body1">
            Aqui podras agendar una reunion de asesoría con nosotros para
            ayudarte a implementar la plataforma
          </Typography>

          <Box>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.600' }}
              onClick={() =>
                window.open(
                  'https://calendly.com/legalsurf-ventas/welcome-desktop',
                  '_blank',
                )
              }
            >
              Agendar
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Dialog>
  );
};
