import { useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";

const validateSearchParamValue = (val) => Array.isArray(val) ? val.every(Boolean) : val !== undefined;

export const useSearchFilterValues = ({ paramConfigMap, id }) => {
  const [initialized, setInitialized] = useState(false);

  const [params, setParams] = useQueryParams(paramConfigMap, {
    updateType: 'replaceIn',
    includeAllParams: true,
    enableBatching: true,
  });

  const alreadyInitialized = !initialized && Object.keys(paramConfigMap).some(key => params[key]);

  const handleSetParams = (newParams) => {
    const isEmptyObj = Object.keys(newParams).length === 0;

    if (isEmptyObj) {
      setParams({}, 'replace');
      localStorage.removeItem(id);
      return;
    }

    const newValues = Object.entries(newParams).filter(([key, val]) => validateSearchParamValue(val) && (key in paramConfigMap))
      .reduce((acc, [key, val]) => {
        const value = Array.isArray(val) ? val.map((val) => typeof val === 'string' ? val : (val.value ?? val.id)) : (val?.value ?? val);

        return { ...acc, [key]: value }
      }, {});

    localStorage.setItem(id, JSON.stringify(newValues));
    setParams(newValues);
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem(id));

    if (alreadyInitialized) {
      setInitialized(true);
    } else if (!initialized && obj) {
      setInitialized(true);
      setParams(obj, 'replace')
    }
  }, [id, initialized, setParams, alreadyInitialized]);

  return [params, handleSetParams, { initialized }]
}