import React, { useState } from 'react';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam } from 'use-query-params';
import * as Sentry from '@sentry/browser';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import { TextField } from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen } from 'src/components/icons';
import {
  AssigneesAutocomplete,
  EntitiesAutocomplete,
  FilecasesAutocomplete,
} from 'src/autocompletes';
import { useSearchFilterValues } from 'src/components/v3/SectionHeader/utils';

export const calendarSearchFiltersId = 'CalendarSearchFilters-v1';

const paramConfigMap = {
  entity: StringParam,
  filecases: ArrayParam,
  assigned: ArrayParam,
  calendars: ArrayParam,
  text: StringParam,
  start: StringParam,
  end: StringParam,
};

export const useCalendarSearchFilterValues = () =>
  useSearchFilterValues({ paramConfigMap, id: calendarSearchFiltersId });

const Header = ({ title, subtitle, loading }) => {
  const dispatchEventFormDialog = useDialog('eventForm');
  const [params, setParams] = useCalendarSearchFilterValues();

  const initialValues = {
    entity: params.entity ?? [],
    filecases: params.filecases ?? [],
    assigned: params.assigned ?? [],
    calendars: params.calendars ?? [],
    text: params.text ?? '',
  };

  const handleFiltersSubmit = async (values) => {
    try {
      setParams(values);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          innerParams: JSON.stringify(values),
        },
      });

      // try reseting parameters
      setParams({});
    }
  };

  const [t, setT] = useState(null);

  const handleFiltersChange = (values, prev) => {
    if (values.text !== prev.text) {
      if (t) clearTimeout(t);

      setT(setTimeout(() => handleFiltersSubmit(values), 400));
      return;
    }

    handleFiltersSubmit(values);
  };

  const handleReset = () => {
    setParams({});
  };

  return (
    <SectionHeader
      enableReinitialize
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersSubmit}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack direction="column">
              <SectionHeaderHeading sx={{ mb: 0.5 }} variant="h3">
                {title}
              </SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <Box>
              <TextField
                debounce
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LSLen color="primary" />
                    </InputAdornment>
                  ),
                }}
                name="text"
                placeholder="Buscar..."
                size="small"
              />
            </Box>

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <EntitiesAutocomplete
                label="Directorio"
                name="entity"
                size="small"
              />

              <FilecasesAutocomplete
                multiple
                label="Expedientes"
                name="filecases"
                placeholder="Ej: 1234/2021"
                size="small"
              />

              <AssigneesAutocomplete
                multiple
                label="Asignados"
                name="assigned"
                placeholder="Ej: Nicolas Salazar"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{ ml: 'auto', gap: 2, display: 'flex', alignItems: 'center' }}
            >
              <Button
                color="primary"
                startIcon={<LSAddIcon color="white" />}
                variant="contained"
                onClick={() =>
                  dispatchEventFormDialog('CalendarPageEventForm', {
                    type: 'task',
                  })
                }
              >
                Crear evento
              </Button>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecase: 'Expediente',
              filecases: 'Expedientes',
              assigned: 'Asignados',
              onlyMe: 'Solo yo',
              start: 'Desde',
              end: 'Hasta',
              text: 'Texto',
            }}
            filters={params}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;
