import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';

import TitleCell from './TitleCell';
import StateCell from './StateCell';
import AssignedCell from './AssignedCell';
import ToggleCell from './ToggleCell';
import FavoriteCell from './FavoriteCell';
import InactivityCell from './InactivityCell';

export const useColumns = ({ short = false } = {}) => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.READ,
  ]);

  return useMemo(
    () => [
      ...(hasPermissions && !short
        ? [
            {
              sortable: false,
              filterable: false,
              hideable: false,
              headerName: '',
              disableColumnMenu: true,
              field: 'see',
              renderCell: (cell) => (
                <Box>
                  <FavoriteCell {...cell} />
                  <ToggleCell {...cell} />
                </Box>
              ),
            },
          ]
        : []),
      {
        hideable: false,
        filterable: false,
        headerName: 'Expediente',
        field: 'title',
        flex: 1,
        minWidth: 200,
        renderCell: TitleCell,
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        width: 150,
        headerName: 'Número',
        field: 'externalId',
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        headerName: 'Estado',
        field: 'state',
        width: 140,
        renderCell: StateCell,
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        width: 120,
        headerName: 'Asignados',
        field: 'assigned',
        renderCell: AssignedCell,
      },
      {
        hideable: false,
        headerName: 'Actuaciones',
        filterable: false,
        field: 'updatedAt',
        width: 160,
        renderCell: InactivityCell,
      },
    ],
    [hasPermissions, short],
  );
};

export const useCompactColumns = () => [
  {
    hideable: false,
    filterable: false,
    headerName: 'Expediente',
    field: 'title',
    flex: 1,
    minWidth: 200,
    renderCell: TitleCell,
  },
  {
    editable: false,
    hideable: false,
    filterable: false,
    width: 150,
    headerName: 'Número',
    field: 'externalId',
  },
  {
    editable: false,
    hideable: false,
    filterable: false,
    headerName: 'Estado',
    field: 'state',
    width: 140,
    renderCell: StateCell,
  },
  {
    editable: false,
    hideable: false,
    filterable: false,
    width: 120,
    headerName: 'Asignados',
    field: 'assigned',
    renderCell: AssignedCell,
  },
  {
    hideable: false,
    headerName: 'Actuaciones',
    filterable: false,
    field: 'updatedAt',
    width: 160,
    renderCell: InactivityCell,
  },
];
