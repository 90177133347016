import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { darken } from '@mui/material';

import { UPDATE_ENTITY } from 'src/graphql/mutations/entities';
import EntityStatusPopover from 'src/components/v3/EntityStatusPopover';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP, ROLES_MAP } from '@legalsurf/common';
import dayjs from 'dayjs';
import { Badge } from 'src/components/Badge';

const StateCell = ({ row: { state, ...row } = {} }) => {
  const { studioId } = useParams();
  const [open, setOpen] = useState(false);
  const cellRef = useRef(null);
  const { permissions, role } = usePermissions();

  const hasEditPermissions = permissions.includes(
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  );
  const isHighRole = role === ROLES_MAP.ADMIN || role === ROLES_MAP.OWNER;

  const [updateEntity] = useMutation(UPDATE_ENTITY);

  const handleTogglePop = (event) => {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    setOpen(!open);
  };

  const handleSubmitEditState = async (entity) => {
    await updateEntity({
      variables: {
        id: entity.id,
        state: entity.state,
        studio: studioId,
      },
      update: (cache, result) => {
        cache.modify({
          id: cache.identify(row),
          fields: {
            stateUpdatedAt() {
              return result.data.updateEntity.stateUpdatedAt;
            },
          },
        });
      },
    });
  };

  const title = state?.name
    ? `${state.name} - Ultima actualización: ${dayjs(row.stateUpdatedAt).format('LL')}`
    : 'Sin estado';

  return (
    <>
      <Badge
        tooltipLabel={title}
        tooltipProps={{ arrow: true, placement: 'left' }}
        ref={cellRef}
        {...((hasEditPermissions || isHighRole) && {
          onClick: handleTogglePop,
          sx: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: darken(state?.color ?? '#F8F5FF', 0.2),
            },
          },
        })}
        label={state?.name ?? '+'}
        backgroundColor={state?.color ?? '#F8F5FF'}
      />

      <EntityStatusPopover
        anchorEl={cellRef.current}
        open={(isHighRole || hasEditPermissions) && open}
        onClickStatus={(option) =>
          handleSubmitEditState({
            id: row.id,
            title: row.title,
            state: option.name,
          })
        }
        onClose={handleTogglePop}
      />
    </>
  );
};

export default StateCell;
