import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { SalesQueryFilters } from '@legalsurf/queries';

import SectionHeader from 'src/components/v2/SectionHeader';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useCurrencies } from 'src/autocompletes/CurrencyAutocomplete';

const initialExpenseQueryVars = {
  filecases: [],
  clients: [],
  responsibles: [],
  tags: [],
  currencies: [],
};

const initialValues = {
  filecases: [],
  clients: [],
  responsibles: [],
  tags: [],
  currencies: [],
};

const Header = ({
  totalItems,
  refetch,
  loading,
  selectedItems,
  setShowModalExpense,
  onDownloasdAsExcel: handleDownloadAsExcel,
  loadingExpensesToExport,
}) => {
  const studioId = useStudioId();
  const { currencies } = useCurrencies();

  const { hasPermissions: canCreateExpenses } = usePermissions([
    LS_PERMISSIONS_MAP.EXPENSES.CREATE,
  ]);

  const { data: filters = {} } = useQuery(SalesQueryFilters, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...initialExpenseQueryVars,
      studio: studioId,
    },
  });

  return (
    <Box sx={{ p: 2 }}>
      <SectionHeader
        actions={[
          {
            title: `Descargar Excel (${selectedItems?.length || 'Todos'})`,
            handleChange: handleDownloadAsExcel,
            icon: DownloadIcon,
            disabled: loadingExpensesToExport,
          },
        ]}
        createButton={
          canCreateExpenses
            ? [
                {
                  title: 'Nuevo gasto',
                  color: 'error',
                  disabled: loading,
                  handleChange: () => setShowModalExpense(true),
                  sx: {
                    mr: 2,
                  },
                },
              ]
            : null
        }
        filters={{
          initialValues,
          loading,
          onSubmit: (values) =>
            refetch({
              studio: studioId,
              search: values.search,
              responsibles:
                values.responsibles?.map((res) => res.value) || null,
              entities: values.clients?.map((client) => client.value) || null,
              filecases: values.filecases?.map((file) => file.value) || null,
              currencies:
                values.currencies?.map((currency) => currency.value) || null,
              tags: values.tags?.map((file) => file.value) || null,
            }),
          onReset: () =>
            refetch({
              ...initialExpenseQueryVars,
              studio: studioId,
            }),
          data: [
            {
              title: 'Responsables',
              name: 'responsibles',
              isMulti: true,
              options: (filters.studio?.members ?? []).map((opt) => ({
                label: opt.name,
                value: opt.id,
              })),
            },
            {
              title: 'Expedientes',
              name: 'filecases',
              isMulti: true,
              options: (filters.filecases ?? []).map((opt) => ({
                label: opt.title,
                value: opt.id,
              })),
            },
            {
              title: 'Clientes',
              name: 'clients',
              isMulti: true,
              options: (filters.entities ?? []).map((opt) => ({
                label: opt.displayName,
                value: opt.id,
              })),
            },
            {
              title: 'Etiquetas',
              name: 'tags',
              isMulti: true,
              options: (filters.tags ?? []).map((opt) => ({
                label: opt.name,
                value: opt.name,
              })),
            },
            {
              title: 'Monedas',
              name: 'currencies',
              isMulti: true,
              options: currencies.map((opt) => ({
                label: opt.name,
                value: opt.id,
              })),
            },
          ],
        }}
        subtitle={`${totalItems} totales`}
        title="Gastos"
      />
    </Box>
  );
};

export default Header;
