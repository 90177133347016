import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from './usePermissions';
import { useUser } from './useUser';

import { useStudioId } from './useStudioId';
import { useGoogleCalendar } from './useGoogleCalendar';

export const QuestQuery = gql`
  query QuestQuery($studioId: ID!) {
    paginatedFilecases(limit: 1, studio: $studioId) {
      count
      data {
        id
      }
    }

    paginatedEntities(limit: 1, studio: $studioId) {
      count
      data {
        id
      }
    }

    members(studio: $studioId) {
      id
    }

    sales(studio: $studioId) {
      id
    }
  }
`;

const getCompletionPercentage = (questSteps) => {
  const totalQuests = questSteps.length;
  const totalCompletedQuests = questSteps.filter((val) => val.completed).length;

  return Math.floor((totalCompletedQuests / totalQuests) * 100);
};

export const useQuests = (options, { handleMembersInvitationsClick }) => {
  const navigate = useNavigate();
  const dispatchEntityForm = useDialog('entityForm');
  const dispatchFilecaseForm = useDialog('filecaseForm');
  const dispatchSaleForm = useDialog('salesForm');
  const { hasPermissions: directoryAccess } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.ACCESS,
  ]);
  const { hasPermissions: filecaseAccess } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.ACCESS,
  ]);
  const { hasPermissions: calendarAccess } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
  ]);
  const { hasPermissions: salesAccess } = usePermissions([
    LS_PERMISSIONS_MAP.SALES.ACCESS,
  ]);
  const { hasPermissions: canInvitateUsers } = usePermissions([
    LS_PERMISSIONS_MAP.CONFIG.ACCESS,
  ]);

  const studioId = useStudioId();
  const { user } = useUser();

  const {
    loading: loadingGoogleCalendar,
    hasGoogleCalendar,
    refetch: refetchGoogleCalendar,
  } = useGoogleCalendar();

  const { data, loading, refetch } = useQuery(QuestQuery, {
    ...options,
    variables: {
      studioId,
    },
  });

  const params = useMemo(
    () => ({
      refetch: () => {
        // Warning, infinite loop incoming
        refetch();
        refetchGoogleCalendar();
      },
      loading: loading || loadingGoogleCalendar,
      googleCalendarDone: hasGoogleCalendar,
      filecasesDone: data?.paginatedFilecases?.count > 0,
      directoryDone: data?.paginatedEntities?.count > 0,
      membersDone: data?.members?.length > 1,
      salesDone: data?.sales?.length > 0,
      isMembersQuestAvailable: user?.role === 'owner',
      completionPercentage: 0,
      steps: [],
    }),
    [
      data,
      hasGoogleCalendar,
      loading,
      loadingGoogleCalendar,
      refetch,
      refetchGoogleCalendar,
      user?.role,
    ],
  );

  const steps = useMemo(() => {
    const arr = [
      {
        title: 'Crea tu Firma de Abogados Online con LegalSurf',
        completed: true,
      },
    ];

    if (calendarAccess) {
      arr.push({
        title: 'Sincroniza tus calendarios de Google Calendar',
        completed: params.googleCalendarDone,
        // @ts-ignore
        onClick: () => {
          navigate(`/dashboard/${studioId}/settings/googlecalendar`);
        },
      });
    }

    if (directoryAccess) {
      arr.push({
        title: 'Crea tu primer cliente',
        completed: params.directoryDone,
        // @ts-ignore
        onClick: () => {
          dispatchEntityForm('HomeQuestsEntityForm');
        },
      });
    }

    if (filecaseAccess) {
      arr.push({
        title: 'Digitaliza tu primer expediente',
        completed: params.filecasesDone,
        // @ts-ignore
        onClick: () => {
          dispatchFilecaseForm('HomeQuestsFilecaseForm');
        },
      });
    }

    if (salesAccess) {
      arr.push({
        title: 'Carga tu primer Honorario',
        completed: params.salesDone,
        // @ts-ignore
        onClick: () => {
          dispatchSaleForm('HomeQuestsSaleForm');
        },
      });
    }

    if (canInvitateUsers) {
      arr.push({
        title: 'Invita a tu equipo de trabajo',
        completed: params.membersDone,
        onClick: handleMembersInvitationsClick,
      });
    }

    return arr;
  }, [
    calendarAccess,
    directoryAccess,
    filecaseAccess,
    salesAccess,
    canInvitateUsers,
    params.googleCalendarDone,
    params.directoryDone,
    params.filecasesDone,
    params.salesDone,
    params.membersDone,
    navigate,
    studioId,
    dispatchEntityForm,
    dispatchFilecaseForm,
    dispatchSaleForm,
    handleMembersInvitationsClick,
  ]);

  params.completionPercentage = getCompletionPercentage(steps);
  params.steps = steps;

  return params;
};
