import React, { useState } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useDialog } from 'src/dialogs/Dialogs';
import { LSAdd, LSEdit, LSTrash } from 'src/components/icons';
import { WhatsApp } from '@mui/icons-material';
import { sendWhatsappMsg } from 'src/utils/notificationTemplates';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import { useMutation } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import { DELETE_SALE, SalesQuery } from '../SalesQueries';

export const defaultEmptyEntity = {
  id: 1,
  displayName: '',
  state: {
    name: '',
  },
};

export const SaleDetailsHeaderActions = ({
  sale,
  filecase,
  disabled,
  onClose,
}) => {
  const studioId = useStudioId();
  const dispatchSalesFormDialog = useDialog('salesForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchChargeFormDialog = useDialog('chargeForm');
  const { openSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (innerEvent) => {
    setAnchorEl(innerEvent?.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const [deleteSale] = useMutation(DELETE_SALE, {
    refetchQueries: [
      SalesQuery,
      {
        query: GET_ENTITY_SINGLE_PAGE_METADATA,
        variables: {
          entity: sale?.entity?.id,
        },
      },
    ],
  });

  const handleDeleteSale = async () => {
    try {
      await deleteSale({
        variables: {
          id: sale.id,
          studioId,
        },
      });

      onClose?.();

      openSnackbar('Honorario eliminado con exito.', {
        severity: 'success',
      });
    } catch (error) {
      openSnackbar(error.message, { severity: 'error', autoDismiss: 200 });
    }
  };

  return (
    <>
      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ sale, filecase }}
        entity={defaultEmptyEntity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg('', msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />

      <Stack direction="row" gap={1} justifyContent="flex-end">
        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.UPDATE]}>
          <Tooltip title="Plantillas de Whatsapp">
            <IconButton variant="text" onClick={handleOpenFilecasesPopover}>
              <WhatsApp
                sx={{
                  color: '#24d366',
                }}
              />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.UPDATE]}>
          <Tooltip title="Editar honorario">
            <IconButton
              color="primary"
              disabled={disabled}
              sx={{
                '&:hover': {
                  color: 'info.main',
                },
              }}
              onClick={() =>
                dispatchSalesFormDialog('SaleDetailsEditDialog', {
                  isEdit: true,
                  id: sale?.id,
                  transaction: sale,
                })
              }
            >
              <LSEdit fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.UPDATE]}>
          <Tooltip title="Cargar cobro">
            <IconButton
              color="primary"
              disabled={disabled || sale.charged === sale.amount}
              sx={{
                '&:hover': {
                  color: 'info.main',
                },
              }}
              onClick={() =>
                dispatchChargeFormDialog('ChargeDetailsForm Dialog', {
                  sale,
                })
              }
            >
              <LSAdd fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.DELETE]}>
          <Tooltip title="Eliminar honorario">
            <IconButton
              color="primary"
              disabled={disabled}
              sx={{
                '&:hover': {
                  color: 'error.main',
                },
              }}
              onClick={() =>
                dispatchWarningPromptDialog(`SaleDetailsDialog-${sale.id}`, {
                  onAccept: handleDeleteSale,
                })
              }
            >
              <LSTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        {/* <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SaleDetailsHeaderActions.CREATE]}>
          <Tooltip title="Duplicar evento">
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={() =>
                dispatchSaleForm(sale.id, {
                  id: sale.id,
                  calendarId,
                  clone: true,
                })
              }
            >
              <LSCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard> */}
      </Stack>
    </>
  );
};
