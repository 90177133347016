import React from 'react';
import {
  Avatar,
  Chip,
  Box,
  Button,
  Typography,
  CircularProgress,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  ButtonGroup,
} from '@mui/material';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useMutation, useQuery } from '@apollo/client';
import { LSAdd as LSAddIcon, LSMail, LSX, LSEdit } from 'src/components/icons';

import { toDateFormat } from 'src/utils/dates';
import { stringAvatar } from 'src/utils/formatters';
import {
  entityTypesObj,
  DEFAULT_PAYMENT_METHODS,
  LS_PERMISSIONS_MAP,
} from '@legalsurf/common';
import PermissionGuard from 'src/components/v2/PermissionGuard';

import { useDialog } from 'src/dialogs/Dialogs';
import {
  DeletePaymentMutation,
  DeleteExpenseMutation,
  GetExpenseDetails,
} from '../ExpensesQueries';

export const ExpenseDetailsDialogContent = ({ expenseId, onClose }) => {
  const studioId = useStudioId();

  const { data: { expense } = {}, loading } = useQuery(GetExpenseDetails, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: expenseId,
    },
  });
  const dispatchExpensesPaymentForm = useDialog('expensesPaymentForm');
  const dispatchExpensesForm = useDialog('expenseForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deletePayment] = useMutation(DeletePaymentMutation, {
    refetchQueries: [GetExpenseDetails],
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deletePayment));
      cache.gc();
    },
  });

  const [deleteExpense] = useMutation(DeleteExpenseMutation, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteExpense));
      cache.gc();
    },
  });

  const handleExpenseDelete = async () => {
    await deleteExpense({
      variables: {
        id: expense.id,
      },
    });

    onClose();
  };

  if (loading || !expense) {
    return (
      <DialogContent>
        <Box
          sx={{
            minHeight: 150,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" size={26} />
        </Box>
      </DialogContent>
    );
  }

  return (
    <>
      <DialogContent
        dividers
        sx={{
          '& > * + *': {
            mt: 3,
          },
        }}
      >
        <Box>
          <Box
            sx={{
              m: 2,
              mr: 2,
              '& > * + * ': {
                mt: 2,
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Chip
                      color="error"
                      label="Gasto"
                      size="small"
                      variant="light"
                    />

                    <Typography sx={{ ml: 2, color: 'grey.400' }}>
                      {toDateFormat(expense?.date, 'DD/MM/YYYY')}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    {expense?.tags.map((tag) => (
                      <Chip
                        key={tag.name}
                        label={tag.name}
                        size="small"
                        sx={{
                          color: (theme) => theme.palette.primary.dark,
                          backgroundColor: (theme) =>
                            theme.palette.primary[100],
                        }}
                      />
                    ))}
                  </Box>

                  <Typography color="error" sx={{ my: 3 }} variant="h2">
                    {expense.currency?.name} {expense.amount}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress
                    size={60}
                    sx={{
                      mr: 2,
                      color: 'grey.100',
                    }}
                    thickness={7}
                    value={100}
                    variant="determinate"
                  />
                  <CircularProgress
                    color="error"
                    size={60}
                    sx={{
                      color: 'error',
                      position: 'absolute',
                      left: 0,
                    }}
                    thickness={7}
                    value={(expense.paid * 100) / expense.amount}
                    variant="determinate"
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Total pagado</Typography>
                  <Typography color="success" variant="h4">
                    {expense.currency?.name} {expense.paid}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Typography variant="h4">{expense.concept}</Typography>
          </Box>
        </Box>

        {expense.entity && (
          <Box
            sx={{
              m: 2,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '& > * + *': {
                ml: 2,
              },
            }}
            onClick={() =>
              window.open(
                `/dashboard/${studioId}/directories/${expense.entity.id}`,
                '_blank',
              )
            }
          >
            <Avatar
              {...stringAvatar(expense.entity.displayName, {
                width: 25,
                height: 25,
                fontSize: 12,
              })}
            />

            <Chip
              color="primary"
              label={entityTypesObj[expense.entity.type]}
              size="small"
              variant="light"
            />

            <Typography fontWeight={300}>
              {expense.entity.displayName}
            </Typography>
          </Box>
        )}

        {!!expense.filecase && (
          <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
            <LSMail color="error" />

            <Typography sx={{ ml: 2, fontWeight: 400 }} variant="h5">
              {expense.filecase.title}
            </Typography>
          </Box>
        )}

        {!!expense.responsible && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 2, fontWeight: 400, m: 2 }} variant="h6">
              Responsable:
            </Typography>
            <Avatar
              {...stringAvatar(expense.responsible?.name, {
                width: 25,
                height: 25,
                fontSize: 12,
              })}
            />
            <Typography fontWeight={300} ml={2}>
              {expense.responsible?.name}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              maxHeight: 180,
              overflowY: 'auto',
              '& > * + *': {
                marginTop: 4,
              },
            }}
          >
            {expense.payments?.map((payment) => (
              <Box
                key={payment.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Typography fontWeight={400}>
                  {toDateFormat(payment.date)}
                </Typography>

                <Chip color="success" label="Pago" value="pago" />

                <Typography sx={{ width: 150, fontWeight: 400 }} variant="h6">
                  {expense.currency?.name} {payment.amount}
                </Typography>

                <Typography
                  noWrap
                  sx={{ width: 150, flex: 1, fontWeight: 400 }}
                  variant="h6"
                >
                  {payment.concept}
                </Typography>

                <Chip
                  color="success"
                  label={
                    DEFAULT_PAYMENT_METHODS.find(
                      (method) => method.value === payment.method,
                    ).label
                  }
                  sx={{ mr: 2 }}
                  value={
                    DEFAULT_PAYMENT_METHODS.find(
                      (method) => method.value === payment.method,
                    ).label
                  }
                  variant="light"
                />

                <ButtonGroup>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.EXPENSES.DELETE]}
                  >
                    {/* <Tooltip title="Eliminar gasto"> */}
                    <IconButton
                      // disabled={isDeletingPayment}
                      onClick={() =>
                        dispatchWarningPromptDialog(
                          `ExpenseDetailsDialog-${payment.id}`,
                          {
                            onAccept: () =>
                              deletePayment({
                                variables: {
                                  id: payment.id,
                                },
                              }),
                            title: 'Eliminar gasto',
                          },
                        )
                      }
                    >
                      <LSX />
                    </IconButton>
                    {/* </Tooltip> */}
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.EXPENSES.UPDATE]}
                  >
                    <Tooltip title="Editar pago">
                      <IconButton
                        // disabled={isDeletingPayment}
                        onClick={() =>
                          dispatchExpensesPaymentForm(payment.id, {
                            title: 'Editar pago',
                            expense,
                            payment,
                          })
                        }
                      >
                        <LSEdit />
                      </IconButton>
                    </Tooltip>
                  </PermissionGuard>
                </ButtonGroup>
              </Box>
            ))}
          </Box>

          <PermissionGuard
            permissions={[
              LS_PERMISSIONS_MAP.EXPENSES.CREATE,
              LS_PERMISSIONS_MAP.EXPENSES.UPDATE,
              LS_PERMISSIONS_MAP.EXPENSES.DELETE,
            ]}
          >
            <ButtonGroup
              sx={{
                ml: 'auto',
              }}
            >
              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.EXPENSES.DELETE]}
              >
                <Button
                  color="error"
                  disabled={loading}
                  variant="contained"
                  onClick={() =>
                    dispatchWarningPromptDialog(
                      `ExpenseDetailsDialog-${expense.id}`,
                      {
                        onAccept: handleExpenseDelete,
                        title: 'Eliminar gasto',
                      },
                    )
                  }
                >
                  Eliminar gasto
                </Button>
              </PermissionGuard>

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.EXPENSES.UPDATE]}
              >
                <Button
                  color="warning"
                  disabled={loading}
                  sx={{ alignSelf: 'end', marginLeft: 1 }}
                  variant="contained"
                  onClick={() => dispatchExpensesForm(expense.id, { expense })}
                >
                  Editar gasto
                </Button>
              </PermissionGuard>
              {/* <Button
                    color="success"
                    disabled={loading}
                    sx={{ alignSelf: 'end', marginLeft: 1 }}
                    variant="contained"
                    onClick={() =>
                      window.open(
                        `/dashboard/${params.studioId}/expense/${expense.id}/voucher`,
                        '_blank',
                      )
                    }
                  >
                    Descargar comprobante
                  </Button> */}

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.EXPENSES.CREATE]}
              >
                <Button
                  color="success"
                  disabled={loading || expense?.paid >= expense?.amount}
                  startIcon={<LSAddIcon />}
                  sx={{ alignSelf: 'end', marginLeft: 1 }}
                  variant="contained"
                  onClick={() =>
                    dispatchExpensesPaymentForm(expense.id, { expense })
                  }
                >
                  Cargar pago
                </Button>
              </PermissionGuard>
            </ButtonGroup>
          </PermissionGuard>
        </Box>
      </DialogActions>
    </>
  );
};
