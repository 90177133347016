import React, { useRef } from 'react';
import {
  Avatar,
  Box,
  Typography,
  useTheme,
  CircularProgress,
  CardContent,
  Card,
  Button,
  CardActions,
} from '@mui/material';

import Page from 'src/components/v2/Page';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toDateFormat } from 'src/utils/dates';
import { GET_SALE } from '../SalesQueries';
import cardVouche from './cardVouche.png';
import { formatMoney } from 'src/utils/formatters';

export const VoucherSale = () => {
  const { saleId } = useParams();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data, loading } = useQuery(GET_SALE, {
    variables: {
      id: saleId,
    },
  });
  const theme = useTheme();

  if (loading) return <CircularProgress />;

  return (
    <Page sx={{ backgroundColor: '#f4faff', minHeight: '100vh' }}>
      <Card
        sx={{
          margin: 'auto',
          maxWidth: 700,
          border: '1px solid #e2e7ec',
          boxShadow: 'none',
          borderRadius: '0',
        }}
      >
        <CardContent ref={componentRef} sx={{ display: 'flex' }}>
          <Box
            sx={{
              margin: 4,
              marginLeft: 7,
              width: '100%',
              display: 'flex',
              gap: 1.1,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'contents' }}>
              <Avatar
                alt={null}
                src={data.sale.studio.picture}
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 80,
                }}
              />
              <Typography sx={{ fontWeight: 500, fontSize: 24 }} variant="span">
                {data.sale.studio.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.grey[500],
                  fontSize: 14,
                }}
                variant="span"
              >
                Fecha: {toDateFormat(data.sale.date)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.grey[500],
                  fontSize: 14,
                }}
                variant="span"
              >
                Cliente: {data.sale.entity.displayName}
              </Typography>
              <Typography
                sx={{
                  backgroundColor: 'aquamarine',
                  padding: 0.9,
                  borderRadius: '0.3rem',
                  width: 'fit-content',
                  color: '#358489',
                  fontWeight: 500,
                  fontSize: 12,
                }}
                variant="span"
              >
                Comprobante de honorarios
              </Typography>

              <Typography
                sx={{ fontWeight: 500, color: theme.palette.grey[400] }}
                variant="h5"
              >
                {data.sale.concept}
              </Typography>
              <Typography
                sx={{
                  fontSize: 36,
                  fontWeight: 600,
                  color: '#1A1A1A',
                }}
                variant="span"
              >
                {data.sale.currency?.name} {formatMoney(data.sale.amount)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ margin: 'auto' }}>
            <Avatar
              alt={null}
              src={cardVouche}
              style={{
                width: 94,
                height: 71,
                borderRadius: 80,
              }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            sx={{
              marginLeft: 'auto',
            }}
            variant="filled"
            onClick={handlePrint}
          >
            ⬇ Descargar Comprobante
          </Button>
        </CardActions>
      </Card>
    </Page>
  );
};
