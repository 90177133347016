import React from 'react';
import {
  Grid,
  FormControl,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const TextField = React.forwardRef(
  (
    {
      label,
      gridProps = { xs: 12 },
      name,
      disabled,
      hint,
      noFormControlLabel,
      ...rest
    },
    ref,
  ) => {
    const [field, meta] = useField(name);
    const { isSubmitting } = useFormikContext();

    return (
      <Grid item {...gridProps}>
        <FormControl sx={{ width: '100%' }}>
          {label && !noFormControlLabel && (
            <Typography sx={{ mb: 1 }} variant="label">
              {label}
            </Typography>
          )}

          <MuiTextField
            fullWidth
            autoComplete="off"
            disabled={isSubmitting || disabled}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
            inputRef={ref}
            label={noFormControlLabel && label}
            variant="outlined"
            {...field}
            {...rest}
          />
        </FormControl>
      </Grid>
    );
  },
);

export default TextField;
