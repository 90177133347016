import React from 'react';

import { DateCell } from './DateCell';
import { PaidCell } from './PaidCell';
import { ResponsibleCell } from './ResponsibleCell';
import { AmountCell } from './AmountCell';
import { EntityCell } from './EntityCell';
import { ConceptCell } from './ConceptCell';

const baseColumns = [
  {
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: 'Concepto',
    field: 'concept',
    renderCell: (cell) => <ConceptCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Proveedor',
    renderCell: (cell) => <EntityCell {...cell} />,
    width: 240,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    width: 120,
    headerName: 'Cantidad',
    field: 'amount',
    renderCell: (cell) => <AmountCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Pagado',
    field: 'charged',
    width: 160,
    height: 100,
    renderCell: (cell) => <PaidCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Responsable',
    field: 'responsible',
    width: 120,
    renderCell: (cell) => <ResponsibleCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Creación',
    field: 'date',
    width: 140,
    renderCell: (cell) => <DateCell {...cell} />,
  },
];

export const getColumns = () => baseColumns;

export default baseColumns;
