import React from 'react';

import {
  Grid,
  Chip,
  Autocomplete as MuiAutocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
  Paper,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Avatar,
} from '@mui/material';
import { LSX as LSXIcon } from 'src/components/icons';

export const defaultAutocompleteRenderTags = (tagValue, getTagProps) =>
  tagValue.map((option, index) => {
    const tagProps = getTagProps({ index });

    return (
      <Chip
        {...tagProps}
        avatar={
          option?.picture ? (
            <Avatar alt={option.label} src={option.picture} />
          ) : null
        }
        disabled={option?.disabled || tagProps?.disabled}
        key={option?.id ?? index}
        label={option.name ?? option.label ?? option.value}
        size="small"
        variant="outlined"
        onDelete={!option.disabled ? tagProps.onDelete : undefined}
      />
    );
  });

const creatableFilter = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  stringify: (option) => {
    if (option?.search) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return option?.label + option?.search;
    }

    return option.label;
  },
});

const AutocompleteField = ({
  options,
  loading,
  label,
  placeholder,
  gridProps = { xs: 12 },
  helperText,
  error,
  creatable = false,
  freeSolo = false,
  disableCloseOnSelect = false,
  filterOptions,
  multiple,
  renderTags,
  getFilterOptionsWithCreatableLabel,
  value,
  inputProps = {},
  onChange,
  withSelectAll,
  ...rest
}) => {
  const filterOptionsWithCreatable = (unfilteredOptions, params) => {
    const filtered = creatableFilter(unfilteredOptions, params);

    if (params.inputValue !== '') {
      filtered.push({
        value: params.inputValue,
        label: getFilterOptionsWithCreatableLabel
          ? getFilterOptionsWithCreatableLabel(params.inputValue)
          : `+ Agregar "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Grid item {...gridProps}>
      <MuiAutocomplete
        clearIcon={<LSXIcon />}
        disableCloseOnSelect={multiple || disableCloseOnSelect}
        filterOptions={
          creatable
            ? filterOptionsWithCreatable
            : filterOptions ?? creatableFilter
        }
        freeSolo={creatable || freeSolo}
        isOptionEqualToValue={(option, val) =>
          option?.value === (val?.value ?? val)
        }
        loading={loading}
        multiple={multiple}
        noOptionsText="No encontramos opciones."
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={12} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={error}
            helperText={helperText}
            label={label}
            placeholder={value ? '' : placeholder}
          />
        )}
        renderTags={renderTags ?? defaultAutocompleteRenderTags}
        sx={{ width: '100%' }}
        value={value}
        PaperComponent={
          withSelectAll
            ? (paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                const checked = value?.length === options?.length;

                const handleToggleSelectAll = (e) => {
                  if (!checked) {
                    onChange(e, options);
                  } else {
                    onChange(e, []);
                  }
                };

                return (
                  <Paper {...restPaperProps}>
                    <MenuItem
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(event) => {
                        event.preventDefault();
                        handleToggleSelectAll(event);
                      }}
                      sx={{
                        pl: 3.5,
                      }}
                    >
                      <FormControlLabel
                        label="Seleccionar todos"
                        control={
                          <Checkbox
                            size="small"
                            checked={checked}
                            onChange={(event) => handleToggleSelectAll(event)}
                          />
                        }
                      />
                    </MenuItem>

                    {children}
                  </Paper>
                );
              }
            : Paper
        }
        onChange={onChange}
        {...rest}
      />
    </Grid>
  );
};

export default AutocompleteField;
