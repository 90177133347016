import React from 'react';
import { Box, Collapse, Divider } from '@mui/material';

const CollapsablePanel = ({ trigger, children, open, setOpen }) => (
  <Box>
    <Box
      sx={{ p: 1, cursor: 'pointer' }}
      onClick={() => setOpen((old) => !old)}
    >
      {trigger}
    </Box>
    <Collapse in={open}>
      <Divider />
      <Box sx={{ m: 2 }}>{children}</Box>
    </Collapse>
  </Box>
);
export default CollapsablePanel;
