import React, { useEffect, useRef } from 'react';

import { Grid } from '@mui/material';
import { Form as FormikForm, useFormikContext } from 'formik';

import TextField from './TextField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import SubmitButton from './SubmitButton';
import SearchField from './SearchField';
import SelectField from './SelectField';
import ImageField from './ImageField';
import AutocompleteField, {
  defaultAutocompleteRenderTags,
} from './AutocompleteField';
import CheckField from './CheckField';
import SwitchField from './SwitchField';
import CommentField from './CommentField';
import PhoneField from './PhoneField';
import RichEditorField from './RichEditorField';
import CustomFields, { parseCustomFieldsToInitialValues } from './CustomFields';

const FormikListener = ({ values, callback }) => {
  useEffect(() => {
    callback(values);
  }, [callback, values]);

  return null;
};

const Form = ({ gridProps, children, spacing = 3, onChange, sx, ...rest }) => {
  const ref = useRef(null);
  const { submitForm, values } = useFormikContext();

  const handleKeyDown = async (event) => {
    const isEnterKey = event.charCode || event.keyCode === 13;

    if (isEnterKey && !event.ctrlKey) {
      // TODO: hay un problema con el salto de linea y el submit de los modals.
      // event.preventDefault();
    }

    if (isEnterKey && event.ctrlKey && ref.current) {
      await submitForm();
    }
  };

  return (
    <FormikForm
      {...rest}
      encType="multipart/form-data"
      ref={ref}
      onKeyDown={handleKeyDown}
    >
      <Grid
        container
        spacing={spacing}
        sx={{
          ...sx,
        }}
        {...gridProps}
      >

        {onChange && <FormikListener callback={onChange} values={values} />}

        {children}
      </Grid>
    </FormikForm>
  );
};

export {
  SelectField,
  TextField,
  SubmitButton,
  SearchField,
  DateField,
  DateTimeField,
  AutocompleteField,
  CheckField,
  SwitchField,
  CommentField,
  defaultAutocompleteRenderTags,
  ImageField,
  PhoneField,
  RichEditorField,
  CustomFields,
  parseCustomFieldsToInitialValues,
};

export default Form;
