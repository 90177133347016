import React, { useCallback, useMemo } from 'react';
import { Box, Button, Card, CardContent, Stack } from '@mui/material';

import EventsTable from '../EventsTable';
import { useTaskBoardSearchFilterValues } from './Header';

export const Table = ({ events, refetch, loading }) => {
  const [filtersParams, setParams] = useTaskBoardSearchFilterValues();
  const { state = 'pending' } = filtersParams;

  const handleStateButtonClick = useCallback(
    (newState) => {
      setParams({ ...filtersParams, state: newState });

      return refetch({
        state: [newState],
      });
    },
    [filtersParams, refetch, setParams],
  );

  const buttonsArray = useMemo(
    () => [
      {
        key: 5,
        title: 'Pendientes',
        onClick: () => handleStateButtonClick('pending'),
        selected: state === 'pending',
        color: 'warning',
      },
      {
        key: 3,
        title: 'En progreso',
        onClick: () => handleStateButtonClick('wip'),
        selected: state === 'wip',
        color: 'error',
      },
      {
        key: 1,
        title: 'Realizados',
        onClick: () => handleStateButtonClick('done'),
        color: 'success',
        selected: state === 'done',
      },
      {
        key: 2,
        title: 'En Revision',
        onClick: () => handleStateButtonClick('reviewing'),
        selected: state === 'reviewing',
        color: 'info',
      },
      {
        key: 4,
        title: 'Archivados',
        onClick: () => handleStateButtonClick('finished'),
        selected: state === 'finished',
        color: 'background',
      },
    ],
    [handleStateButtonClick, state],
  );

  return (
    <Card>
      <CardContent>
        <Stack direction="column" gap={3}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {buttonsArray.map(
              ({
                key,
                selected,
                onClick,
                title: buttonTitle,
                ...buttonProps
              }) => (
                <Button
                  fullWidth
                  variant={selected ? 'contained' : 'outlined'}
                  onClick={onClick}
                  {...buttonProps}
                >
                  {buttonTitle}
                </Button>
              ),
            )}
          </Box>

          <EventsTable loading={loading} rows={events} />
        </Stack>
      </CardContent>
    </Card>
  );
};
