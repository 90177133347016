import { useCalendarEvents } from '@legalsurf/hooks';
import dayjs from 'dayjs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useCalendarSearchFilterValues } from './Header';

export const generateDateRange = (date) => {
  const start = dayjs(date).locale('es').startOf('week');

  // Seven days of the week
  const dates = new Array(8).fill(0).map((_, idx) => start.add(idx, 'day'));

  return [start.subtract(1, 'day'), ...dates];
};

const weeklyLeftStyles = {
  borderRadius: 0,
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
  borderTop: '2px solid',
  borderLeft: '2px solid',
  borderBottom: '2px solid',
  borderColor: 'primary.main',
};

const weeklyRightStyles = {
  borderRadius: 0,
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
  borderTop: '2px solid',
  borderRight: '2px solid',
  borderBottom: '2px solid',
  borderColor: 'primary.main',
};

const weeklyMiddleStyles = {
  borderRadius: 0,
  borderTop: '2px solid',
  borderBottom: '2px solid',
  borderColor: 'primary.main',
};

export const getWeeklyStyles = (date) => {
  if (date.day() === 1) {
    return weeklyLeftStyles;
  }

  if (date.day() === 0) {
    return weeklyRightStyles;
  }

  return weeklyMiddleStyles;
};

export const useCalendarTableEvents = (selectedDate, selectedCalendars, variables = {}, options = {}) => {
  const studioId = useStudioId();
  const [params] = useCalendarSearchFilterValues();

  return useCalendarEvents(
    {
      studio: studioId,
      start: selectedDate.startOf('month').subtract(2, 'weeks'),
      end: selectedDate.endOf('month').add(2, 'weeks'),
      calendars: selectedCalendars,

      assigned: params.assigned,
      filecases: params.filecases,
      text: params.text,

      ...variables,
    },
    options
  )
}