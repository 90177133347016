import { gql, useLazyQuery } from '@apollo/client';
import ExcelJS from 'exceljs';
import { useUser } from 'src/utils/hooks/useUser';
import ContrastColor from 'contrast-color';
import dayjs from 'dayjs';
import { formatCustomFieldValue } from 'src/components/ui-components/Form/CustomField';

const FilecasesToExportQuery = gql`
  query FilecasesToExportQuery(
    $search: String
    $category: String
    $state: String
    $entities: [String!]
    $assigned: [ID!]
    $tags: [String!]
    $limit: Int
    $offset: Int
    $studio: ID!
    $onlyFavorites: Boolean
    $orderBy: PaginatedFilecasesSortBy
    $sortOrder: SortOrder
    $ids: [ID!]
  ) {
    filecases(
      search: $search
      category: $category
      state: $state
      entities: $entities
      assigned: $assigned
      tags: $tags
      limit: $limit
      offset: $offset
      studio: $studio
      onlyFavorites: $onlyFavorites
      orderBy: $orderBy
      sortOrder: $sortOrder
      ids: $ids
    ) {
      id
      title
      externalId
      description
      createdAt
      category {
        id
        name
      }
      state {
        id
        color
        name
      }
      side {
        id
        name
      }
      tags {
        id
        name
        studioId
      }
      createdBy {
        id
        name
        email
      }
      assigned {
        id
        picture
        name
      }

      customFields {
        id
        name
        studioId
        type
        value(modelType: Filecase)
      }
    }
  }
`;

const excelHeaders = [
  'Código identificador',
  'Título',
  'Descripción',
  'Estado',
  'Categoría',
  'Parte',
  'Asignado a',
  'Etiquetas',
  'Creador por',
  'Fecha de creación',
];

const filecaseKeyPerCell = [
  'externalId',
  'title',
  'description',
  'state',
  'category',
  'side',
  'assigned',
  'tags',
  'createdBy',
  'createdAt',
];

export const useFilecasesToExport = () => {
  const [getFilecasesToExport, { loading }] = useLazyQuery(
    FilecasesToExportQuery,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { user } = useUser();

  return {
    loading,
    getFilecasesToExport: (variables) =>
      getFilecasesToExport({ variables }).then((res) => {
        const { filecases = [] } = res.data ?? {};

        const workbook = new ExcelJS.Workbook();

        workbook.creator = user.name;
        workbook.created = new Date();

        const worksheet = workbook.addWorksheet('Expedientes');

        // Set column headers strings
        excelHeaders.forEach((header, index) => {
          worksheet.getRow(1).getCell(index + 1).value = header;
        });

        if (filecases?.[0]?.customFields?.length) {
          filecases[0].customFields.forEach((customField, index) => {
            worksheet.getRow(1).getCell(excelHeaders.length + index + 1).value = customField.name;
          })
        }

        // Set filecase rows data
        filecases.forEach((filecase, index) => {
          // Plus 2 because = exceljs starts at 1 and columns headers row
          const filecaseRow = worksheet.getRow(2 + index);

          filecaseKeyPerCell.forEach((key, cellKey) => {
            const filecaseCellKey = 1 + cellKey;
            if (!filecase[key] || filecase[key]?.length === 0) {
              return;
            }

            switch (key) {
              case 'state':
                filecaseRow.getCell(filecaseCellKey).value = filecase[key].name;
                filecaseRow.getCell(filecaseCellKey).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {
                    argb: filecase[key].color.split('#')[1],
                  },
                };
                filecaseRow.getCell(filecaseCellKey).font = {
                  color: {
                    argb: ContrastColor.contrastColor({
                      bgColor: filecase[key].color,
                    }).split('#')[1],
                  },
                };

                break;

              case 'category':
              case 'side':
              case 'createdBy':
                filecaseRow.getCell(filecaseCellKey).value = filecase[key].name;
                break;

              case 'createdAt':
                filecaseRow.getCell(filecaseCellKey).value = dayjs(
                  filecase[key],
                ).format('LLL');
                break;

              case 'tags':
              case 'assigned':
                filecaseRow.getCell(filecaseCellKey).value = filecase[key]
                  .map((val) => val.name)
                  .join(', ');
                break;

              default:
                filecaseRow.getCell(filecaseCellKey).value = filecase[key];
            }
          });

          if (filecase.customFields?.length) {
            filecase.customFields.forEach((customField, customFieldIndex) => {
              filecaseRow.getCell(1 + filecaseKeyPerCell.length + customFieldIndex).value = formatCustomFieldValue(customField);
            });
          }
        });

        // Downloads the xlsx into the browser
        worksheet.workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'expedientes.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      }),
  };
};
