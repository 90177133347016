import React, { useState } from 'react';
import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Avatar,
  useTheme,
  Tooltip,
  Grid,
  Divider,
  Stack,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  AvatarGroup,
  darken,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  Add,
  PictureAsPdf,
  Save,
  Star,
  StarBorderOutlined as StarBorderOutlinedIcon,
  VisibilityOutlined,
  WhatsApp,
} from '@mui/icons-material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { palette } from 'src/config/mui-theme/foundations';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  LSMail as LSMailIcon,
  LSEdit as LSEditIcon,
  LSList as LSListIcon,
  LSX as LSXIcon,
  LSExternal as LSExternalIcon,
  LSTrash,
} from 'src/components/icons';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useDialog } from 'src/dialogs/Dialogs';
import {
  DELETE_FILECASE,
  MARK_AS_FAVORITE,
  UPDATE_FILECASE,
} from 'src/graphql/mutations/filecases';
import { useUser } from 'src/utils/hooks/useUser';
import { Linkify } from 'src/components/Linkify';
import {
  formatCustomFieldValue,
  isCustomFieldInitialValue,
} from 'src/components/ui-components/Form/CustomField';
import { Badge } from 'src/components/Badge';
import dayjs from 'dayjs';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import {
  exampleEntityForWhatsApp,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import Form, { SubmitButton } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { StudioMembersAutocomplete } from 'src/autocompletes';
import { useStudioMembers, useUpdateFilecaseAssignees } from '@legalsurf/hooks';
import { PopoverBase } from 'src/popovers/PopoverBase';

export const useDefaultCanBeSeenBy = (
  currentCanBeSeenBy = [],
  externalCanBeSeenBy = [],
) => {
  const studioId = useStudioId();
  const { user } = useUser();

  const { data: members = [], loading: loadingMembers } = useStudioMembers({
    variables: { studio: studioId, status: ['ACTIVE'] },
    fetchPolicy: 'cache-first',
  });

  const adminAndOwnerIds = members
    .filter((member) => ['admin', 'owner'].includes(member.role))
    .map((member) => member.id);

  const getMemberIds = (members) => members.map((member) => member.id);

  const fixedCanBeSeenByIds = Array.from(
    new Set([
      user.id,
      ...adminAndOwnerIds,
      ...getMemberIds(externalCanBeSeenBy),
    ]),
  );

  const mapMemberFromId = (memberId) => members.find((m) => m.id === memberId);

  const defaultCanBeSeenBy = Array.from(
    new Set([...fixedCanBeSeenByIds, ...getMemberIds(currentCanBeSeenBy)]),
  )
    .map(mapMemberFromId)
    .filter(Boolean);

  return {
    members: loadingMembers ? [] : members,
    defaultCanBeSeenBy: loadingMembers ? [] : defaultCanBeSeenBy,
    fixedCanBeSeenByIds: loadingMembers ? [] : fixedCanBeSeenByIds,
  };
};

const FilecaseVisibilityChip = ({ filecase }) => {
  const hasPermissions = usePermissions([LS_PERMISSIONS_MAP.FILECASES.UPDATE]);
  const studioId = useStudioId();

  const [updateFilecase] = useMutation(UPDATE_FILECASE);
  const [open, setOpen] = useState(false);
  const { fixedCanBeSeenByIds, defaultCanBeSeenBy, members } =
    useDefaultCanBeSeenBy(filecase.canBeSeenBy, filecase.assigned);

  const amount =
    filecase.canBeSeenBy.length === 0 ? 'Todos' : defaultCanBeSeenBy.length;

  const tooltipTitle =
    filecase.canBeSeenBy.length === 0 ? (
      'Este expediente puede ser visto por todos los miembros del estudio'
    ) : (
      <Stack gap={1}>
        <Typography color="inherit">
          ¿Quién puede ver este expediente?
        </Typography>

        <Stack gap={0.5}>
          {defaultCanBeSeenBy.map((member) => (
            <Typography key={member.id} variant="body2" color="inherit">
              {member.name}
            </Typography>
          ))}
        </Stack>
      </Stack>
    );

  const mapMemberToOption = (member) => ({
    label: member.name,
    value: member.id,
    picture: member.picture,
    disabled: fixedCanBeSeenByIds.includes(member.id),
  });

  const initialValues = {
    canBeSeenBy:
      filecase.canBeSeenBy.length === 0
        ? members.map(mapMemberToOption)
        : defaultCanBeSeenBy.map(mapMemberToOption),
  };

  const handleSubmit = async (values) => {
    await updateFilecase({
      variables: {
        studio: studioId,
        title: filecase.title,
        filecase: filecase.id,
        canBeSeenBy:
          members.length === values.canBeSeenBy?.length ||
          values.canBeSeenBy?.length === 0
            ? []
            : values.canBeSeenBy.map((member) => member.value),
      },
    });

    setOpen(false);
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      {hasPermissions && (
        <>
          <PopoverBase
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <Stack p={2} gap={4} maxWidth={['initial', 500, 500]}>
              <Typography fontWeight="700" fontSize={16}>
                Agregar usuario
              </Typography>

              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                <Form>
                  <StudioMembersAutocomplete
                    fixedOptions={fixedCanBeSeenByIds}
                    name="canBeSeenBy"
                  />

                  <Grid item xs={12}>
                    <Typography fontWeight="600" fontSize={11} color="primary">
                      * No podrás quitar a usuarios que aún están asignados al
                      expediente Tampoco podrás quitar a usuarios
                      administradores ya que tienen permisos para visualizar
                      toda la información de la cuenta LegalSurf.
                    </Typography>
                  </Grid>

                  <SubmitButton startIcon={<Save />} gridProps={{ ml: 'auto' }}>
                    Guardar
                  </SubmitButton>
                </Form>
              </Formik>
            </Stack>
          </PopoverBase>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle fontWeight="600">Acceso a expediente:</DialogTitle>

            <DialogContent dividers>
              <Typography fontSize={16} fontWeight="500">
                Colaboradores con acceso
              </Typography>

              <Stack direction="row" mt={2} gap={2} alignItems="center">
                <AvatarGroup
                  max={5}
                  total={
                    filecase.canBeSeenBy.length === 0
                      ? members.length
                      : defaultCanBeSeenBy.length
                  }
                  slotProps={{
                    additionalAvatar: {
                      sx: {
                        bgcolor: 'primary.900',
                        borderWidth: '0 !important',
                      },
                    },
                  }}
                  sx={{
                    '& > div': {
                      width: '50px !important',
                      height: '50px !important',
                      marginRight: '-12px !important',
                    },
                  }}
                >
                  {defaultCanBeSeenBy.map((member) => (
                    <Tooltip key={member.id} title={member.name}>
                      <Avatar
                        alt={member.name}
                        src={member.picture}
                        sx={{
                          borderColor: 'transparent !important',
                        }}
                      >
                        <Typography fontSize={12} variant="body1">
                          {member.name?.[0]}
                        </Typography>
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>

                <Box>
                  <IconButton
                    color="primary"
                    sx={{
                      bgcolor: 'background.input',
                      ':hover': {
                        bgcolor: (theme) =>
                          darken(theme.palette.background.input, 0.1),
                      },
                    }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Stack>
            </DialogContent>
          </Dialog>
        </>
      )}

      <Tooltip title={tooltipTitle}>
        <Box ml="auto">
          <Chip
            onClick={hasPermissions ? () => setOpen(true) : null}
            icon={<VisibilityOutlined color="primary" />}
            label={<Typography color="primary">{amount}</Typography>}
            sx={{ bgcolor: 'primary.200', px: 0.5 }}
          />
        </Box>
      </Tooltip>
    </>
  );
};

const FilecaseCustomFields = ({ filecase }) => {
  const customFields = filecase?.customFields?.filter(
    (customField) => !isCustomFieldInitialValue(customField),
  );

  if (!customFields?.length) {
    return null;
  }

  return (
    <Stack gap={0.5}>
      {customFields.map((customField) => (
        <Stack direction="row" gap={0.5} key={customField.id}>
          <Typography sx={{ opacity: 0.8 }} fontWeight="500" fontSize={12}>
            {customField.name}:
          </Typography>

          <Typography fontWeight="600" fontSize={14}>
            {formatCustomFieldValue(customField)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export const SingleFilecaseHeader = ({ filecase, onClose, singlePage }) => {
  const theme = useTheme();
  const studioId = useStudioId();
  const { user } = useUser();

  const dispatchFilecaseForm = useDialog('filecaseForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const moreOptionsMenuOpen = Boolean(anchorEl);

  const [markAsFavorite] = useMutation(MARK_AS_FAVORITE);

  const [deleteFilecase] = useMutation(DELETE_FILECASE, {
    update: (cache, data, options) => {
      const normalizedId = cache.identify({
        id: options.variables.filecaseIds[0],
        __typename: 'Filecase',
      });

      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const isMarkAsFavorite = filecase?.favorites?.some(
    (favorite) => favorite.id === user?.id,
  );

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.UPDATE,
  ]);

  const [isFavorite, setIsFavorite] = useState(() => isMarkAsFavorite ?? false);

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteFilecase = () =>
    deleteFilecase({
      variables: {
        filecaseIds: [filecase.id],
        studio: studioId,
      },
      onCompleted: onClose,
    });

  const handleClickFavorite = () => {
    markAsFavorite({
      variables: {
        studioId,
        id: filecase.id,
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(filecase),
          fields: {
            favorites() {
              if (!isFavorite) {
                return [...filecase.favorites, user];
              }

              return filecase.favorites.filter(
                (oldFavorite) => oldFavorite.id !== user.id,
              );
            },
          },
        });
      },
    });
  };

  const [whatsappAnchorEl, setWhatsappAnchorEl] = useState(null);

  const handleOpenWhatsappPopover = (innerEvent) => {
    setWhatsappAnchorEl(innerEvent?.currentTarget);
  };

  const handleCloseWhatsappPopover = () => {
    setWhatsappAnchorEl(null);
  };

  const [updateCalendarEventAssignees] = useUpdateFilecaseAssignees();

  const handleAssignedSubmit = async (newAssignees) => {
    await updateCalendarEventAssignees({
      variables: {
        studioId,
        id: filecase.id,
        assigned: newAssignees,
      },
    });
  };

  return (
    <>
      <NotificationTemplatesPopover
        anchorEl={whatsappAnchorEl}
        data={{ filecase, entity: exampleEntityForWhatsApp }}
        entity={exampleEntityForWhatsApp}
        open={Boolean(whatsappAnchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg('', msg);
        }}
        onClose={handleCloseWhatsappPopover}
      />

      <Stack gap={2}>
        <Grid container item alignItems="center">
          <Grid item xs={5}>
            <Stack alignItems="center" direction="row" gap={1}>
              <LSMailIcon color="primary" />
              <Typography variant="h5">EXPEDIENTE</Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ textAlign: 'right' }}>
              {!singlePage && (
                <IconButton onClick={onClose}>
                  <LSXIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            {filecase?.externalId && (
              <Stack alignItems="flex-end" direction="row">
                <Typography flex={4} fontWeight={600} variant="h5">
                  Nro. {filecase.externalId}
                </Typography>
              </Stack>
            )}

            <Stack
              direction="row"
              flex={1}
              flexWrap="nowrap"
              justifyContent="flex-end"
            >
              <Tooltip title="Plantillas de Whatsapp">
                <IconButton variant="text" onClick={handleOpenWhatsappPopover}>
                  <WhatsApp
                    sx={{
                      color: '#24d366',
                    }}
                  />
                </IconButton>
              </Tooltip>

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.FILECASES.UPDATE]}
              >
                <Tooltip color="primary" title="Editar expediente">
                  <IconButton
                    sx={{
                      p: 1,
                      '&:hover': {
                        backgroundColor: palette.error[200],

                        '& svg': {
                          color: 'error.main',
                        },
                      },
                    }}
                    onClick={() =>
                      dispatchFilecaseForm(filecase.id, { filecase })
                    }
                  >
                    <LSEditIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </PermissionGuard>

              {!singlePage && (
                <Tooltip color="primary" title="Pantalla completa">
                  <IconButton
                    sx={{
                      p: 1,
                    }}
                    onClick={() =>
                      window.open(
                        `/dashboard/${studioId}/filecase/${filecase.id}`,
                        '_blank',
                      )
                    }
                  >
                    <LSExternalIcon color="primary" sx={{ margin: 'auto' }} />
                  </IconButton>
                </Tooltip>
              )}

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.FILECASES.DELETE]}
              >
                <IconButton
                  aria-controls="filecase-more-options-menu"
                  aria-expanded={moreOptionsMenuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  color="primary"
                  id="filecase-more-options-button"
                  onClick={handleMoreOptionsClick}
                >
                  <LSListIcon />
                </IconButton>
              </PermissionGuard>
            </Stack>
          </Stack>

          <Stack gap={1} direction="row" justifyContent="space-between">
            <Stack direction="column" gap={1}>
              <Typography sx={{ fontWeight: 600, fontSize: 26 }} variant="h3">
                {filecase.title}
              </Typography>

              <Stack gap={2}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {filecase.state && (
                    <Badge
                      label={filecase.state.name}
                      backgroundColor={filecase.state.color}
                    />
                  )}

                  {filecase.category && (
                    <Badge label={filecase.category.name} />
                  )}

                  {filecase.side && <Badge label={filecase.side.name} />}
                </Box>

                {filecase.description && (
                  <Linkify text={filecase.description} />
                )}

                <FilecaseCustomFields filecase={filecase} />

                {!!(Array.isArray(filecase.tags) && filecase.tags.length) && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {filecase.tags.map((tag) => (
                      <Chip
                        key={tag.name}
                        label={tag.name}
                        size="small"
                        sx={{
                          color: theme.palette.primary.dark,
                          backgroundColor: theme.palette.primary[100],
                          fontWeight: '500',
                        }}
                      />
                    ))}
                  </Box>
                )}

                <Stack gap={0.5}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                      sx={{ opacity: 0.8 }}
                      fontWeight="500"
                      fontSize={12}
                    >
                      Fecha de creación:
                    </Typography>

                    <Typography fontWeight="500" fontSize={12}>
                      {dayjs(filecase.createdAt).format('LLL[hs]')}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        sx={{ opacity: 0.8 }}
                        fontWeight="500"
                        fontSize={12}
                      >
                        Creado por:
                      </Typography>

                      <Avatar
                        alt={filecase.createdBy.name}
                        src={filecase.createdBy.picture}
                        sx={{ width: 25, height: 25 }}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={filecase.assigned?.length ? 0 : 0.5}
                      justifyContent="flex-start"
                    >
                      <Typography
                        sx={{ opacity: 0.8 }}
                        fontWeight="500"
                        whiteSpace="nowrap"
                        fontSize={12}
                      >
                        Asignado a:
                      </Typography>

                      <AssigneesPopover
                        selectedAssignees={filecase.assigned}
                        touchable={hasPermissions}
                        onSubmit={handleAssignedSubmit}
                        sx={{
                          justifyContent: 'flex-start',
                          '.MuiAvatar-root': {
                            width: 25,
                            height: 25,
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Box>
              <Menu
                MenuListProps={{
                  'aria-labelledby': 'filecase-more-options-button',
                }}
                anchorEl={anchorEl}
                id="filecase-more-options-menu"
                open={moreOptionsMenuOpen}
                onClose={handleMoreOptionsClose}
              >
                <MenuItem
                  onClick={() => {
                    window.open(
                      `/dashboard/${studioId}/filecase/${filecase.id}/pdf`,
                      '_blank',
                    );
                  }}
                >
                  <ListItemIcon>
                    <PictureAsPdf fontSize="small" />
                  </ListItemIcon>
                  Generar PDF
                </MenuItem>

                <PermissionGuard
                  permissions={[LS_PERMISSIONS_MAP.FILECASES.UPDATE]}
                >
                  <MenuItem
                    onClick={() => {
                      setIsFavorite(!isFavorite);
                      handleClickFavorite();
                    }}
                  >
                    <ListItemIcon>
                      {isFavorite ? (
                        <Star color="warning" fontSize="small" />
                      ) : (
                        <StarBorderOutlinedIcon
                          color="default"
                          fontSize="small"
                        />
                      )}
                    </ListItemIcon>

                    <ListItemText>
                      {isFavorite
                        ? 'Desmarcar favorito'
                        : 'Marcar como favorito'}
                    </ListItemText>
                  </MenuItem>
                </PermissionGuard>

                <MenuItem
                  onClick={() => {
                    dispatchWarningPromptDialog(
                      'DeleteFilecaseSingleFilecaseHeader',
                      {
                        onAccept: handleDeleteFilecase,
                        title: 'Eliminar expediente',
                        content: `Estas seguro que deseas eliminar el expediente ${filecase.title}`,
                      },
                    );

                    handleMoreOptionsClose();
                  }}
                >
                  <ListItemIcon>
                    <LSTrash fontSize="small" />
                  </ListItemIcon>
                  Eliminar
                </MenuItem>
              </Menu>

              <Stack gap={1}>
                <FilecaseVisibilityChip filecase={filecase} />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SingleFilecaseHeader;
