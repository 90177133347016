import React from 'react';
import { darken, Stack } from '@mui/material';

import { DEFAULT_TASK_STATES } from '@legalsurf/common';
import { useUpdateCalendarEventStatus } from '@legalsurf/hooks';
import { Badge } from 'src/components/Badge';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';

const TaskStatusPopoverContent = ({
  eventId,
  calendarId,
  // state,
  onClickAway,
}) => {
  const [updateCalendarEventStatusMutation, { loading }] =
    useUpdateCalendarEventStatus();

  const onClickStatus = async (event, option) => {
    await updateCalendarEventStatusMutation({
      variables: {
        id: eventId,
        calendarId,
        state: option.value,
      },
    });

    onClickAway(event);
  };

  return (
    <DisabledBackdrop in={loading}>
      <Stack p={1} gap={0.5}>
        {DEFAULT_TASK_STATES.map((option) => (
          <Badge
            // disabled={option.value === state}
            key={option.value}
            label={option.label}
            backgroundColor={option.bg}
            color={option.color}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: darken(option.bg, 0.05),
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              onClickStatus(event, option);
            }}
          />
        ))}
      </Stack>
    </DisabledBackdrop>
  );
};

export default TaskStatusPopoverContent;
