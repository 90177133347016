import { createTheme } from '@mui/material';

import { palette, typography } from './foundations';
import {
  MuiCard,
  MuiButton,
  MuiMenu,
  MuiTableCell,
  MuiTextField,
  MuiFormLabel,
  MuiChip,
  MuiCssBaseline,
  MuiOutlinedInput,
  MuiDialogActions,
  MuiInputLabel,
  MuiSkeleton,
} from './components';

const muiTheme = createTheme({
  components: {
    MuiCard,
    MuiTableCell,
    MuiTextField,
    MuiMenu,
    MuiButton,
    MuiFormLabel,
    MuiChip,
    MuiCssBaseline,
    MuiOutlinedInput,
    MuiDialogActions,
    MuiInputLabel,
    MuiSkeleton,
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'initial',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 34,
          height: 34,
          fontSize: 14,
          borderWidth: 0,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette,
  typography,
});

export default muiTheme;
