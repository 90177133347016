import React from 'react';
import { Stack } from '@mui/material';

import Page from 'src/components/v2/Page';

import UserColumn from './UserColumn';
import { DetailsColumn } from './DetailsColumn';

const DirectorySingle = () => (
  <Page title="Directorios | LegalSurf">
    <Stack flexDirection={['column', 'column', 'row']} gap={3}>
      <UserColumn />

      <DetailsColumn />
    </Stack>
  </Page>
);

export default DirectorySingle;
