import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { useUser } from './useUser';
import { useStudioId } from './useStudioId';
import { useConditionalEffect } from './useConditionalEffect';
import { useSession } from '@clerk/clerk-react';

export const usePosthogTracker = () => {
  const { user } = useUser();
  const { session, isLoaded } = useSession();
  const studioId = useStudioId();
  const location = useLocation();
  const posthog = usePostHog();
  const isImpersonated = !!session?.actor?.sub;

  useConditionalEffect(() => {
    if (posthog && !isImpersonated && isLoaded) {
      posthog.capture('$pageview');
    } else {
      console.log('skipping pageview due to isImpersonated');
    }
  }, [location.pathname, location.search]);

  useConditionalEffect(() => {
    if (posthog && !isImpersonated && isLoaded) {
      posthog.identify(user?.id, {
        email: user?.email,
        name: user?.name,
      });
    } else {
      console.log('skipping user identity due to isImpersonated');
    }
  }, [user?.id]);

  useConditionalEffect(() => {
    if (posthog && !isImpersonated && isLoaded) {
      posthog.group('studio', studioId);
    } else {
      console.log('skipping studio due to isImpersonated');
    }
  }, [studioId]);
};
