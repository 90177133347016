import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GET_PAGINATED_FILECASES } from 'src/graphql/queries/filecases';

import { useDialog } from 'src/dialogs/Dialogs';
import { Stack } from '@mui/material';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header, { useFilecasesTableSearchFilterValues } from './Header';
import { useColumns } from './columns';
import { useFilecasesToExport } from './excel';

const initialTasksQueryVars = {
  limit: defaultPageSize,
  offset: 0,

  tags: [],
  filecases: [],
  assigned: [],
  directory: [],
  search: '',
  onlyFavorites: null,
  category: null,
  state: null,
};

const usePaginatedFilecasesTable = (options = {}) => {
  const studioId = useStudioId();
  const [params, , initialized] = useFilecasesTableSearchFilterValues();

  const queryVariables = {
    studio: studioId,
    limit: defaultPageSize,
    offset: 0,

    tags: params.tags ?? initialTasksQueryVars.tags,
    filecases: params.filecases ?? initialTasksQueryVars.filecases,
    assigned: params.assigned ?? initialTasksQueryVars.assigned,
    directory: params.directory ?? initialTasksQueryVars.directory,
    search: params.search ?? initialTasksQueryVars.search,
    onlyFavorites: params.onlyFavorites ?? initialTasksQueryVars.onlyFavorites,
    category: params.category ?? initialTasksQueryVars.category,
    state: params.state ?? initialTasksQueryVars.state,
  };

  const { data: { paginatedFilecases } = { paginatedFilecases: {} }, ...rest } =
    useQuery(GET_PAGINATED_FILECASES, {
      notifyOnNetworkStatusChange: true,
      partialRefetch: true,
      fetchPolicy: 'cache-and-network',
      variables: queryVariables,
      skip: !studioId || !initialized,
      ...options,
    });

  return { paginatedFilecases, ...rest };
};

const FilecasesTable = () => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const [selectedItems, setSelectedItems] = useState([]);
  const columns = useColumns();

  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
  });

  const { paginatedFilecases, loading, variables, refetch } =
    usePaginatedFilecasesTable({
      onCompleted: () => setSelectedItems([]),
    });

  const handlePaginationModelChange = ({ pageSize, page }) =>
    refetch({
      ...variables,
      limit: pageSize,
      offset: pageSize * page,
    }).then(() => {
      setPagination({ page, limit: pageSize });
    });

  const { getFilecasesToExport, loading: loadingFilecasesToExport } =
    useFilecasesToExport();

  const handleDownloadAsExcel = async () => {
    const params = { ...variables, ids: selectedItems };

    if (!selectedItems?.length) {
      params.limit = paginatedFilecases?.data?.count;
    }

    await getFilecasesToExport(params);
  };

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        loadingFilecasesToExport={loadingFilecasesToExport}
        refetch={refetch}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        subtitle={`Totales ${paginatedFilecases?.count || '0'}`}
        title="Expedientes"
        totalItems={paginatedFilecases?.count ?? 0}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'filecases',
          },
          noRowsOverlay: {
            variant: 'filecases',
          },
        }}
        loading={loading || loadingFilecasesToExport}
        paginationModel={{ page: pagination.page, pageSize: pagination.limit }}
        rowCount={paginatedFilecases?.count ?? 0}
        rows={paginatedFilecases?.data ?? []}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={(params) =>
          dispatchFilecaseDialog(params.row.id, { id: params.row.id })
        }
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        onSortModelChange={([column]) => {
          refetch({
            ...variables,
            ...(column
              ? {
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  orderBy: null,
                  sortOrder: null,
                }),
          });
        }}
      />
    </Stack>
  );
};

export default FilecasesTable;
