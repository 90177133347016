import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Typography,
  CardContent,
  Card,
  Avatar,
  Chip,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import { LS_PERMISSIONS_MAP, entityTypesObj } from '@legalsurf/common';

import CollapsablePanel from 'src/components/CollapsablePanel';
import EntityDialog from 'src/dialogs/EntityDialog';

import { GET_ENTITY } from 'src/graphql/queries/entities';
import { getEntityType, stringAvatar } from 'src/utils/formatters';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { formatCustomFieldValue } from 'src/components/ui-components/Form/CustomField';
import { Mail, WhatsApp } from '@mui/icons-material';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import { sendWhatsappMsg } from 'src/utils/notificationTemplates';
import UserColumnSkeleton from './UserColumnSkeleton';

const UserColumn = () => {
  const params = useParams();
  const [openCollapse, setOpenCollapse] = useState(true);
  const [showEntity, setShowEntity] = useState(false);
  const { loading, data: { getEntity: entity } = {} } = useQuery(GET_ENTITY, {
    fetchPolicy: 'cache-and-network',
    variables: { entity: params.entity },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (innerEvent) => {
    setAnchorEl(innerEvent?.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  // Why not do this formatting in the backend instead?
  // Someone insert answer here: wtf is that func bruh?
  const handleEditEntity = (event) => {
    event.stopPropagation();
    setShowEntity(true);
  };

  const detailsTrigger = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6">Detalle</Typography>
      {openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}

      <PermissionGuard permissions={LS_PERMISSIONS_MAP.DIRECTORY.UPDATE}>
        <Button
          color="secondary"
          sx={{ marginLeft: 'auto' }}
          variant="light"
          onClick={handleEditEntity}
        >
          Editar
        </Button>
      </PermissionGuard>
    </Box>
  );

  if (loading || !entity) {
    return <UserColumnSkeleton />;
  }

  return (
    <>
      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ entity }}
        entity={entity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg('', msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />

      <Stack sx={{ minWidth: ['inherit', 'inherit', 350] }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 4,

                '& > * + *': {
                  marginTop: 2,
                },
              }}
            >
              <Avatar
                variant="squared"
                {...stringAvatar(entity.displayName, {
                  width: 120,
                  height: 120,
                  marginBottom: 2,
                  fontSize: '2rem',
                })}
              />

              <Typography align="center" variant="h5">
                {entity.displayName}
              </Typography>

              <Chip
                color="primary"
                label={getEntityType(entity.type)}
                variant="light"
              />

              <Box
                sx={{
                  gap: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {entity.tags &&
                  entity.tags?.map((tag) => (
                    <Chip
                      key={tag.name}
                      label={tag.name}
                      size="small"
                      sx={{
                        color: 'primary.dark',
                        backgroundColor: 'primary.100',
                      }}
                    />
                  ))}
              </Box>
            </Box>

            <CollapsablePanel
              defaultToggle
              open={openCollapse}
              setOpen={setOpenCollapse}
              trigger={detailsTrigger}
            >
              <List>
                {!entity.businessname ? (
                  <>
                    <ListItem>
                      <ListItemText
                        primary="Nombre"
                        secondary={entity.firstname}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary="Apellido"
                        secondary={entity.lastname}
                      />
                    </ListItem>
                  </>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Razón social"
                      secondary={entity.businessname}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary="Descripción"
                    secondary={entity.description ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Pasaporte / DNI / ID"
                    secondary={entity.identification ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Telefono de contacto"
                    secondary={entity.phone ?? '---'}
                  />

                  <Tooltip title="Plantillas de Whatsapp">
                    <IconButton variant="text" onClick={handleOpenFilecasesPopover}>
                      <WhatsApp
                        sx={{
                          color: '#24d366',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <ListItem component="a" href={`mailto:${entity.email}`} sx={{ color: '#000000de' }}>
                  <ListItemText
                    primary="Email"
                    secondary={entity.email ?? '---'}
                  />

                  <IconButton>
                    <Mail />
                  </IconButton>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Codigo postal"
                    secondary={entity.postalCode ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Localidad"
                    secondary={entity.location ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Pais"
                    secondary={entity.country ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Direccion"
                    secondary={entity.address ?? '---'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Piso / Departamento"
                    secondary={entity.departament ?? '---'}
                  />
                </ListItem>

                {entity?.customFields?.map((customField) => (
                  <ListItem>
                    <ListItemText
                      primary={customField.name}
                      secondary={formatCustomFieldValue(customField)}
                    />
                  </ListItem>
                ))}
              </List>
            </CollapsablePanel>
          </CardContent>
        </Card>

        <EntityDialog
          entity={{
            ...entity,
            type: { label: entityTypesObj[entity.type], value: entity.type },
          }}
          open={showEntity}
          onClose={() => setShowEntity(false)}
        />
      </Stack>
    </>
  );
};

export default UserColumn;
