import React, { useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import SectionHeader from 'src/components/v2/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useCurrencies } from 'src/autocompletes/CurrencyAutocomplete';

const initialSalesFiltersQueryVars = {
  entities: null,
  responsibles: null,
  filecases: null,
  tags: null,
  currencies: null,
};

const initialValues = {
  filecases: [],
  entities: [],
  responsibles: [],
  tags: [],
  currencies: [],
};

const getSalesTableFiltersQuery = gql`
  query getSalesTableFiltersQuery($studio: ID!) {
    filecases(studio: $studio) {
      id
      title
    }

    entities(studio: $studio) {
      id
      type
      displayName
    }

    members(studio: $studio) {
      id
      name
    }

    tags(studio: $studio) {
      name
    }
  }
`;

const Header = ({
  totalItems,
  refetch,
  loading,
  selectedItems,
  loadingFilecasesToExport,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const studioId = useStudioId();
  const { currencies } = useCurrencies();

  const dispatchSalesFormDialog = useDialog('salesForm');
  const { role, permissions } = usePermissions([LS_PERMISSIONS_MAP.SALES.READ]);

  const filters = useQuery(getSalesTableFiltersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
    },
    skip: !studioId,
  });

  const handleFiltersSubmit = useCallback(
    (values) =>
      refetch({
        studio: studioId,
        filecases: values.filecases?.map((filecase) => filecase.value) || [],
        entities: values.entities?.map((entity) => entity.value) || [],
        responsibles:
          values.responsibles?.map((responsible) => responsible.value) || [],
        currencies: values.currencies?.map((currency) => currency.value) || [],
        tags: values.tags?.map((tag) => tag.value) || [],
      }),
    [refetch, studioId],
  );

  return (
    <Box sx={{ p: 2 }}>
      <SectionHeader
        actions={[
          {
            title: `Descargar Excel (${selectedItems?.length || 'Todos'})`,
            handleChange: handleDownloadAsExcel,
            icon: DownloadIcon,
            disabled: loadingFilecasesToExport,
          },
        ]}
        createButton={
          permissions.includes(LS_PERMISSIONS_MAP.SALES.CREATE) ||
          role === 'owner' ||
          role === 'admin'
            ? {
                title: 'Crear honorario',
                handleChange: () => {
                  dispatchSalesFormDialog('SalesTableForm');
                },
              }
            : undefined
        }
        filters={{
          initialValues,
          loading: loading || filters.loading,
          onSubmit: handleFiltersSubmit,
          onReset: () =>
            refetch({
              studio: studioId,
              ...initialSalesFiltersQueryVars,
            }),
          data: [
            {
              title: 'Expedientes',
              name: 'filecases',
              isMulti: true,
              options:
                filters.data &&
                filters.data.filecases.map((filecase) => ({
                  label: filecase.title,
                  value: filecase.id,
                })),
            },
            {
              title: 'Clientes',
              name: 'entities',
              isMulti: true,
              options:
                filters.data &&
                filters.data.entities.map((entity) => ({
                  label: entity.displayName,
                  value: entity.id,
                })),
            },
            {
              title: 'Responsable',
              name: 'responsibles',
              isMulti: true,
              options: filters?.data?.members.map((assigned) => ({
                label: assigned.name,
                value: assigned.id,
              })),
            },
            {
              title: 'Etiquetas',
              name: 'tags',
              isMulti: true,
              options: filters?.data?.tags.map((tag) => ({
                label: tag.name,
                value: tag.name,
              })),
            },
            {
              title: 'Monedas',
              name: 'currencies',
              isMulti: true,
              options: currencies.map((currency) => ({
                label: currency.name,
                value: currency.id,
              })),
            },
          ],
        }}
        subtitle={`${totalItems} totales`}
        title="Honorarios"
      />
    </Box>
  );
};

export default Header;
