import React from 'react';
import { Box, Dialog } from '@mui/material';

import FilecaseDetail from '../../components/v2/FilecaseDetail';

const FilecaseDetailsDialog = ({ filecaseId, onHide }) => (
  <Dialog
    fullScreen
    open={!!filecaseId}
    sx={{ width: 700, ml: 'auto' }}
    onClose={onHide}
  >
    <Box sx={{ overflowY: 'scroll' }}>
      <FilecaseDetail filecaseId={filecaseId} onHide={onHide} />
    </Box>
  </Dialog>
);

export default FilecaseDetailsDialog;
