import React, { useCallback, useState } from 'react';
import { Box, Button, ButtonGroup, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam, BooleanParam } from 'use-query-params';
import * as Sentry from '@sentry/browser';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import { DateField, SwitchField, TextField } from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen } from 'src/components/icons';
import { useUser } from 'src/utils/hooks/useUser';
import dayjs from 'dayjs';
import { AssigneesAutocomplete, CalendarsAutocomplete, FilecasesAutocomplete } from 'src/autocompletes';
import { useSearchFilterValues } from 'src/components/v3/SectionHeader/utils';

export const taskBoardSearchFiltersId = 'TaskBoardSearchFilters-v1';

const paramConfigMap = {
  filecases: ArrayParam,
  assigned: ArrayParam,
  calendars: ArrayParam,
  text: StringParam,
  start: StringParam,
  end: StringParam,
  onlyMe: BooleanParam,
  viewType: StringParam,
  state: StringParam,
};

export const useTaskBoardSearchFilterValues = () => useSearchFilterValues({ paramConfigMap, id: taskBoardSearchFiltersId });

const Header = ({
  title,
  subtitle,
  refetch,
  loading,
}) => {
  const studioId = useStudioId();
  const { user } = useUser();
  const dispatchTaskFormDialog = useDialog('eventForm');

  const [params, setParams] = useTaskBoardSearchFilterValues();

  const initialValues = {
    filecases: params.filecases ?? [],
    assigned: params.assigned ?? [],
    calendars: params.calendars ?? [],
    text: params.text ?? '',
    start: params.start ?? null,
    end: params.end ?? null,
    onlyMe: params.onlyMe ?? user.role === 'member',
    viewType: params.viewType ?? 'board'
  };

  const handleFiltersSubmit = async (values) => {
    const innerParams = {
      ...values,
      start: dayjs(values.start).isValid() ? dayjs(values.start).format('YYYY-MM-DD') : undefined,
      end: dayjs(values.end).isValid() ? dayjs(values.end).format('YYYY-MM-DD') : undefined
    };

    try {
      setParams(innerParams);

      const query = {
        studio: studioId,
        type: 'task',
        text: values.text?.length ? values.text : null,
        filecases: values.filecases?.map((filecase) => filecase.value ?? filecase) || null,
        assigned: values.assigned?.map((assigned) => assigned.value ?? assigned) || null,
        calendars: values.calendars?.map((calendar) => calendar.value ?? calendar) || null,
        start: dayjs(values.start).startOf('day').toDate() ?? null,
        end: dayjs(values.end).endOf('day').toDate() ?? null,
      };

      if (values.onlyMe) {
        query.assigned = [user.id];
      }

      await refetch(query);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          innerParams: JSON.stringify(innerParams)
        }
      });

      // try reseting parameters
      setParams({});
    }
  }

  const [t, setT] = useState(null);

  const handleFiltersChange = (values, prev) => {
    if (values.text !== prev.text) {
      if (t) clearTimeout(t);

      setT(setTimeout(() => handleFiltersSubmit(values), 400));
      return;
    }

    handleFiltersSubmit(values);
  }

  const handleReset = () => {
    setParams({});
  }

  return (
    <SectionHeader
      enableReinitialize
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersSubmit}
    >
      {({ values, setFieldValue }) => {
        const handleViewChange = (view) => () => {
          setFieldValue('viewType', view);

          if (view === 'board') {
            setParams({ state: null })
          }
        }

        return (
          <>
            <Stack alignItems="center" direction="row" gap={3}>
              <Stack direction="column">
                <SectionHeaderHeading sx={{ mb: 0.5 }} variant="h3">
                  {title}
                </SectionHeaderHeading>

                {subtitle && (
                  <SectionHeaderHeading variant="subtitle1">
                    {subtitle}
                  </SectionHeaderHeading>
                )}
              </Stack>

              <TextField
                debounce
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      <LSLen color="primary" />
                    </InputAdornment>
                }}
                name="text"
                placeholder="Buscar..."
                size="small"
              />

              <DateField
                label="Desde"
                name="start"
                size="small"
                sx={{ maxWidth: 160 }}
              />

              <DateField
                label="Hasta"
                name="end"
                size="small"
                sx={{ maxWidth: 160 }}
              />

              <SectionHeaderAdvanceFilters onReset={handleReset}>
                <CalendarsAutocomplete
                  multiple
                  label="Calendarios"
                  name="calendars"
                  size="small"
                />

                <FilecasesAutocomplete
                  multiple
                  label="Expedientes"
                  name="filecases"
                  placeholder="Ej: 1234/2021"
                  size="small"
                />

                <AssigneesAutocomplete
                  multiple
                  label="Asignados"
                  name="assigned"
                  placeholder="Ej: Nicolas Salazar"
                  size="small"
                />
              </SectionHeaderAdvanceFilters>

              <Box sx={{ ml: 'auto', gap: 2, display: 'flex', alignItems: 'center' }}>
                <SwitchField
                  label="Solo yo"
                  name="onlyMe"
                />

                <ButtonGroup variant="outlined">
                  <Button
                    variant={values.viewType === 'list' ? 'contained' : 'outlined'}
                    onClick={handleViewChange('list')}
                  >
                    Lista
                  </Button>
                  <Button
                    variant={values.viewType === 'board' ? 'contained' : 'outlined'}
                    onClick={handleViewChange('board')}
                  >
                    Tablero
                  </Button>
                </ButtonGroup>

                <Button
                  color="primary"
                  startIcon={<LSAddIcon color="white" />}
                  variant="contained"
                  onClick={() =>
                    dispatchTaskFormDialog('TaskBoard', {
                      type: 'task',
                    })
                  }
                >
                  Crear tarea
                </Button>
              </Box>
            </Stack >

            <SectionHeaderActiveFilters
              filterLabels={{
                filecase: 'Expediente',
                filecases: 'Expedientes',
                assigned: 'Asignados',
                onlyMe: 'Solo yo',
                start: 'Desde',
                end: 'Hasta',
                text: 'Texto'
              }}
              filters={params}
            />
          </>
        )
      }}
    </SectionHeader >
  );
};

export default Header;
