import React, { useMemo } from 'react';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';

import TitleCell from './TitleCell';
import TypeCell from './TypeCell';
import StateCell from './StateCell';
import { TemplateCell } from './TemplateCell';

export const useColumns = () => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.READ,
  ]);

  return useMemo(
    () => [
      {
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        headerName: 'Directorio',
        field: 'displayName',
        flex: 1,
        minWidth: 320,
        renderCell: (cell) => <TitleCell {...cell} />,
      },
      {
        editable: false,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        field: 'type',
        minWidth: 150,
        flex: 1,
        headerName: 'Tipo',
        renderCell: (cell) => <TypeCell {...cell} />,
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        headerName: 'Estado',
        field: 'state',
        width: 140,
        renderCell: (cell) => <StateCell {...cell} />,
      },
      {
        hideable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 200,
        headerName: 'Teléfono',
        field: 'phone',
      },
      {
        editable: false,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        minWidth: 150,
        headerName: 'Email',
        field: 'email',
      },
      {
        editable: false,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        minWidth: 140,
        headerName: 'Identificación',
        field: 'identification',
      },
      {
        hideable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        headerName: 'WhatsApp',
        field: 'whatsapp',
        width: 200,
        renderCell: (cell) => <TemplateCell {...cell} />,
      },
    ],
    [hasPermissions],
  );
};
