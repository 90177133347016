import React from 'react';
import { Link, Box, Tooltip, Typography } from '@mui/material';

import { useDialog } from 'src/dialogs/Dialogs';

const DocumentCell = ({ row: file }) => {
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Tooltip
        title={
          file.kind === 'spaces'
            ? 'Previsualizar documento'
            : 'Abrir documento en google drive'
        }
      >
        <Typography
          noWrap
          {...(file.kind === 'spaces'
            ? {
                onClick: () =>
                  dispatchDocumentPreviewDialog(
                    `DocumentsTableCell-${file?.id}`,
                    {
                      document: file,
                      downloable: true,
                    },
                  ),
              }
            : {
                component: Link,
                href: file.url,
                rel: 'noopener',
                target: '_blank',
              })}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              color: 'primary.main',
            },
            maxWidth: 350,
          }}
        >
          {file.name}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default DocumentCell;
