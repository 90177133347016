import React, { useState, useRef } from 'react';
import { darken } from '@mui/material';

import { tasksStatesObj } from '@legalsurf/common';
import TaskStatusPopover from '../TaskStatusPopover';
import { Badge } from 'src/components/Badge';

const noState = {
  bg: '#F8F5FF',
  color: '#000',
  value: 'N/A',
  label: '+',
};

const TaskEventStatusChip = ({ state, touchable, eventId, calendarId, sx }) => {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef(null);
  const stateValue = tasksStatesObj[state] || noState;

  return (
    <TaskStatusPopover
      calendarId={calendarId}
      eventId={eventId}
      open={open}
      ref={popoverRef.current}
      state={state}
      onClickAway={(event) => {
        if (!touchable) {
          return;
        }

        if (event.stopPropagation) {
          event.stopPropagation();
        }
        setOpen(false);
      }}
    >
      <Badge
        label={stateValue.label}
        backgroundColor={stateValue.bg}
        ref={popoverRef}
        onClick={(event) => {
          if (!touchable) {
            return;
          }
          event.stopPropagation();
          setOpen(true);
        }}
        sx={{
          ...sx,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: darken(stateValue.bg, 0.2),
          },
        }}
        textProps={{
          color: stateValue.color,
        }}
      />
    </TaskStatusPopover>
  );
};

export default TaskEventStatusChip;
